import { Card, Container, Nav, Tab } from "react-bootstrap";
import ManualPayment from "./manual/index";
import OnlinePayment from "./online/onlinePayment";

export default function AddMoney() {
  return (
    <Container>
      <Card className="rounded-0 p-3 p-lg-4 my-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey="online">
          <Nav variant="tabs">
            <Nav.Item className="tab_active" role="button">
              <Nav.Link eventKey="online">Online Payment</Nav.Link>
            </Nav.Item>
            <Nav.Item className="tab_active" role="button">
              <Nav.Link eventKey="manual">Manual Payment</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-3">
            <Tab.Pane eventKey="online">
              <OnlinePayment />
            </Tab.Pane>
            <Tab.Pane eventKey="manual">
              <ManualPayment />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </Container>
  );
}
