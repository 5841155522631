import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Image,
} from "react-bootstrap";
import RoomsGuests from "../../../../components/RoomsGuests";
import { format, addDays, isBefore, startOfDay } from "date-fns";
import HotelRoom from "./room/index";
import Cart from "./Cart";
import PropertyProfileDetails from "./profileDetails";
import HotelNotFound from "../../../../assets/img/room_not_found.svg";
import ContentLoader from "react-content-loader";
import DateRangePickerCustom from "../../../../components/form-elements/DateRangePicker";
import {
  CartDefinition,
  AddCartItem,
  RemoveCartItem,
} from "../../../../types/cart";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RequestFailed from "../../../../components/RequestFailed";
import { IFetchError } from "../../../../types";
import { usePropertyProfile } from "./usePropertyProfile";
import { usePropertyRooms } from "./room/usePropertyRooms";

export default function PropertyProfile() {
  const params = useParams();
  const navigate = useNavigate();
  const query_params = HandleParams();

  const [propertyId] = useState(params.property_id);

  const [startDate, setStartDate] = useState(query_params.start_date);
  const [endDate, setEndDate] = useState(query_params.end_date);

  const [totalRooms, setTotalRooms] = useState(query_params.total_rooms);
  const [totalGuests, setTotalGuests] = useState(query_params.total_guests);
  const [rooms_guests, setRoomsGuests] = useState(query_params.rooms_guests);

  const [cart, setCart] = useState<CartDefinition[]>([]);
  const [cartTotal, setCartTotal] = useState(0.0);

  const profile = usePropertyProfile({ propertyId });
  const rooms = usePropertyRooms({
    property_id: propertyId,
    start_date: format(new Date(startDate), "yyyy-MM-dd"),
    end_date: format(new Date(endDate), "yyyy-MM-dd"),
    cart,
    setCart,
    setCartTotal,
  });

  const handleRoomSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    rooms.refetch();
  };
  // calculate total rooms and guests
  useEffect(() => {
    let total_guests = 0;
    rooms_guests.forEach((room_guest: { adult: number; child: number }) => {
      total_guests += room_guest.adult;
      total_guests += room_guest.child;
    });
    setTotalRooms(rooms_guests.length);
    setTotalGuests(total_guests);
  }, [rooms_guests]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const addCartItem: AddCartItem = ({
    id,
    name,
    photo,
    b2b_total_nights_gross_rate,
    available,
  }) => {
    const itemIndex = cart.findIndex(
      (i: CartDefinition) => i.id === id && i.name === name
    );
    if (itemIndex === -1) {
      setCart((prev: CartDefinition[]) => [
        ...prev,
        {
          id,
          name,
          b2b_total_nights_gross_rate,
          photo,
          available,
          quantity: 1,
        },
      ]);
      setCartTotal((prev) => (prev += b2b_total_nights_gross_rate));
    } else {
      if (cart[itemIndex].quantity < available) {
        cart[itemIndex].quantity += 1;
        setCart((prev) => [...prev]);
        setCartTotal((prev) => (prev += b2b_total_nights_gross_rate));
      }
    }
  };

  // decrement item

  const removeCartItem: RemoveCartItem = ({
    id,
    name,
    b2b_total_nights_gross_rate,
  }) => {
    const itemIndex = cart.findIndex(
      (i: CartDefinition) => i.id === id && i.name === name
    );

    if (itemIndex !== -1) {
      if (cart[itemIndex].quantity > 1) {
        cart[itemIndex].quantity -= 1;
        setCart((prev) => [...prev]);
        setCartTotal((prev) => (prev -= b2b_total_nights_gross_rate));
      } else {
        cart.splice(itemIndex, 1);
        setCart((prev) => [...prev]);
        setCartTotal((prev) => (prev -= b2b_total_nights_gross_rate));
      }
    }
  };

  // remove the full item

  const deleteCartItem: RemoveCartItem = ({ id, name }) => {
    const newCart = cart.filter(
      (i: CartDefinition) => i.id !== id && i.name !== name
    );
    setCart(newCart);
    let total = 0;
    newCart.forEach((item: CartDefinition) => {
      total += item.b2b_total_nights_gross_rate * item.quantity;
    });
    setCartTotal(total);
  };
  return (
    <>
      <Container className="my-2">
        {!profile.isLoading ? (
          profile.data ? (
            <PropertyProfileDetails profile={profile.data.data} />
          ) : null
        ) : (
          <>
            <Row>
              <Col className="px-2 pt-1 pb-0" lg={7} xs={7}>
                <ContentLoader
                  width="100%"
                  height={330}
                  backgroundColor="#E1E9EE"
                  foregroundColor="#F2F8FC"
                >
                  <rect rx="10" ry="10" width="100%" height="330" />
                </ContentLoader>
              </Col>
              <Col lg={5} xs={5}>
                <Row>
                  <Col className="p-1" lg={12} xs={12}>
                    <ContentLoader
                      width="100%"
                      height={160}
                      backgroundColor="#E1E9EE"
                      foregroundColor="#F2F8FC"
                    >
                      <rect rx="10" ry="10" width="100%" height="160" />
                    </ContentLoader>
                  </Col>
                  <Col className="px-1 pt-1 pb-0" lg={12} xs={12}>
                    <ContentLoader
                      width="100%"
                      height={160}
                      backgroundColor="#E1E9EE"
                      foregroundColor="#F2F8FC"
                    >
                      <rect rx="10" ry="10" width="100%" height="160" />
                    </ContentLoader>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Card className="p-4 mt-2">
              <Row>
                <Col lg={8}>
                  <ContentLoader
                    width="100%"
                    height={200}
                    backgroundColor="#E1E9EE"
                    foregroundColor="#F2F8FC"
                  >
                    <rect x="0" y="14" rx="8" ry="8" width="55%" height="18" />
                    <rect x="0" y="45" rx="8" ry="8" width="40%" height="15" />
                    <rect x="0" y="110" rx="6" ry="6" width="30%" height="14" />
                    <rect
                      x="33%"
                      y="110"
                      rx="6"
                      ry="6"
                      width="30%"
                      height="14"
                    />
                    <rect
                      x="67%"
                      y="110"
                      rx="6"
                      ry="6"
                      width="30%"
                      height="14"
                    />
                    <rect x="0" y="150" rx="6" ry="6" width="30%" height="14" />
                    <rect
                      x="33%"
                      y="150"
                      rx="6"
                      ry="6"
                      width="30%"
                      height="14"
                    />
                    <rect
                      x="67%"
                      y="150"
                      rx="6"
                      ry="6"
                      width="30%"
                      height="14"
                    />
                  </ContentLoader>
                </Col>
                <Col lg={4}>
                  <ContentLoader
                    width="100%"
                    height={200}
                    backgroundColor="#E1E9EE"
                    foregroundColor="#F2F8FC"
                  >
                    <rect rx="10" ry="10" width="100%" height="200" />
                  </ContentLoader>
                </Col>
              </Row>
            </Card>
          </>
        )}
        {profile.isLoadingError ? (
          <RequestFailed
            ErrorMessage={`Oops! Looks like you followed a bad link. Let's get you back on track.`}
            ButtonName="Go Back"
            ButtonTrigger={() => navigate("/search/properties")}
          />
        ) : null}
        <h2 className="fw-bold my-4">Choose your room</h2>
        <Row className="my-1">
          <Col lg={9} sm={12}>
            <Form onSubmit={handleRoomSearch}>
              <Row>
                <DateRangePickerCustom
                  labels={["Check In", "Check Out"]}
                  labelClass="cm-form-label"
                  className="cm-form-input"
                  icon={true}
                  lg={5}
                  minimumLength={1}
                  minimumDate={new Date()}
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={(date) => setStartDate(date!)}
                  onEndDateChange={(date) => setEndDate(date!)}
                  required={true}
                />
                <RoomsGuests
                  lg={3}
                  totalGuests={totalGuests}
                  totalRooms={totalRooms}
                  rooms_guests={rooms_guests}
                  setRoomsGuests={setRoomsGuests}
                />
                <Form.Group
                  className="d-flex align-items-center mb-3"
                  lg={3}
                  as={Col}
                >
                  <Button
                    className="w-100 fw-bold py-3"
                    variant="primary"
                    type="submit"
                  >
                    Search
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </Col>
        </Row>
        {!rooms.isLoading && !profile.isLoading ? (
          profile.data && rooms.data ? (
            <Row>
              <Col lg={9}>
                {rooms.data.data.property_rooms.length !== 0 ? (
                  rooms.data.data.property_rooms.map((room, key: React.Key) => {
                    return (
                      <HotelRoom
                        key={key}
                        room={room}
                        cart={cart}
                        startDate={new Date(rooms.data.data.check_in)}
                        endDate={new Date(rooms.data.data.check_out)}
                        addCartItem={addCartItem}
                        removeCartItem={removeCartItem}
                        number_of_nights={rooms.data.data.number_of_nights}
                      />
                    );
                  })
                ) : (
                  <Row className="my-2">
                    <Col className="text-center">
                      <Image src={HotelNotFound} />
                    </Col>
                  </Row>
                )}
              </Col>
              <Col lg={3}>
                <Cart
                  cartTotal={cartTotal}
                  cart={cart}
                  deleteCartItem={deleteCartItem}
                  search_id={rooms.data.data.search_id}
                  property={{
                    property_id: propertyId!,
                    name: profile.data.data.property.name,
                    district_name: profile.data?.data.property.district_name,
                    thana_name: profile.data.data.property.thana_name,
                    image: profile.data.data.property_photos[0]
                      ? profile.data.data.property_photos[0].name
                      : "",
                  }}
                />
              </Col>
            </Row>
          ) : null
        ) : (
          [1, 2, 3].map(() => (
            <Row>
              <Col>
                <Card className="my-2 card_hover">
                  <ContentLoader
                    width="100%"
                    height={200}
                    backgroundColor="#E1E9EE"
                    foregroundColor="#F2F8FC"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="10"
                      ry="10"
                      width="50%"
                      height="200"
                    />
                    <rect
                      x="55%"
                      y="14"
                      rx="6"
                      ry="6"
                      width="40%"
                      height="16"
                    />
                    <rect
                      x="55%"
                      y="40"
                      rx="6"
                      ry="6"
                      width="30%"
                      height="14"
                    />
                  </ContentLoader>
                </Card>
              </Col>
            </Row>
          ))
        )}
        {rooms.isLoadingError ? (
          <RequestFailed
            ErrorMessage={
              (rooms.error as IFetchError).status === "ValidationError"
                ? (rooms.error as IFetchError).reason!
                : (rooms.error as IFetchError).message
            }
            ButtonName="Try Again"
            ButtonTrigger={() => rooms.refetch()}
          />
        ) : null}
        {!profile.isLoading ? (
          profile.data ? (
            <Row>
              <Col lg={9} sm={12}>
                <Card className="p-4 mt-0">
                  <h4 className="fw-bold my-2">Policies</h4>
                  <Row>
                    <Col>
                      <h5 className="fw-bold my-2">Check In</h5>
                      <p>
                        {format(
                          new Date(
                            `${format(new Date(), "MMM dd yyyy")} ${
                              profile.data.data.property.check_in
                            }`
                          ),
                          "hh:mm bbb"
                        )}
                      </p>
                    </Col>
                    <Col>
                      <h5 className="fw-bold my-2">Check Out</h5>
                      <p>
                        {format(
                          new Date(
                            `${format(new Date(), "MMM dd yyyy")} ${
                              profile.data.data.property.check_out
                            }`
                          ),
                          "hh:mm bbb"
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className="fw-bold my-2">Pets</h5>
                      <p>
                        {profile.data.data.property.pets} pets or service
                        animals allowed
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5 className="fw-bold my-2">Children and extra beds</h5>
                      {profile.data.data.property.children === true ? (
                        <p className="mb-1">Children are welcome</p>
                      ) : (
                        <p className="mb-1">Children are not allowed</p>
                      )}
                      {profile.data.data.property
                        .property_extra_bed_available === true ? (
                        <p className="mb-1">
                          {profile.data.data.property.no_of_extra_beds} extra
                          beds are available for BDT{" "}
                          {profile.data.data.property.property_extra_bed_price}{" "}
                          per night
                        </p>
                      ) : (
                        <p className="mb-1">No extra beds are available</p>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : null
        ) : (
          <Row>
            <Col lg={9} sm={12}>
              <Card className="p-4 mt-0">
                <ContentLoader
                  width="100%"
                  height={200}
                  backgroundColor="#E1E9EE"
                  foregroundColor="#F2F8FC"
                >
                  <rect x="0" y="0" rx="6" ry="6" width="35%" height="14" />
                  <rect x="20" y="30" rx="3" ry="3" width="70%" height="11" />
                  <rect x="20" y="60" rx="3" ry="3" width="50%" height="11" />
                  <rect x="0" y="100" rx="6" ry="6" width="30%" height="14" />
                  <rect x="20" y="135" rx="3" ry="3" width="60%" height="11" />
                  <rect x="20" y="160" rx="3" ry="3" width="43%" height="11" />
                </ContentLoader>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
const HandleParams = () => {
  const [searchParams] = useSearchParams();
  const state = {
    start_date: new Date(),
    end_date: addDays(new Date(), 1),
    total_rooms: 1,
    total_guests: 1,
    rooms_guests: [{ adult: 1, child: 0 }],
  };

  if (
    searchParams.get("s_date") &&
    !isBefore(new Date(searchParams.get("s_date")!), startOfDay(new Date()))
  ) {
    state.start_date = new Date(searchParams.get("s_date")!);
  }
  if (
    searchParams.get("e_date") &&
    !isBefore(
      new Date(searchParams.get("e_date")!),
      startOfDay(state.start_date)
    )
  ) {
    state.end_date = new Date(searchParams.get("e_date")!);
  }
  if (searchParams.get("total_rooms")) {
    state.total_rooms = JSON.parse(searchParams.get("total_rooms")!);
  }
  if (searchParams.get("total_guests")) {
    state.total_guests = JSON.parse(searchParams.get("total_guests")!);
  }
  if (searchParams.get("rooms_guests")) {
    state.rooms_guests = JSON.parse(atob(searchParams.get("rooms_guests")!));
  }
  return state;
};
