import React from "react";
import {
  Control,
  FieldValues,
  RegisterOptions,
  useController,
} from "react-hook-form";
import { Col, Form, FormControlProps } from "react-bootstrap";

interface Props extends FormControlProps {
  xs?: string | number;
  lg?: string | number;
  md?: string | number;
  xl?: string | number;
  sm?: string | number;
  type: "checkbox" | "radio";
  label?: string;
  label_class?: string;
  children?: React.ReactElement;
  [x: string]: any;
  name: string;
  control: Control<FieldValues>;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  defaultValue?: string;
}

export default function CheckBox({
  lg,
  xs,
  sm,
  md,
  xl,
  type,
  label,
  label_class,
  name,
  control,
  children,
  rules,
  defaultValue,
  ...rest
}: Props) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue,
  });

  return (
    <Form.Group xs={xs} sm={sm} md={md} lg={lg} xl={xl} as={Col} {...rest}>
      <Form.Check.Input type={type} {...field} isInvalid={invalid} />
      <Form.Check.Label
        style={{ marginLeft: "0.5rem" }}
        className={label_class}
      >
        {label}
      </Form.Check.Label>

      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>

      {children ? children : null}
    </Form.Group>
  );
}
