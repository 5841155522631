import React, { Dispatch, SetStateAction } from "react";
import { Row, Col, Image, Button, Modal, Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancellationPolicy from "./CancellationPolicy";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ICancellationPolicy, PropertyRoom } from "./usePropertyRooms";

interface Props {
  startDate: string | number | Date;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  room: PropertyRoom;
  cancellationPolicy: ICancellationPolicy;
}

export default function RoomDetails(props: Props) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(!props.showModal)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Room Amenities</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel indicators={false}>
          {props.room.room_photos.map((photo, key) => (
            <Carousel.Item key={key}>
              <Image
                height="380"
                width="100%"
                rounded
                src={
                  process.env.REACT_APP_CDN_URL +
                  btoa(
                    JSON.stringify({
                      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                      key: photo.name,
                    })
                  )
                }
                className="d-block w-100"
                alt={`${props.room.name} Photo ${key}`}
                loading="lazy"
              />
            </Carousel.Item>
          ))}
        </Carousel>

        <h2 className="font_20 fw-bold mt-3">{props.room.name}</h2>
        {props.room.room_size_sqft ? (
          <div className="pb-2">{props.room.room_size_sqft} sq ft</div>
        ) : null}
        <div className="pl-3 pb-4">
          <div className="pb-1">
            <FontAwesomeIcon icon="user-friends" /> Sleeps{" "}
            {props.room.occupancy}
          </div>
          <div className="pb-1">
            <FontAwesomeIcon icon="bed" />{" "}
            {props.room.room_beds.map((bed) => bed.name).join(" OR ")}
          </div>
          <div className="pb-1">
            <CancellationPolicy
              cancellation_policy={props.cancellationPolicy}
            />
          </div>
        </div>

        <h6 className="fw-bold my-2">Room Amenities</h6>
        <Row className="my-2 ml-4">
          {props.room.room_amenities.map(
            (amenity: { icon: IconProp; name: string }, key) => (
              <Col lg={6} xs={6} className="my-2" key={key}>
                <FontAwesomeIcon icon={amenity.icon} /> {amenity.name}
              </Col>
            )
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => props.setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
