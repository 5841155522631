import React, { useEffect, useState, lazy, Suspense } from "react";
import { Card, Row, Col, Image, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoomDetails from "./RoomDetails";
import CancellationPolicy from "./CancellationPolicy";
import {
  AddCartItem,
  CartDefinition,
  RemoveCartItem,
} from "../../../../../types/cart";
import { PropertyRoom } from "./usePropertyRooms";

const FsLightbox = lazy(() => import("fslightbox-react"));

interface Props {
  room: PropertyRoom;
  number_of_nights: number;
  startDate: Date;
  endDate: Date;
  addCartItem: AddCartItem;
  removeCartItem: RemoveCartItem;
  cart: CartDefinition[];
}

export default function HotelRoom(props: Props) {
  const [roomCount, setRoomCount] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const openLightboxOnSlide = (number: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  useEffect(() => {
    const cartItem = props.cart.find(
      (i: CartDefinition) =>
        i.id === props.room.id && i.name === props.room.name
    );
    if (cartItem) {
      setRoomCount(cartItem.quantity);
    } else {
      setRoomCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cart]);

  const { per_night, total_nights } = props.room.pricing;

  const guest_per_night_gross_rate =
    Number(per_night.guest.net_rate) +
    Number(per_night.vat) +
    Number(per_night.service_charge);

  const guest_total_nights_gross_rate =
    Number(total_nights.guest.net_rate) +
    Number(total_nights.vat) +
    Number(total_nights.service_charge);

  const b2b_per_night_gross_rate =
    Number(per_night.b2b_agent.net_rate) +
    Number(per_night.vat) +
    Number(per_night.service_charge);

  const b2b_total_nights_gross_rate =
    Number(total_nights.b2b_agent.net_rate) +
    Number(total_nights.vat) +
    Number(total_nights.service_charge);

  return (
    <Row>
      <Col>
        <Card className="mb-3">
          <Row>
            <Col lg={4}>
              {props.room.room_photos[0] ? (
                <Image
                  className="cursor-pointer rc_image_cover"
                  height="100%"
                  width="100%"
                  src={
                    process.env.REACT_APP_CDN_URL +
                    btoa(
                      JSON.stringify({
                        bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                        key: props.room.room_photos[0].name,
                      })
                    )
                  }
                  rounded
                  onClick={() => openLightboxOnSlide(1)}
                  alt={`${props.room.name} Photo`}
                  loading="lazy"
                  srcSet={`${
                    process.env.REACT_APP_CDN_URL +
                    btoa(
                      JSON.stringify({
                        bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                        key: props.room.room_photos[0].name,
                        edits: { resize: { width: 480 } },
                      })
                    )
                  } 480w,
                    ${
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: props.room.room_photos[0].name,
                          edits: { resize: { width: 720 } },
                        })
                      )
                    } 720w,
                    ${
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: props.room.room_photos[0].name,
                          edits: { resize: { width: 960 } },
                        })
                      )
                    } 960w,
                    ${
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: props.room.room_photos[0].name,
                          edits: { resize: { width: 1200 } },
                        })
                      )
                    } 1200w`}
                  sizes="(max-width: 575px) 90vw, (max-width: 990px) 70vw, 25vw"
                />
              ) : null}
              <Suspense fallback={<div></div>}>
                <FsLightbox
                  toggler={lightboxController.toggler}
                  sources={props.room.room_photos.map(
                    (photo: { name: string | number }) =>
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: photo.name,
                        })
                      )
                  )}
                  slide={lightboxController.slide}
                  type="image"
                />
              </Suspense>
            </Col>
            <Col lg={5} className="px-4 pt-4 pb-2">
              <h2 className="font_22 fw-bold">{props.room.name}</h2>
              {props.room.room_size_sqft ? (
                <div className="font_14 pb-2">
                  {props.room.room_size_sqft} sq ft
                </div>
              ) : null}
              <div className="pl-3 font_14">
                <div className="font_14 pb-1">
                  <FontAwesomeIcon icon="user-friends" /> Sleeps{" "}
                  {props.room.occupancy}
                </div>
                <div className="font_14 pb-1">
                  <FontAwesomeIcon icon="bed" />{" "}
                  {props.room.room_beds.map((bed) => bed.name).join(" OR ")}
                </div>
                <div className="pb-1">
                  <CancellationPolicy
                    cancellation_policy={props.room.cancellation_policy}
                  />
                </div>
              </div>
              <div>
                <Button
                  variant="link"
                  className="text-decoration-none btn-outline-light"
                  onClick={() => setShowModal(true)}
                >
                  More details <FontAwesomeIcon icon="angle-right" />
                </Button>
              </div>
            </Col>
            <RoomDetails
              startDate={props.startDate}
              showModal={showModal}
              setShowModal={setShowModal}
              room={props.room}
              cancellationPolicy={props.room.cancellation_policy}
            />
            <Col lg={3} className="px-4 pt-4 pb-2">
              {props.room.available > 0 ? (
                <>
                  <Row className="mb-2">
                    <Col className="text-end">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="font_8 m-0 fw-bold">Guest Price</p>
                        <h2 className="font_12 fw-bold m-0">
                          ৳ {guest_per_night_gross_rate.toFixed(2)}
                        </h2>
                      </div>
                      <p className="font_8 m-0 ms-1">(per night)</p>
                      {props.number_of_nights > 1 ? (
                        <h6 className="font_7 fw-bold">
                          ৳ {guest_total_nights_gross_rate.toFixed(2)} for{" "}
                          {props.number_of_nights} nights
                        </h6>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-end">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="font_8 m-0 fw-bold">Agent Price</p>
                        <h2 className="font_18 fw-bold m-0">
                          ৳ {b2b_per_night_gross_rate.toFixed(2)}
                        </h2>
                      </div>
                      <p className="font_8 m-0 ms-1">(per night)</p>
                      {props.number_of_nights > 1 ? (
                        <h6 className="font_8 fw-bold mb-0">
                          ৳ {b2b_total_nights_gross_rate.toFixed(2)} for{" "}
                          {props.number_of_nights} nights
                        </h6>
                      ) : null}
                    </Col>
                  </Row>
                  <p className="font_8 m-0 text-end">*VAT & TAX included</p>
                  <Row>
                    {roomCount < 1 ? (
                      <Col className="text-end mt-2">
                        <Button
                          className="w-100 fw-bold"
                          variant="primary"
                          type="submit"
                          onClick={() => {
                            props.addCartItem({
                              id: props.room.id,
                              name: props.room.name,
                              photo: props.room.room_photos[0]
                                ? props.room.room_photos[0].name
                                : null,
                              available: props.room.available,
                              b2b_total_nights_gross_rate,
                            });
                            setRoomCount(roomCount + 1);
                          }}
                        >
                          Reserve Room
                        </Button>
                      </Col>
                    ) : (
                      <Col
                        className="mt-2 text-center d-flex reserve_room_multiButtton"
                        lg={12}
                      >
                        <button
                          className="rounded-0 rounded-start btn"
                          onClick={() => {
                            props.removeCartItem({
                              id: props.room.id,
                              name: props.room.name,
                              b2b_total_nights_gross_rate,
                            });
                            setRoomCount(roomCount - 1);
                          }}
                        >
                          <FontAwesomeIcon icon="minus" />
                        </button>
                        <span className="d-flex h-100 align-items-center justify-content-center flex-grow-1">
                          {`${roomCount} ${roomCount > 1 ? "Rooms" : "Room"}`}
                        </span>
                        <button
                          className="rounded-0 rounded-end btn"
                          onClick={() => {
                            props.addCartItem({
                              id: props.room.id,
                              name: props.room.name,
                              photo: props.room.room_photos[0]
                                ? props.room.room_photos[0].name
                                : null,
                              available: props.room.available,
                              b2b_total_nights_gross_rate,
                            });
                            setRoomCount(
                              props.room.available > roomCount
                                ? roomCount + 1
                                : roomCount
                            );
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </button>
                      </Col>
                    )}
                  </Row>
                </>
              ) : (
                <h2 className="font_20 fw-bold text-center">Sold Out</h2>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
