import { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Container, Form, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faEye } from "@fortawesome/free-solid-svg-icons";
import InputButton from "../../../../components/form-elements/Button";
import Input from "../../../../components/form-elements/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import Select from "../../../../components/form-elements/Select";
import addMoneyIcon from "../../../../assets/img/add_money_icon.svg";
import usePayments, { IPayment } from "./hooks/usePayments";
import Pagination from "../../../../components/pagination";
import { format } from "date-fns";
import { IFetchError } from "../../../../types";
import { Toast } from "../../../../utils";
import DateRangePickerCustom from "../../../../components/form-elements/DateRangePicker";
import { TableLoader } from "../../../../components/Loaders";

interface IFormValues {
  payment_status: string;
  transaction_id: string;
  merchant_order_id: string;
  client_mobile_no: string;
  bank_pan: string;
  transaction_type: string;
}

export default function Payments() {
  const { control, handleSubmit } = useForm<IFormValues>();
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);

  const [offset, setOffset] = useState(0);
  const [pageSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [formData, setFormData] = useState({
    from_date: startDate,
    to_date: endDate,
    payment_status: "",
    transaction_id: "",
    merchant_order_id: "",
    client_mobile_no: "",
    bank_pan: "",
    transaction_type: "",
    offset,
    pageSize,
  });

  const payments: any = usePayments({
    ...formData,
    activePage,
  });

  if (payments.isError) {
    Toast.fire({
      icon: "error",
      title: (payments.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  const handlePageClick = (pageNumber: number) => {
    setActivePage(pageNumber);
    setOffset(pageSize * (pageNumber - 1));
    setFormData({ ...formData, offset: pageSize * (pageNumber - 1) });
    payments.refetch();
  };

  const handleFilter: SubmitHandler<IFormValues> = (data) => {
    setOffset(0);
    setFormData({
      ...formData,
      ...data,
      offset: 0,
      from_date: startDate,
      to_date: endDate,
    });
    payments.refetch();
  };

  const generateStatusBadge = (status: string) => {
    let status_badge = "badge";

    status === "succeeded"
      ? (status_badge += " bg-success")
      : status === "initiated"
      ? (status_badge += " bg-warning")
      : (status_badge += " bg-danger");

    return status_badge;
  };

  return (
    <Container>
      <Card className="p-3 mt-2 mt-md-3 mt-lg-4">
        <Form className="form-horizontal" onSubmit={handleSubmit(handleFilter)}>
          <Row className="mt-0 align-items-end">
            <DateRangePickerCustom
              lg={5}
              labels={["Date From", "Date To"]}
              startDate={startDate!}
              endDate={endDate!}
              onStartDateChange={(date) => setStartDate(date!)}
              onEndDateChange={(date) => setEndDate(date!)}
            />

            <Input
              lg={3}
              md={6}
              label="Transaction ID"
              name="transaction_id"
              type="text"
              className="mb-3"
              control={control}
              defaultValue=""
              placeholder="Enter transaction id"
            />

            <Select
              lg={2}
              md={6}
              label="Transaction Type"
              name="transaction_type"
              className="mb-3"
              control={control}
              selectOption="Select type"
              defaultValue=""
              options={[
                { id: "CASH", name: "CASH" },
                { id: "BANK", name: "BANK" },
                { id: "BKASH", name: "BKASH" },
                { id: "NAGAD", name: "NAGAD" },
                { id: "MANUAL", name: "MANUAL" },
                { id: "AMEX", name: "AMEX" },
                { id: "VISA", name: "VISA" },
                { id: "MASTER", name: "MASTER" },
              ]}
            />

            <Select
              lg={2}
              md={6}
              label="Payment Status"
              name="payment_status"
              className="mb-3"
              control={control}
              selectOption="Select status"
              defaultValue=""
              options={[
                { id: "initiated", name: "Initiated" },
                { id: "succeeded", name: "Succeeded" },
                { id: "canceled", name: "Canceled" },
                { id: "failed", name: "Failed" },
              ]}
            />

            <Input
              lg={3}
              md={6}
              label="Merchant Order ID"
              name="merchant_order_id"
              type="text"
              className="mb-3"
              control={control}
              defaultValue=""
              placeholder="Enter merchant order id"
            />
            <Input
              lg={3}
              md={6}
              label="Client Mobile Number"
              name="client_mobile_no"
              type="text"
              className="mb-3"
              control={control}
              defaultValue=""
              placeholder="Enter mobile number"
            />

            <Input
              lg={3}
              md={6}
              label="Bank Pan"
              name="bank_pan"
              type="text"
              className="mb-3"
              control={control}
              defaultValue=""
              placeholder="Enter bank pan"
            />
            <InputButton
              lg={3}
              md={6}
              label="Search"
              type="submit"
              variant="primary"
              className="w-100 mb-3"
            />
          </Row>
        </Form>
      </Card>

      <Card className="mt-4 mt-2 mt-md-3 mt-lg-4 p-2 font_14">
        <div className="d-flex justify-content-between px-2 py-3 align-items-center">
          <h5 className="fw-bold mb-0">Payments</h5>
          <div className="d-flex align-items-center">
            <button className="border-0 bg-white">
              <FontAwesomeIcon
                icon={faFileExcel}
                className="fs-4"
                color="#212529"
              />
            </button>
            <Link to={"/wallet/money/deposit"}>
              <button className="mx-1 mx-sm-2 border-0 bg-white">
                <img
                  src={addMoneyIcon}
                  alt="add_money_icon"
                  className="h-25 add_money_icon"
                />
              </button>
            </Link>
          </div>
        </div>
        <Table
          responsive="xxl"
          className="caption-top"
          bordered
          striped={!payments.isLoading}
          hover={!payments.isLoading}
        >
          <thead className="border-bottom">
            <tr>
              <th>Payment Date</th>
              <th>Transaction ID</th>
              <th>Order ID</th>
              {/* <th>Deposited to</th> */}
              <th>Gateway</th>
              <th>Payment status</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {payments.isLoading ? (
              <TableLoader columnLength={7} />
            ) : payments.isSuccess ? (
              <>
                {payments.data.data.payments.map((payment: IPayment) => {
                  return (
                    <tr className="my-1" key={payment.id}>
                      <td>
                        {payment.created_at
                          ? format(
                              new Date(payment.created_at),
                              "dd MMM yyyy hh:mm:ss aa"
                            )
                          : null}
                      </td>
                      <td>{payment.transaction_id}</td>
                      <td>{payment.order_id}</td>

                      {/* <td>{payment.deposited_to_account}</td> */}
                      <td>{payment.gateway_type}</td>
                      <td>
                        <Badge bg={generateStatusBadge(payment.status)}>
                          {payment.status === "initiated" &&
                          payment.gateway_type.includes("Manual")
                            ? "Pending"
                            : payment.status}
                        </Badge>
                      </td>
                      <td className="fw-bold">{payment.amount}</td>
                      <td>
                        <Link
                          to={`/payment/details/${payment.id}`}
                          className="btn btn-warning"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <h4>Data not found!</h4>
            )}
          </tbody>
        </Table>

        {payments.isSuccess && (
          <Pagination
            count={Number(payments.data.data.count[0].count)}
            handlePageClick={handlePageClick}
            pageSize={pageSize}
            activePage={activePage}
            name="payment"
          />
        )}
      </Card>
    </Container>
  );
}
