import React, { useEffect, useState } from "react";
import "./ToggleSwitch.scss";
import takaIcon from "../../assets/img/taka_icon.svg";
import { useQuery } from "react-query";
import Fetch from "../../utils/fetch";
import { useNavigate } from "react-router-dom";

interface IToggleBalance {
  name?: string;
  small?: boolean;
  disabled?: boolean;
}

export default function ToggleBalance({
  name,
  small,
  disabled,
}: IToggleBalance) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [optionLabels, setOptionLabels] = useState([
    "Loading...",
    "Tab for balance",
  ]);

  const balance = useQuery(
    ["wallet-balance"],
    async () =>
      await Fetch<string>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/balance`,
        navigate,
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    if (checked) {
      balance.refetch();
      if (balance.isSuccess) {
        setOptionLabels([balance.data?.data, "Tab for balance"]);
        setTimeout(() => {
          setChecked(false);
        }, 10000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, balance.isSuccess]);

  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id="balance-check"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        disabled={disabled}
      />
      <label
        className="toggle-switch-label"
        tabIndex={disabled ? -1 : 1}
        htmlFor="balance-check"
      >
        <span
          className={
            disabled
              ? "toggle-switch-inner toggle-switch-disabled"
              : "toggle-switch-inner"
          }
          data-yes={optionLabels[0]}
          data-no={optionLabels[1]}
          tabIndex={-1}
        />
        <span
          className={
            disabled
              ? "toggle-switch-switch toggle-switch-disabled"
              : "toggle-switch-switch"
          }
          tabIndex={-1}
        >
          <img src={takaIcon} alt="taka_icon" className="taka_icon" />
        </span>
      </label>
    </div>
  );
}
