import { format } from "date-fns";
import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ITransactionDetails } from "./transactionDetails";

interface IProps {
  data: ITransactionDetails;
}

export default function TransactionDetailsTable({ data }: IProps) {
  const generateStatusBadge = (status: boolean) => {
    let status_badge = "badge";

    status ? (status_badge += " bg-success") : (status_badge += " bg-danger");

    return status_badge;
  };
  return (
    <Table striped bordered hover>
      <tbody>
        <tr>
          <th>Source</th>
          <td>{data.source}</td>
        </tr>
        <tr>
          <th>Transactiopn Type</th>
          <td>{data.type}</td>
        </tr>
        <tr>
          <th>Transaction Amount</th>
          <td>
            {data.amount} {data.currency}
          </td>
        </tr>
        <tr>
          <th>Code</th>
          <td>{data.code}</td>
        </tr>
        <tr>
          <th>Transaction Status</th>
          <td>
            <Badge bg={generateStatusBadge(data.status)}>
              {data.status ? "Success" : "Failed"}
            </Badge>
          </td>
        </tr>
        <tr>
          <th>Now Total balance</th>
          <td>
            {data.new_balance} {data.currency}
          </td>
        </tr>

        <tr>
          <th>Transaction DateTime</th>
          <td>
            {format(new Date(data.created_at), "dd MMM yyyy hh:mm:ss aa")}
          </td>
        </tr>

        <tr>
          <th>Created By</th>
          <td>{data.created_name}</td>
        </tr>

        {data.property_reservation_id ? (
          <tr>
            <th>Property Reservation Id</th>
            <td>
              <span className="text-primary fw-bold">
                <Link
                  to={`/manage/booking/details/${data.property_reservation_id}`}
                >
                  {data.property_reservation_id}
                </Link>
              </span>
            </td>
          </tr>
        ) : null}

        {data.payment_id ? (
          <tr>
            <th>Payment Id</th>
            <td>
              <Link
                to={`/payment/details/${data.payment_id}`}
                className="text-primary fw-bold"
              >
                {data.payment_id}
              </Link>
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
}
