import { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import InputButton from "../../../../../components/form-elements/Button";
import Input from "../../../../../components/form-elements/Input";
import { TableLoader } from "../../../../../components/Loaders";
import { IFetchError } from "../../../../../types";
import { Toast } from "../../../../../utils";
import Fetch from "../../../../../utils/fetch";
import SelectPaymentOption from "../selectPaymentOption";
import BkashPayment from "./bKash/Button";
import bKashInit from "./bKash/initialize";
import CityBankInitialize from "./citybank/initialize";
import NagadInitialize from "./nagad/initialize";

type FormValues = {
  amount: number;
  payment_type: string;
  final_amount: number;
  gateway_charge: number;
  conversion_rate: number;
};

type PaymentTypes = {
  id: number;
  name: string;
  code_name: string;
  service_charge: number;
}[];

export default function OnlinePayment() {
  const navigate = useNavigate();
  const bKashButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const payment_types = useQuery(
    ["payment_types", "Online"],
    async () =>
      await Fetch<PaymentTypes>({
        method: "POST",
        url: process.env.REACT_APP_BACKEND_URL + "/api/dropdowns/payment/types",
        body: { mode: "Online", status: true },
        navigate,
      })
  );

  if (payment_types.isError) {
    Toast.fire({
      icon: "error",
      title: (payment_types.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      conversion_rate: 0,
      gateway_charge: 0,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (data.payment_type === undefined) {
      Swal.fire("Failed!", "Please select a payment type", "error");
    } else {
      if (JSON.parse(data.payment_type).code_name === "bKash") {
        bKashInit({
          amount: data.amount,
          setIsLoading,
          navigate,
          queryClient,
        });

        bKashButtonRef.current?.click();
      } else if (JSON.parse(data.payment_type).code_name.includes("CityBank")) {
        CityBankInitialize({
          amount: data.amount,
          setIsLoading,
          navigate,
        });
      } else if (JSON.parse(data.payment_type).code_name.includes("Nagad")) {
        NagadInitialize({
          amount: data.amount,
          setIsLoading,
          navigate,
        });
      }
    }
  };

  const watchAmount = watch("amount");
  const watchPaymentType: string = watch("payment_type");

  useEffect(() => {
    if (watchPaymentType) {
      const amount = Number(watchAmount) || 0;
      const conversion_rate = Number(
        JSON.parse(watchPaymentType).service_charge
      );
      const gateway_charge = amount * (conversion_rate / 100);
      const final_amount = amount - gateway_charge;

      setValue("conversion_rate", Number(conversion_rate.toFixed(2)));
      setValue("gateway_charge", Number(gateway_charge.toFixed(2)));
      setValue("final_amount", Number(final_amount.toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAmount, watchPaymentType]);

  return (
    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={3} className="bg-light">
          <Row className="online_payment_option mt-2">
            <h6>Choose payment gateway</h6>
            {!payment_types.isLoading ? (
              payment_types.isSuccess ? (
                payment_types.data.data.map((type, key) => (
                  <SelectPaymentOption
                    key={key}
                    control={control}
                    name="payment_type"
                    label={type.name}
                    data={type}
                    rules={{ required: true }}
                  />
                ))
              ) : (
                <h5 className="text-center">Data no found!</h5>
              )
            ) : (
              <Table className="bg-white">
                <TableLoader columnLength={1} />
              </Table>
            )}
          </Row>
        </Col>
        <Col md={9}>
          <Row className="mt-0">
            <Input
              md={12}
              name="amount"
              type="number"
              label="Amount"
              control={control}
              group_class="mb-3"
              placeholder="Enter amount"
              step=".01"
              rules={{
                min: 1,
                required: "Please provide a Amount",
              }}
            />
            <Input
              md={6}
              disabled
              type="text"
              name="gateway_charge"
              label="Gateway Charge"
              control={control}
              group_class="mb-3"
            />
            <Input
              md={6}
              disabled
              type="text"
              name="conversion_rate"
              label="Conversion Rate"
              control={control}
              group_class="mb-3"
            />
            <Input
              md={12}
              disabled
              type="text"
              name="final_amount"
              label="Final Amount"
              control={control}
              group_class="mb-3"
              children={
                <span className="font_14 text-warning">
                  This amount will be added to balance
                </span>
              }
            />
            <InputButton
              md={12}
              label={
                isLoading ? (
                  <>
                    SUBMITTING{" "}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">...</span>
                  </>
                ) : (
                  "SUBMIT"
                )
              }
              disabled={isLoading}
              variant="primary"
              type="submit"
              className="w-100"
            />
          </Row>
        </Col>
      </Row>
      <BkashPayment bKashButtonRef={bKashButtonRef} />
    </Form>
  );
}
