import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { IFetchError } from "../../../../../types";
import { Toast } from "../../../../../utils";
import Fetch from "../../../../../utils/fetch";

type BanksType = {
  id: number;
  name: string;
}[];

export function useBankList(props: { enabled: boolean }) {
  const navigate = useNavigate();
  return useQuery(
    ["banks"],
    async () =>
      await Fetch<BanksType>({
        method: "GET",
        url: process.env.REACT_APP_BACKEND_URL + "/api/dropdowns/banks",
        navigate,
      }),
    {
      enabled: props.enabled,
      onError: (error) =>
        Toast.fire({
          icon: "error",
          title: (error as IFetchError).message,
          position: "bottom-left",
          showCloseButton: true,
        }),
    }
  );
}
