import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addDays, format } from "date-fns";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import HotelNotFound from "../../../../assets/img/hotel_not_found.svg";
import ContentLoader from "react-content-loader";
import SearchDestination from "../../../../components/SearchDestination";
import DateRangePickerCustom from "../../../../components/form-elements/DateRangePicker";
import RequestFailed from "../../../../components/RequestFailed";
import useProperties from "./useProperties";

interface IState {
  startDate: Date;
  endDate: Date;
  searchDestination: {
    country_name: string;
    destination_type: string;
    district_name: string;
    division_name: string;
    id: number;
    name: string;
    thana_name: string;
  };
  roomsGuests: { adult: number; child: number }[];
}

export default function PropertySearch() {
  const location = useLocation().state as IState;

  const [startDate, setStartDate] = useState(
    location ? location.startDate : new Date()
  );
  const [endDate, setEndDate] = useState(
    location ? location.endDate : addDays(new Date(), 2)
  );
  const [searchDestination, setSearchLocation] = useState(
    location
      ? location.searchDestination
      : {
          destination_type: "district",
          id: 1,
          name: "Dhaka",
        }
  );
  const [roomsGuests, setRoomsGuests] = useState(
    location ? location.roomsGuests : [{ adult: 1, child: 0 }]
  );
  const [totalRooms, setTotalRooms] = useState(1);
  const [totalGuests, setTotalGuests] = useState(1);
  const navigate = useNavigate();

  const observer = useRef<IntersectionObserver>();

  // calculate total rooms and guests

  useEffect(() => {
    let total_guests = 0;
    roomsGuests.forEach((room_guest: any) => {
      total_guests += room_guest.adult;
      total_guests += room_guest.child;
    });
    setTotalRooms(roomsGuests.length);
    setTotalGuests(total_guests);
  }, [roomsGuests]);

  const properties = useProperties({
    location_type: searchDestination.destination_type,
    location_id: searchDestination.id,
    start_date: format(new Date(startDate), "yyyy-MM-dd"),
    end_date: format(new Date(endDate), "yyyy-MM-dd"),
  });

  const lastBookElementRef = useCallback(
    (node) => {
      if (properties.isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && properties.hasNextPage) {
          properties.fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [properties.hasNextPage]
  );

  const handlePropertySearch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (searchDestination?.destination_type === "property") {
      navigate(
        `/search/property/${searchDestination.id}?s_date=${format(
          startDate,
          "yyyy-MM-dd"
        )}&e_date=${format(
          endDate,
          "yyyy-MM-dd"
        )}&total_rooms=${totalRooms}&total_guests=${totalGuests}&rooms_guests=${btoa(
          JSON.stringify(roomsGuests)
        )}`
      );
    } else {
      properties.refetch();
    }
  };

  return (
    <>
      <Container className="my-5">
        <Row className="my-2">
          <Col>
            <Form onSubmit={handlePropertySearch}>
              <Row>
                <SearchDestination
                  lg={4}
                  searchDestination={searchDestination}
                  setSearchDestination={setSearchLocation}
                />
                <DateRangePickerCustom
                  labels={["Check In", "Check Out"]}
                  labelClass="cm-form-label"
                  className="cm-form-input"
                  icon={true}
                  lg={6}
                  minimumLength={1}
                  minimumDate={new Date()}
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={(date) => setStartDate(date!)}
                  onEndDateChange={(date) => setEndDate(date!)}
                  required={true}
                />
                {/* <RoomsGuests
                  lg={2}
                  totalGuests={totalGuests}
                  totalRooms={totalRooms}
                  rooms_guests={roomsGuests}
                  setRoomsGuests={setRoomsGuests}
                /> */}
                <Form.Group
                  className="d-flex align-items-center position-relative mb-3"
                  lg={2}
                  as={Col}
                >
                  <Button
                    className="w-100 fw-bold py-3"
                    variant="primary"
                    type="submit"
                  >
                    Search
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </Col>
        </Row>

        {properties.data?.pages.map((page) =>
          page.data.properties.map((property, key: any) => (
            <Row
              key={key}
              ref={
                page.data.properties.length === key + 1
                  ? lastBookElementRef
                  : undefined
              }
            >
              <Col>
                <Card className="my-2 card_hover">
                  <Link
                    className="text-decoration-none disable_hover_color"
                    to={`/search/property/${property.id}?s_date=${format(
                      new Date(page.data.check_in),
                      "yyyy-MM-dd"
                    )}&e_date=${format(
                      new Date(page.data.check_out),
                      "yyyy-MM-dd"
                    )}&total_rooms=${totalRooms}&total_guests=${totalGuests}&rooms_guests=${btoa(
                      JSON.stringify(roomsGuests)
                    )}`}
                  >
                    <Row>
                      <Col lg={4}>
                        <Image
                          height="250"
                          src={
                            process.env.REACT_APP_CDN_URL +
                            btoa(
                              JSON.stringify({
                                bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                                key: property.photo,
                              })
                            )
                          }
                          rounded
                          className="w-100 rc_image_cover"
                          alt={`${property.name} Photo`}
                          loading="lazy"
                          srcSet={`${
                            process.env.REACT_APP_CDN_URL +
                            btoa(
                              JSON.stringify({
                                bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                                key: property.photo,
                                edits: { resize: { width: 480 } },
                              })
                            )
                          } 480w,
                                ${
                                  process.env.REACT_APP_CDN_URL +
                                  btoa(
                                    JSON.stringify({
                                      bucket:
                                        process.env.REACT_APP_AWS_S3_BUCKET,
                                      key: property.photo,
                                      edits: { resize: { width: 720 } },
                                    })
                                  )
                                } 720w,
                                ${
                                  process.env.REACT_APP_CDN_URL +
                                  btoa(
                                    JSON.stringify({
                                      bucket:
                                        process.env.REACT_APP_AWS_S3_BUCKET,
                                      key: property.photo,
                                      edits: { resize: { width: 960 } },
                                    })
                                  )
                                } 960w,
                                ${
                                  process.env.REACT_APP_CDN_URL +
                                  btoa(
                                    JSON.stringify({
                                      bucket:
                                        process.env.REACT_APP_AWS_S3_BUCKET,
                                      key: property.photo,
                                      edits: { resize: { width: 1200 } },
                                    })
                                  )
                                } 1200w`}
                          sizes="(max-width: 575px) 90vw, (max-width: 990px) 70vw, 25vw"
                        />
                      </Col>
                      <Col lg={5} className="p-4">
                        <h2 className="font_26 fw-bold">
                          {property.name}{" "}
                          {[1, 2, 3, 4, 5].map((rate, key) => (
                            <FontAwesomeIcon
                              key={key}
                              className={`${
                                property.rating >= rate ? "star-checked" : null
                              } font_14`}
                              icon={faStar}
                            />
                          ))}
                        </h2>

                        <div>
                          {property.thana_name}, {property.district_name}
                        </div>

                        <Row className="my-3">
                          <Col lg={6} xs={6} className="my-2">
                            <FontAwesomeIcon icon="coffee" /> Breakfast{" "}
                            {property.property_breakfast === "No"
                              ? "Not Included"
                              : property.property_breakfast}
                          </Col>

                          {property.property_parking.includes("Yes") ? (
                            <Col lg={6} xs={6} className="my-2">
                              <FontAwesomeIcon icon="parking" /> Parking
                            </Col>
                          ) : null}

                          {property.facilities.map(
                            (
                              facility: { icon: IconProp; name: string },
                              key: React.Key
                            ) => {
                              return key < 4 ? (
                                <Col lg={6} xs={6} className="my-2" key={key}>
                                  <FontAwesomeIcon icon={facility.icon} />{" "}
                                  {facility.name}
                                </Col>
                              ) : null;
                            }
                          )}
                        </Row>
                      </Col>
                      <Col lg={3} className="p-5 text-end">
                        <h6 className="mb-0 font_14">Guest Price</h6>
                        <h2 className="font_20 fw-bold">
                          ৳ {property.pricing.per_night.guest.gross_rate}
                        </h2>
                        <h6 className="mb-0">Agent Price</h6>
                        <h2 className="font_26 fw-bold">
                          ৳ {property.pricing.per_night.b2b_agent.gross_rate}
                        </h2>
                        <p className="font_12 m-0">per night</p>
                        <p className="font_12 m-0">VAT & TAX inclued</p>
                      </Col>
                    </Row>
                  </Link>
                </Card>
              </Col>
            </Row>
          ))
        )}

        {!properties.isLoading ? (
          properties.data?.pages[0].data.properties.length === 0 ? (
            <Row className="my-2">
              <Col className="text-center">
                <Image src={HotelNotFound} />
              </Col>
            </Row>
          ) : null
        ) : null}

        {properties.isLoadingError ? (
          <RequestFailed
            ErrorMessage={`Oops! Looks like you followed a bad link. Let's get you back on track.`}
            ButtonName="Go Back"
            ButtonTrigger={() => navigate("/dashboard")}
          />
        ) : null}

        {properties.isLoading || properties.isFetchingNextPage
          ? [1, 2, 3].map(() => (
              <Row>
                <Col>
                  <Card className="my-2 card_hover">
                    <ContentLoader
                      width="100%"
                      height={200}
                      backgroundColor="#E1E9EE"
                      foregroundColor="#F2F8FC"
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="10"
                        ry="10"
                        width="45%"
                        height="200"
                      />
                      <rect
                        x="50%"
                        y="14"
                        rx="6"
                        ry="6"
                        width="40%"
                        height="16"
                      />
                      <rect
                        x="50%"
                        y="40"
                        rx="6"
                        ry="6"
                        width="30%"
                        height="14"
                      />
                    </ContentLoader>
                  </Card>
                </Col>
              </Row>
            ))
          : null}
      </Container>
    </>
  );
}
