import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../utils/fetch";

export interface IReservation {
  id: number;
  guest_name: string;
  property_name: string;
  check_in: string;
  check_out: string;
  number_of_nights: number;
  reservation_status: string;
  created_at: Date;
  rooms: number;
  grand_total_rate: number;
  total_paid_amount: number;
  payment_status: string;
  checkin_time: string;
  is_invoice_closed: boolean;
}

interface IFetchData {
  count: {
    count: string;
  }[];
  reservations: IReservation[];
}

interface IParams {
  from_date: Date | null;
  to_date: Date | null;
  reservation_id: string;
  date_type: string;
  reservation_status: string;
  payment_status: string;
  guest_name: string;
  guest_email: string;
  guest_mobile_no: string;
  offset: number;
  pageSize: number;
}

export default function useReservations(params: IParams) {
  const navigate = useNavigate();

  return useQuery(
    [{ scope: "reservations", params }],
    async ({ queryKey: [{ params }] }) =>
      await Fetch<IFetchData>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/booking/property/reservations`,
        body: params,
        navigate,
      }),
    { keepPreviousData: true }
  );
}
