import React from "react";
import { Col, FormGroup, Button, ButtonProps } from "react-bootstrap";

interface Props extends ButtonProps {
  xs?: string | number;
  lg?: string | number;
  md?: string | number;
  xl?: string | number;
  sm?: string | number;
  label: string | React.ReactElement;
  label_class?: string;
  icon?: React.ReactElement;
}

function InputButton({
  md,
  xl,
  xs,
  lg,
  sm,
  label,
  label_class,
  icon,
  ...rest
}: Props) {
  return (
    <FormGroup xs={xs} sm={sm} md={md} lg={lg} xl={xl} as={Col}>
      <Button {...rest}>
        {icon ? icon : null}
        <span className={label_class}>{label}</span>
      </Button>
    </FormGroup>
  );
}

export default InputButton;
