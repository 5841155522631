import {
  faAngleRight,
  faBath,
  faBed,
  faChild,
  faCoffee,
  faConciergeBell,
  faDumbbell,
  faEnvelope,
  faEye,
  faFan,
  faGlassMartiniAlt,
  faHotTub,
  faMinus,
  faParking,
  faPhone,
  faPlus,
  faSeedling,
  faShuttleVan,
  faSmokingBan,
  faSnowflake,
  faSpa,
  faStar,
  faSwimmer,
  faSwimmingPool,
  faThermometerFull,
  faTv,
  faUserFriends,
  faUtensils,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";

const Icons = [
  faEnvelope,
  faPhone,
  faUtensils,
  faConciergeBell,
  faGlassMartiniAlt,
  faDumbbell,
  faSeedling,
  faSmokingBan,
  faSpa,
  faHotTub,
  faChild,
  faSwimmingPool,
  faSwimmer,
  faWifi,
  faSnowflake,
  faShuttleVan,
  faStar,
  faCoffee,
  faParking,
  faBath,
  faTv,
  faEye,
  faThermometerFull,
  faFan,
  faUserFriends,
  faAngleRight,
  faPlus,
  faMinus,
  faBed,
];
export default Icons;
