import Swal from "sweetalert2";

export function LoadScript(id: string, jsSrc: string) {
  return new Promise((resolve, reject) => {
    const element = document.getElementsByTagName("script")[0];
    const first_js = element;

    let js = element;
    js = document.createElement("script");
    js.id = id;
    js.src = jsSrc;
    js.async = true;

    if (first_js && first_js.parentNode) {
      first_js.parentNode.insertBefore(js, first_js);
    } else {
      document.head.appendChild(js);
    }
    js.onerror = reject;
    js.onload = resolve;
  });
}

export function RemoveScript(id: string) {
  const element = document.getElementById(id);

  if (element) {
    element?.parentNode?.removeChild(element);
  }
}

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
