import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Image,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { Link, useLocation } from "react-router-dom";
import PriceDetails from "./PriceDetails";
import Input from "../../../../components/form-elements/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { ReservationGuestForm } from "../../../../types";
import { HoldReservation } from "../property_profile/holdReservation";
import useReservationMutation from "./useReservationMutation";
import Swal from "sweetalert2";

interface LocationState {
  property: {
    property_id: number;
    name: string;
    district_name: string;
    thana_name: string;
    image: string;
  };
  reservation: HoldReservation;
}

export default function PropertyCheckout() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { property, reservation } = location.state as LocationState;

  const { control, handleSubmit, setError } = useForm<ReservationGuestForm>();

  const reservation_mutation = useReservationMutation({ setError });

  const onReservationSubmit: SubmitHandler<ReservationGuestForm> = async (
    data
  ) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to confirm this reservation?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        reservation_mutation.mutate({
          reservation_id: reservation.id,
          guest_name: data.guest_name,
          guest_email: data.guest_email,
          guest_mobile_no: data.guest_mobile_no,
          guest_address: data.guest_address,
          guest_special_request: data.guest_special_request,
        });
      }
    });
  };

  return (
    <>
      <Container className="my-4 pb-5">
        <Row className="flex-row-reverse">
          <Col lg={4}>
            <Row>
              <Col>
                <Card className="my-3">
                  <Row>
                    <Col>
                      <Image
                        src={
                          process.env.REACT_APP_CDN_URL +
                          btoa(
                            JSON.stringify({
                              bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                              key: property.image,
                            })
                          )
                        }
                        rounded
                        width="100%"
                        height="200"
                        className="rc_image_cover"
                        srcSet={`${
                          process.env.REACT_APP_CDN_URL +
                          btoa(
                            JSON.stringify({
                              bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                              key: property.image,
                              edits: { resize: { width: 480 } },
                            })
                          )
                        } 480w,
                        ${
                          process.env.REACT_APP_CDN_URL +
                          btoa(
                            JSON.stringify({
                              bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                              key: property.image,
                              edits: { resize: { width: 720 } },
                            })
                          )
                        } 720w,
                        ${
                          process.env.REACT_APP_CDN_URL +
                          btoa(
                            JSON.stringify({
                              bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                              key: property.image,
                              edits: { resize: { width: 960 } },
                            })
                          )
                        } 960w,
                        ${
                          process.env.REACT_APP_CDN_URL +
                          btoa(
                            JSON.stringify({
                              bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                              key: property.image,
                              edits: { resize: { width: 1200 } },
                            })
                          )
                        } 1200w`}
                        sizes="(max-width: 575px) 90vw, (max-width: 990px) 70vw, 25vw"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mx-3">
                      <h5 className="fw-bold mt-3 mb-1">{property.name}</h5>
                      <p>
                        {property.district_name}, {property.thana_name}
                      </p>
                      <p className="mb-1">
                        Check-in:{" "}
                        <strong>
                          {format(
                            new Date(reservation.check_in),
                            "dd MMM yyyy"
                          )}
                        </strong>
                      </p>
                      <p className="mb-2">
                        Check-out:{" "}
                        <strong>
                          {format(
                            new Date(reservation.check_out),
                            "dd MMM yyyy"
                          )}
                        </strong>
                      </p>
                      <p className="mb-3">
                        {reservation.number_of_nights}-night stay
                      </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <PriceDetails
              rooms_details={reservation.rooms_details}
              sub_total_rate={reservation.sub_total_rate}
              total_service_charge={reservation.total_service_charge}
              total_vat={reservation.total_vat}
              grand_total_rate={reservation.grand_total_rate}
            />
          </Col>
          <Col lg={8}>
            <Row>
              <Col>
                <Card className="p-4 my-3">
                  <Row className="mb-2">
                    <Col lg={6} sm={12} xs={12}>
                      <h5 className="fw-bold pb-2">
                        <FontAwesomeIcon icon={faUser} /> Customers Informations
                      </h5>
                    </Col>
                  </Row>
                  <Form
                    id="guest_info"
                    onSubmit={handleSubmit(onReservationSubmit)}
                  >
                    <Row className="mb-3">
                      <Input
                        lg={6}
                        sm={12}
                        xs={12}
                        label="Name"
                        name="guest_name"
                        type="text"
                        placeholder="Guest Name"
                        aria-autocomplete="none"
                        control={control}
                      />
                      <Input
                        lg={6}
                        sm={12}
                        xs={12}
                        label="Email"
                        name="guest_email"
                        type="email"
                        placeholder="Guest Email"
                        aria-autocomplete="none"
                        control={control}
                      />
                    </Row>
                    <Row className="mb-3">
                      <Input
                        lg={6}
                        sm={12}
                        xs={12}
                        label="Phone"
                        name="guest_mobile_no"
                        type="tel"
                        placeholder="Guest Phone"
                        aria-autocomplete="none"
                        control={control}
                      />
                      <Input
                        lg={6}
                        sm={12}
                        xs={12}
                        label="Address"
                        name="guest_address"
                        as="textarea"
                        // rows={1}
                        placeholder="Guest Address"
                        aria-autocomplete="none"
                        control={control}
                      />
                    </Row>
                    <Row className="mb-3">
                      <Input
                        lg={12}
                        label={
                          <>
                            <strong>Special Requests</strong>
                            <br />
                            <span className="font_14">
                              Please write your requests. Special requests
                              cannot be guaranteed – but the property will do
                              its best to meet your needs. You can always make a
                              special request after your booking is complete!
                            </span>
                          </>
                        }
                        name="guest_special_request"
                        as="textarea"
                        // rows={2}
                        placeholder="Some Request..."
                        aria-autocomplete="none"
                        control={control}
                      />
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="p-4 my-3">
                  <h4 className="fw-bold pb-3">
                    <FontAwesomeIcon icon={faUserShield} /> Privacy policy
                  </h4>
                  <dl>
                    <dd className="font_14">
                      Description privacy policy is a statement or a legal
                      document that discloses some or all of the ways a party
                      gathers, uses, discloses, and manages a customer or
                      client's data. It fulfills a legal requirement to protect
                      a customer or client's privacy.
                    </dd>
                  </dl>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Check id="check_policy">
                        <Form.Check.Input
                          type="checkbox"
                          required
                          form="guest_info"
                        />
                        <Form.Check.Label>
                          By selecting this, I acknowledge that I have read and
                          accepted the <b></b>
                          <Link
                            className="text-decoration-none cm_link fw-bold"
                            to="/policies#user_agreement"
                            target="_blank"
                          >
                            User Agreement
                          </Link>
                          ,{" "}
                          <Link
                            className="text-decoration-none cm_link fw-bold"
                            to="/policies#cancellation_refund_policies"
                            target="_blank"
                          >
                            Cancellation & Refund Policies
                          </Link>{" "}
                          and{" "}
                          <Link
                            className="text-decoration-none cm_link fw-bold"
                            to="/policies#privacy_policy"
                            target="_blank"
                          >
                            Privacy Policy.
                          </Link>
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} lg={5} className="my-2 d-grid gap-2">
                      <Button
                        className="fw-bold"
                        form="guest_info"
                        variant="warning"
                        size="lg"
                        type="submit"
                      >
                        Confirm Booking
                      </Button>
                    </Form.Group>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
