import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { IFetchError } from "../../../../types";
import Fetch from "../../../../utils/fetch";
import { HoldReservation } from "./holdReservation";

interface ReservationReqBody {
  property_id: string;
  search_id: number;
  rooms_details: {
    id: number | string;
    quantity: number;
  }[];
}

interface IParams {
  property: {
    property_id: string;
    name: string;
    district_name: string;
    thana_name: string;
    image: string;
  };
}

export default function useHoldReservation(params: IParams) {
  const navigate = useNavigate();

  return useMutation(
    (body: ReservationReqBody) => {
      return Fetch<HoldReservation>({
        method: "POST",
        url:
          process.env.REACT_APP_BACKEND_URL +
          "/api/b2b/booking/property/reservation/hold",
        body,
        navigate,
      });
    },
    {
      onSuccess: (data) => {
        navigate("/booking/property/checkout", {
          state: {
            property: params.property,
            reservation: { ...data.data },
          },
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: (error as IFetchError).message,
          text: (error as IFetchError).reason,
        });
      },
    }
  );
}
