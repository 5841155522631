import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../../utils/fetch";

export interface PropertyProfileData {
  property_photos: { name: string }[];
  property_facilities: { icon: IconProp; name: string; priority: number }[];
  property: {
    rating: number;
    name: string;
    property_parking: string;
    property_breakfast: string;
    property_parking_space: string;
    property_parking_location: string;
    property_parking_price: string;
    property_parking_reserve: string;
    latitude: number;
    longitude: number;
    division_name: string;
    district_name: string;
    thana_name: string;
    address: string;
    post_code: string;
    property_type: string;
    country_name: string;
    check_in: string;
    check_out: string;
    pets: string;
    children: boolean;
    property_extra_bed_available: boolean;
    no_of_extra_beds: string;
    property_extra_bed_price: number;
  };
}

interface Params {
  propertyId?: string;
}

export function usePropertyProfile(params: Params) {
  const navigate = useNavigate();
  return useQuery(
    [{ scope: "property", entity: "profile", propertyId: params.propertyId }],
    async ({ queryKey: [{ propertyId }] }) =>
      await Fetch<PropertyProfileData>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/booking/search/property_profile/${propertyId}`,
        navigate,
      }),
    { keepPreviousData: true }
  );
}
