import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import Fetch from "../../../../utils/fetch";

interface CancelReqBody {
  reservation_id: number;
}

interface CancelResponse {
  reservation: {
    reservation_id: number;
    status: string;
    created_at: Date;
  };
  refund: {
    total_amount: number;
  };
}

export default function useCancelBookingMutation() {
  const navigate = useNavigate();

  return useMutation(({ reservation_id }: CancelReqBody) => {
    return Fetch<CancelResponse>({
      method: "DELETE",
      url:
        process.env.REACT_APP_BACKEND_URL +
        `/api/b2b/booking/property/reservation/cancel/${reservation_id}`,
      navigate,
    });
  });
}
