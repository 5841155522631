import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

interface IProps {
  newRoomRate?: string[];
  total_vat: number;
  total_rate: number;
  total_paid: number;
  total_service_charge: number;
  sub_total_rate: number;
  number_of_nights: number;
  poperty_reservation_details: {
    quantity: number;
    rate: number;
    room_name: string;
    cancellation_penalty: string;
    cancellation_period: string;
    occupancy: number;
  }[];
}

export default function PrintRoomDetails(props: IProps) {
  const [rate, setRate] = useState(props.newRoomRate);

  const total_vat = Number(props.total_vat);
  const total_service_charge = Number(props.total_service_charge);

  const sub_total_rate = props.poperty_reservation_details.reduce(
    (a, b, index) => {
      return (
        a +
        Number(rate ? rate[index] : b.rate) *
          b.quantity *
          props.number_of_nights
      );
    },
    0
  );

  useEffect(() => {
    setRate(props.newRoomRate);
  }, [props.newRoomRate]);

  return (
    <Table bordered size="sm" responsive className="border-dark mb-4">
      <thead>
        <tr>
          <th className="text-start p-2 font_20" colSpan={5}>
            Room Details
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="text-center">
          <th>Room Category</th>
          <th># Rooms</th>
          <th># Nights</th>
          <th>Avg. Rate/Night</th>
          <th>Total (BDT)</th>
        </tr>
        {props.poperty_reservation_details.map((room, index) => {
          const room_rate = Number(room.rate) / props.number_of_nights;

          return (
            <tr key={room.room_name}>
              <td>{room.room_name}</td>
              <td className="text-center">{room.quantity}</td>
              <td className="text-center">{props.number_of_nights}</td>
              <td className="text-end">
                {rate ? rate[index] : room_rate.toFixed(2)}
              </td>
              <td className="text-end">
                {(
                  Number(rate ? rate[index] : room_rate) *
                  room.quantity *
                  props.number_of_nights
                ).toFixed(2)}
              </td>
            </tr>
          );
        })}
        <tr>
          <th colSpan={4}>Net Room Price</th>
          <td className="text-end">{sub_total_rate.toFixed(2)}</td>
        </tr>
        <tr>
          <th colSpan={4}>Service Charge</th>
          <td className="text-end">{total_service_charge.toFixed(2)}</td>
        </tr>

        <tr>
          <th colSpan={4}>VAT </th>
          <td className="text-end">{total_vat.toFixed(2)}</td>
        </tr>
        <tr>
          <th colSpan={4}>Total Room Price</th>
          <td className="text-end">
            {(sub_total_rate + total_vat + total_service_charge).toFixed(2)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
