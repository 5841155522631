import React from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import Input from "../../../components/form-elements/Input";
import InputButton from "../../../components/form-elements/Button";
import { Toast } from "../../../utils";
import { IFetchError } from "../../../types";
import { useMutation } from "react-query";
import Fetch from "../../../utils/fetch";
import { useNavigate } from "react-router-dom";

interface ChangePasswordFormValues {
  password: string;
  confirm_password: string;
}
export default function ChangePassword(props: any) {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<ChangePasswordFormValues>();

  const { mutate, isLoading }: any = useMutation(
    async (params) =>
      await Fetch({
        method: "PATCH",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/account/password/reset`,
        body: params,
        navigate,
      }),
    {
      onSuccess: (data) => {
        Toast.fire({
          icon: "success",
          title: data.message,
          timer: 5000,
          timerProgressBar: true,
          showCloseButton: true,
        });

        navigate("/");
      },
      onError: (error: IFetchError) => {
        Toast.fire({
          icon: "error",
          title: error?.message,
          text: error.reason,
          position: "bottom-left",
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        });
      },
    }
  );

  const handleChangePassword: SubmitHandler<ChangePasswordFormValues> = async (
    data
  ) => {
    if (data.confirm_password === data.password) {
      mutate({
        password: data.password,
      });
    } else {
      Toast.fire({
        icon: "error",
        title: "Failed!",
        text: "Passwords aren't matched!",
        position: "bottom-left",
        showCloseButton: true,
        timer: 10000,
        timerProgressBar: true,
      });
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mt-3">
        <Col sm={10} md={8} lg={7} xl={5}>
          <Card className="p-4 rounded-2">
            <div className="position-relative">
              <h3 className="fw-bolder">Change Password</h3>
              <Form
                className="form-horizontal"
                onSubmit={handleSubmit(handleChangePassword)}
              >
                <Row className="p-3 pt-4 border-top">
                  <Input
                    lg={12}
                    label="New Password"
                    type="password"
                    placeholder="Enter new password"
                    aria-autocomplete="none"
                    name="password"
                    control={control}
                    rules={{
                      required: "Please provide your password",
                    }}
                    group_class="mb-3"
                  />
                  <Input
                    lg={12}
                    label="Confirm New Password"
                    type="password"
                    placeholder="Enter comfirm password"
                    aria-autocomplete="none"
                    name="confirm_password"
                    control={control}
                    rules={{
                      required: "Please provide your password",
                    }}
                    group_class="mb-3"
                  />
                  <InputButton
                    lg={12}
                    label={isLoading ? "Submitting..." : "Submit"}
                    variant="primary"
                    type="submit"
                    className="w-100 mb-3"
                  />
                </Row>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
