import { useEffect, SetStateAction, Dispatch } from "react";
import { Modal, Form, Row, Col, Image } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import InputButton from "../../../components/form-elements/Button";
import FileInput from "../../../components/form-elements/FileInput";
import Input from "../../../components/form-elements/Input";
import Select from "../../../components/form-elements/Select";
import { IFetchError } from "../../../types";
import { Toast } from "../../../utils";
import Fetch from "../../../utils/fetch";

interface IFormValues {
  name: string;
  mobile_no: string;
  email: string;
  status: string;
  user_photo: string;
}

interface IProps {
  user: any;
  show: boolean;
  onHide: Dispatch<SetStateAction<boolean>>;
}

export default function B2bUserUpdate(props: IProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset, setValue } = useForm<IFormValues>();

  useEffect(() => {
    setValue("name", props.user.name);
    setValue("mobile_no", props.user.mobile_no);
    setValue("email", props.user.email);
    setValue("status", props.user.status);
  }, [props]);

  const { mutate, isLoading }: any = useMutation(
    async (params) =>
      await Fetch({
        method: "PATCH",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/user/update`,
        body: params,
        multipart: true,
        navigate,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["b2bUsers"]);
        Toast.fire({
          icon: "success",
          title: data.message,
          timer: 5000,
          timerProgressBar: true,
          showCloseButton: true,
        });

        reset();
        props.onHide(false);
      },
      onError: (error: IFetchError) => {
        Toast.fire({
          icon: "error",
          title: error?.message,
          text: error.reason,
          position: "bottom-left",
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        });
      },
    }
  );

  const updateHandler: SubmitHandler<IFormValues> = async (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("user_id", props.user.id);
    formData.delete("user_photo");
    if (data.user_photo) formData.append("user_photo", data.user_photo[0]);
    if (props.user.photo) formData.append("photo_key", props.user.photo);

    mutate(formData);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={props.show}
      onHide={() => props.onHide(!props.show)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update user
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="form-horizontal"
          onSubmit={handleSubmit(updateHandler)}
        >
          <Row className="mt-0 align-items-baseline">
            {props.user.photo && (
              <Col lg={12}>
                <div className="d-flex flex-column position-relative align align-items-center">
                  <Image
                    className="my-2 bg-light"
                    roundedCircle
                    src={
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: props.user.photo,
                          edits: { resize: { width: 480, height: 480 } },
                        })
                      )
                    }
                    width="120px"
                    height="120px"
                  />
                </div>
              </Col>
            )}
            <Input
              lg={6}
              label="Name"
              name="name"
              type="text"
              group_class="mb-3"
              control={control}
              placeholder="Enter name"
              rules={{
                required: "Please provide a name",
              }}
            />
            <Input
              lg={6}
              label="Mobile number"
              name="mobile_no"
              type="tel"
              group_class="mb-3"
              control={control}
              placeholder="Enter phone number"
              rules={{
                required: "Please provide a phone number",
              }}
            />
            <Input
              lg={6}
              label="Email"
              name="email"
              type="email"
              group_class="mb-3"
              control={control}
              placeholder="Enter email"
              rules={{
                required: "required email address",
              }}
            />

            <Select
              lg={6}
              label="Status"
              name="status"
              group_class="mb-3"
              control={control}
              selectOption="Select status"
              options={[
                { id: true, name: "Active" },
                { id: false, name: "Disable" },
              ]}
            />
            <FileInput
              name="user_photo"
              control={control}
              label="Update User Photo (Optional)"
              group_class="mb-3"
              accept=".png, .jpg, .jpeg, .svg"
            />
            <InputButton
              lg={12}
              label={isLoading ? "Updating..." : "Update"}
              type="submit"
              variant="primary"
              className="w-100 mb-3"
            />
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
