import React from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import InputButton from "../../../../components/form-elements/Button";
import Input from "../../../../components/form-elements/Input";
import { IFetchError } from "../../../../types";
import { Toast } from "../../../../utils";
import Fetch from "../../../../utils/fetch";

interface ResetFormValues {
  password: string;
  confirm_password: string;
}

function ResetPassword() {
  const navigate = useNavigate();
  const [token] = useSearchParams();
  const { control, handleSubmit } = useForm<ResetFormValues>();

  const { mutate, isLoading }: any = useMutation(
    async (params) =>
      await Fetch({
        method: "PATCH",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/user/password/reset`,
        body: params,
        navigate,
      }),
    {
      onSuccess: (data) => {
        Toast.fire({
          icon: "success",
          title: data.message,
          timer: 5000,
          timerProgressBar: true,
          showCloseButton: true,
        });

        navigate("/");
      },
      onError: (error: IFetchError) => {
        Toast.fire({
          icon: "error",
          title: error?.message,
          text: error.reason,
          position: "bottom-left",
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        });
      },
    }
  );

  const onSubmit: SubmitHandler<ResetFormValues> = (data) => {
    if (data.password === data.confirm_password) {
      const params = {
        token: token.get("code"),
        password: data.password,
      };
      mutate(params);
    } else {
      Toast.fire({
        icon: "error",
        title: "Failed!",
        text: "Passwords aren't matched!",
        position: "bottom-left",
        showCloseButton: true,
        timer: 10000,
        timerProgressBar: true,
      });
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center vh-100 ">
        <Col sm={10} md={8} lg={7} xl={5}>
          <Card className="p-4">
            <div className="position-relative">
              {/* <div className="text-center pt-3 px-1">
                <Image src={logo} width="320 " alt="My TrigGuru" />
              </div> */}

              <h4 className="fw-bolder text-center">Reset password</h4>
              <Form
                className="form-horizontal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row className="mt-4">
                  <Input
                    lg={12}
                    label="New password"
                    type="password"
                    placeholder="password"
                    aria-autocomplete="none"
                    name="password"
                    control={control}
                    rules={{
                      required: "Please provide your password",
                    }}
                  />
                </Row>
                <Row className="mt-4">
                  <Input
                    lg={12}
                    label="Confirm new password"
                    type="password"
                    placeholder="password"
                    aria-autocomplete="none"
                    name="confirm_password"
                    control={control}
                    rules={{
                      required: "Please provide your password",
                    }}
                  />
                </Row>
                <Row className="mt-4">
                  <InputButton
                    lg={12}
                    label={isLoading ? "Saving" : "Save"}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  />
                </Row>
                <Row>
                  <div className="text-center mt-3">
                    <Link className="w-100 font_14 text-decoration-none" to="/">
                      Back to Sign In
                    </Link>
                  </div>
                </Row>
              </Form>
              <div className="text-center mt-3">
                <p className="font_14 m-0">
                  Copyright © {new Date().getFullYear()} RNR Rooms. All rights
                  reserved.
                </p>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
