import React, { useState } from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/free-solid-svg-icons";
import { SubmitHandler, useForm } from "react-hook-form";
import Fetch from "../../../utils/fetch";
import { useNavigate } from "react-router-dom";
import InputButton from "../../../components/form-elements/Button";
import Input from "../../../components/form-elements/Input";
import { Toast } from "../../../utils";
import { useMutation } from "react-query";
import { IFetchError } from "../../../types";

interface Props {
  user: { id: number; email: string };
}

interface ResetFormValues {
  password: string;
  confirm_password: string;
}

export default function ResetPassword(props: Props) {
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm<ResetFormValues>();
  const [resetModalShow, setResetModalShow] = useState(false);

  const { mutate, isLoading }: any = useMutation(
    async (params) =>
      await Fetch({
        method: "PATCH",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/user/reset_password`,
        body: params,
        navigate,
      }),
    {
      onSuccess: (data) => {
        Toast.fire({
          icon: "success",
          title: data.message,
          timer: 5000,
          timerProgressBar: true,
          showCloseButton: true,
        });

        reset();
        setResetModalShow(false);
      },
      onError: (error: IFetchError) => {
        Toast.fire({
          icon: "error",
          title: error?.message,
          text: error.reason,
          position: "bottom-left",
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        });
      },
    }
  );

  const handleResetPassword: SubmitHandler<ResetFormValues> = async (data) => {
    if (data.confirm_password === data.password) {
      const params = {
        user_id: props.user.id,
        email: props.user.email,
        password: data.password,
      };
      mutate(params);
    } else {
      Toast.fire({
        icon: "error",
        title: "Failed!",
        text: "Passwords aren't matched!",
        position: "bottom-left",
        showCloseButton: true,
        timer: 10000,
        timerProgressBar: true,
      });
    }
  };

  return (
    <>
      <Button
        className="ms-1"
        variant="warning shadow"
        onClick={() => setResetModalShow(!resetModalShow)}
      >
        <FontAwesomeIcon icon={faFingerprint} />
      </Button>

      <Modal show={resetModalShow} onHide={() => setResetModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="form-horizontal"
            onSubmit={handleSubmit(handleResetPassword)}
          >
            <Row className="mt-0 align-items-baseline">
              <Input
                lg={6}
                label="Password"
                name="password"
                type="password"
                group_class="mb-3"
                control={control}
                placeholder="Password"
                rules={{
                  required: "required password",
                }}
              />
              <Input
                lg={6}
                label="Confirm Password"
                name="confirm_password"
                type="password"
                group_class="mb-3"
                control={control}
                placeholder="Confirm Password"
                rules={{
                  required: "required confirm password",
                }}
              />
              <InputButton
                lg={12}
                label={isLoading ? "Submitting..." : "Submit"}
                type="submit"
                variant="primary"
                className="w-100 mb-3"
              />
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
