import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../utils/fetch";
import getSessionCookie from "../utils/getSessionCookie";

export default function useUserPermissions() {
  const navigate = useNavigate();
  const user_id = getSessionCookie("u_id");

  return useQuery(
    ["permissions", user_id],
    async () =>
      await Fetch<string[]>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/user/permissions`,
        navigate,
      }),
    {
      staleTime: 5000,
    }
  );
}
