import React from "react";
import { Pagination, Row, Col } from "react-bootstrap";

interface Props {
  count: number;
  handlePageClick: (pageNumber: number) => void;
  name?: string;
  activePage: number;
  pageSize: number;
}

function CustomPagination(props: Props) {
  const { activePage, pageSize } = props;

  let items = [];
  let breakView;
  const marginPagesDisplayed = 2;
  const pageRangeDisplayed = 6;
  let count = props.count;
  if (count < 10) {
    count = 10;
  }
  const totalPage = Math.ceil(count / pageSize);

  const handleBreakClick = (pageNumber: number) => {
    if (activePage < pageNumber) {
      const forwardJump = activePage + pageRangeDisplayed;
      if (forwardJump >= totalPage) {
        props.handlePageClick(totalPage - 1);
      } else {
        props.handlePageClick(forwardJump);
      }
    } else {
      const backwardJump = activePage - pageRangeDisplayed;
      if (backwardJump < 1) {
        props.handlePageClick(1);
      } else {
        props.handlePageClick(backwardJump);
      }
    }
  };

  if (totalPage <= pageRangeDisplayed) {
    for (let pageNumber = 1; pageNumber <= totalPage; pageNumber++) {
      items.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === activePage}
          onClick={() => props.handlePageClick(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }
  } else {
    let leftSide = pageRangeDisplayed / 2;
    let rightSide = pageRangeDisplayed - leftSide;

    if (activePage > totalPage - pageRangeDisplayed / 2) {
      rightSide = totalPage - activePage;
      leftSide = pageRangeDisplayed - rightSide;
    } else if (activePage < pageRangeDisplayed / 2) {
      leftSide = activePage;
      rightSide = pageRangeDisplayed - leftSide;
    }

    for (let pageNumber = 1; pageNumber <= totalPage; pageNumber++) {
      if (pageNumber <= marginPagesDisplayed) {
        items.push(
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === activePage}
            onClick={() => props.handlePageClick(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
        continue;
      }

      if (pageNumber > totalPage - marginPagesDisplayed) {
        items.push(
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === activePage}
            onClick={() => props.handlePageClick(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
        continue;
      }

      if (
        pageNumber >= activePage - leftSide &&
        pageNumber <= activePage + rightSide
      ) {
        items.push(
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === activePage}
            onClick={() => props.handlePageClick(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
        continue;
      }
      if (items[items.length - 1] !== breakView) {
        breakView = (
          <Pagination.Ellipsis onClick={() => handleBreakClick(pageNumber)} />
        );
        items.push(breakView);
      }
    }
  }

  return (
    <Row className="my-2">
      <Col>
        <Pagination className="p-1">
          <Pagination.Prev
            onClick={() => props.handlePageClick(activePage - 1)}
            disabled={activePage === 1}
          />
          {items}
          <Pagination.Next
            onClick={() => props.handlePageClick(activePage + 1)}
            disabled={activePage === totalPage}
          />
        </Pagination>
      </Col>
      {props.count ? (
        <Col className="text-end">
          <h6 className="custom_h6">
            Total {props.count} {props.name}(s) found.
          </h6>
        </Col>
      ) : null}
    </Row>
  );
}
export default CustomPagination;
