import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../../../utils/fetch";

export interface IPayment {
  id: 20;
  gateway_type: string;
  order_id: string;
  amount: string;
  currency: string;
  status: string;
  transaction_id: string | null;
  processing_fee: string | null;
  created_at: Date | null;
  deposited_to_account: string;
}

interface IFetchData {
  count: {
    count: string;
  }[];
  payments: IPayment[];
}

interface IParams {
  from_date: null | Date;
  to_date: null | Date;
  payment_status: string;
  transaction_id: string;
  merchant_order_id: string;
  client_mobile_no: string;
  bank_pan: string;
  transaction_type: string;
  offset: number;
  pageSize: number;
  activePage: number;
}

export default function usePayments(params: IParams) {
  const navigate = useNavigate();

  return useQuery(
    ["payments", params],
    async () =>
      await Fetch<IFetchData>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/payments`,
        body: {
          from_date: params.from_date,
          to_date: params.to_date,
          payment_status: params.payment_status,
          transaction_id: params.transaction_id.trim(),
          merchant_order_id: params.merchant_order_id.trim(),
          client_mobile_no: params.client_mobile_no.trim(),
          bank_pan: params.bank_pan.trim(),
          transaction_type: params.transaction_type,
          offset: params.offset,
          pageSize: params.pageSize,
        },
        navigate,
      })
  );
}
