import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Row, Col } from "react-bootstrap";
import { TypeDocuments } from ".";

interface Iprops {
  documents: TypeDocuments;
}

export default function CompanyDocuments(props: Iprops) {
  const data: any = {
    "Trade License": props.documents.trade_license,
    "TIN Certificate": props.documents.tin_certificate,
    "VAT Certificate": props.documents.vat_certificate,
    "NID of Proprietor/Managing Partner/Managing Director":
      props.documents.proprietor_nid,
    "NID of Key Contact Person": props.documents.contact_person_nid,
    "Civil Aviation Travel Agency Registration":
      props.documents.civil_aviation_travel_agency_registration,
    "TOAB Certificate of Membership":
      props.documents.toab_certificate_of_membership,
    "ATAB Certificate of Membership":
      props.documents.atab_certificate_of_membership,
    "Incorporation Certificate": props.documents.incorporation_certificate,
    "RNR Rooms Agreement": props.documents.agreement,
    "Any other documentation": props.documents.any_other_documentation,
  };

  return (
    <Card className="mt-lg-4 mt-3 p-3 p-md-4" id="documents">
      <h5 className="fw-bold">Documents</h5>
      <Row>
        {Object.keys(data).map((key: string) => {
          return (
            data[key] && (
              <Col className="col-12 col-sm-6 col-lg-4 mt-3 mt-sm-3" key={key}>
                <a
                  className="text-decoration-none align-items-center d-flex p-3 bg-opacity-25 bg-info rounded"
                  href={data[key]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{key}</span>
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    className="fs-3 mx-2"
                    color="red"
                  />
                </a>
              </Col>
            )
          );
        })}
      </Row>
    </Card>
  );
}
