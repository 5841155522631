import PublicLayout from "../components/layout/public";
import ProtectedLayout from "../components/layout/protected";

import Home from "../pages/protected/home";
import Company from "../pages/protected/company";

import Login from "../pages/public/authentication/login";
import ForgotPassword from "../pages/public/authentication/forgot_password";
import ResetPassword from "../pages/public/authentication/forgot_password/ResetPassword";
import BadRequest from "../pages/public/error/error_400";
import AccessDenied from "../pages/public/error/error_403";
import PageNotFound from "../pages/public/error/error_404";
import ServerError from "../pages/public/error/error_500";
import PaymentFailed from "../pages/protected/wallet/add_money/PaymentFailed";
// import GuestList from "../pages/protected/guestList";
import Users from "../pages/protected/users";
import BankList from "../pages/protected/bankList";
// import Tickets from "../pages/protected/support/tickets";
import HelpCenter from "../pages/protected/support/help_center";
// import Notification from "../pages/protected/notification";
// import SingleTicket from "../pages/protected/support/tickets/singleTicket";
import ManageBooking from "../pages/protected/manageBooking";
import BookingDetails from "../pages/protected/manageBooking/details";
import Profile from "../pages/protected/profile";
import ChangePassword from "../pages/protected/profile/changePassword";
import PropertySearch from "../pages/protected/booking_engine/search/PropertySearch";
import AddMoney from "../pages/protected/wallet/add_money";
import PropertyProfile from "../pages/protected/booking_engine/property_profile";
import PropertyCheckout from "../pages/protected/booking_engine/checkout";
import Transactions from "../pages/protected/wallet/transactions";
import Payments from "../pages/protected/wallet/payments";
import ProcessingCityBankPayment from "../pages/protected/wallet/add_money/online/citybank/ProcessingCityBankPayment";
import ProcessingNagadPayment from "../pages/protected/wallet/add_money/online/nagad/ProcessingNagadPayment";
import PaymentDetails from "../pages/protected/wallet/payments/paymentDetails";
import TransactionDetails from "../pages/protected/wallet/transactions/transactionDetails";

const AppRoutes = [
  {
    title: "Dashboard",
    path: "/dashboard",
    Layout: ProtectedLayout,
    Component: Home,
    isAuthProtected: true,
    module: "home_default",
  },
  {
    title: "Search Property",
    path: "/search/properties",
    Layout: ProtectedLayout,
    Component: PropertySearch,
    isAuthProtected: true,
    module: "b2b_create_property_booking",
  },
  {
    title: "Property Profile",
    path: "/search/property/:property_id",
    Layout: ProtectedLayout,
    Component: PropertyProfile,
    isAuthProtected: true,
    module: "b2b_create_property_booking",
  },
  {
    title: "Property CheckOut",
    path: "/booking/property/checkout",
    Layout: ProtectedLayout,
    Component: PropertyCheckout,
    isAuthProtected: true,
    module: "b2b_create_property_booking",
  },
  {
    title: "Manage Booking",
    path: "/manage/booking",
    Layout: ProtectedLayout,
    Component: ManageBooking,
    isAuthProtected: true,
    module: "b2b_view_property_booking",
  },
  {
    title: "Booking Details",
    path: "/manage/booking/details/:reservation_id",
    Layout: ProtectedLayout,
    Component: BookingDetails,
    isAuthProtected: true,
    module: "b2b_view_property_booking",
  },
  {
    title: "Bank List",
    path: "/banklist",
    Layout: ProtectedLayout,
    Component: BankList,
    isAuthProtected: true,
    module: "home_default",
  },
  {
    title: "Payments",
    path: "/payments",
    Layout: ProtectedLayout,
    Component: Payments,
    isAuthProtected: true,
    module: "b2b_view_payments",
  },
  {
    title: "Payment Details",
    path: "/payment/details/:payment_id",
    Layout: ProtectedLayout,
    Component: PaymentDetails,
    isAuthProtected: true,
    module: "b2b_view_payments",
  },
  {
    title: "Transactions",
    path: "/transactions",
    Layout: ProtectedLayout,
    Component: Transactions,
    isAuthProtected: true,
    module: "b2b_view_transactions",
  },
  {
    title: "Transaction Details",
    path: "/transaction/details/:transaction_id",
    Layout: ProtectedLayout,
    Component: TransactionDetails,
    isAuthProtected: true,
    module: "b2b_view_transactions",
  },
  {
    title: "Add Money",
    path: "/wallet/money/deposit",
    Layout: ProtectedLayout,
    Component: AddMoney,
    isAuthProtected: true,
    module: "b2b_make_new_payment",
  },
  {
    title: "Processing Payment",
    path: "/wallet/payment/citybank/processing",
    Layout: ProtectedLayout,
    Component: ProcessingCityBankPayment,
    isAuthProtected: true,
    module: "b2b_make_new_payment",
  },
  {
    title: "Processing Payment",
    path: "/wallet/payment/nagad/processing",
    Layout: ProtectedLayout,
    Component: ProcessingNagadPayment,
    isAuthProtected: true,
    module: "b2b_make_new_payment",
  },
  {
    title: "Payment Failed",
    path: "/wallet/payment/failed/:response",
    Component: PaymentFailed,
    Layout: ProtectedLayout,
    isAuthProtected: true,
    module: "b2b_make_new_payment",
  },
  {
    title: "Company",
    path: "/company",
    Layout: ProtectedLayout,
    Component: Company,
    isAuthProtected: true,
    module: "view_b2b_profile",
  },

  // {
  //   title: "Guest List",
  //   path: "/guestlist",
  //   Layout: ProtectedLayout,
  //   Component: GuestList,
  //   isAuthProtected: true,
  //   module: "home_default",
  // },
  {
    title: "Users",
    path: "/users",
    Layout: ProtectedLayout,
    Component: Users,
    isAuthProtected: true,
    module: "b2b_list_user",
  },
  // {
  //   title: "Support Tickets",
  //   path: "/tickets",
  //   Layout: ProtectedLayout,
  //   Component: Tickets,
  //   isAuthProtected: true,
  //   module: "home_default",
  // },
  {
    title: "Help center",
    path: "/help_center",
    Layout: ProtectedLayout,
    Component: HelpCenter,
    isAuthProtected: true,
    module: "home_default",
  },
  // {
  //   title: "Support Single Ticket",
  //   path: "/tickets/single_ticket",
  //   Layout: ProtectedLayout,
  //   Component: SingleTicket,
  //   isAuthProtected: true,
  //   module: "home_default",
  // },
  // {
  //   title: "Notification",
  //   path: "/notification",
  //   Layout: ProtectedLayout,
  //   Component: Notification,
  //   isAuthProtected: true,
  // },

  {
    title: "Profile",
    path: "/profile",
    Layout: ProtectedLayout,
    Component: Profile,
    isAuthProtected: true,
    module: "home_default",
  },
  {
    title: "Change Password",
    path: "/change-password",
    Layout: ProtectedLayout,
    Component: ChangePassword,
    isAuthProtected: true,
    module: "home_default",
  },
  {
    title: "Login",
    path: "/",
    Component: Login,
    Layout: PublicLayout,
    isAuthProtected: false,
  },
  {
    title: "Forgot Password",
    path: "/forgot-password",
    Component: ForgotPassword,
    Layout: PublicLayout,
    isAuthProtected: false,
  },
  {
    title: "Reset Password",
    path: "/reset-password",
    Component: ResetPassword,
    Layout: PublicLayout,
    isAuthProtected: false,
  },
  {
    title: "Access Denied",
    path: "/access-denied",
    Component: AccessDenied,
    Layout: PublicLayout,
    isAuthProtected: false,
  },
  {
    title: "Server Error",
    path: "/500",
    Component: ServerError,
    Layout: PublicLayout,
    isAuthProtected: false,
  },
  {
    title: "Bad Request",
    path: "/400",
    Component: BadRequest,
    Layout: PublicLayout,
    isAuthProtected: false,
  },

  {
    title: "Page Not Found",
    path: "*",
    Component: PageNotFound,
    Layout: PublicLayout,
    isAuthProtected: false,
  },
];

export { AppRoutes };
