import React from "react";
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
  UnpackNestedValue,
  useController,
} from "react-hook-form";
import { Col, Form, FormControlProps } from "react-bootstrap";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends FormControlProps {
  xs?: string | number;
  lg?: string | number;
  md?: string | number;
  xl?: string | number;
  sm?: string | number;
  label: string;
  accept?: string;
  label_class?: string;
  group_class?: string;
  children?: React.ReactElement;
  name: TName;
  multiple?: boolean;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  defaultValue?: UnpackNestedValue<FieldPathValue<TFieldValues, TName>>;
  control: Control<TFieldValues>;
}

export default function InputFile<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  lg,
  xs,
  sm,
  md,
  xl,
  label,
  label_class,
  group_class,
  name,
  control,
  children,
  rules,
  defaultValue,
  multiple,
  ...rest
}: Props<TFieldValues, TName>) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue,
  });

  return (
    <Form.Group
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      as={Col}
      className={group_class}
    >
      <Form.Label className={label_class}>{label}</Form.Label>
      <Form.Control
        {...rest}
        {...field}
        type="file"
        name={field.name}
        value={field.value ? field.value.filename : ""}
        onChange={(e) => {
          field.onChange((e.target as HTMLInputElement).files);
        }}
        ref={field.ref}
        onBlur={field.onBlur}
        multiple={multiple}
        isInvalid={invalid}
      />

      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>

      {children ? children : null}
    </Form.Group>
  );
}
