import { useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import useCancellationDetails from "./useCancellationDetails";
import ContentLoader from "react-content-loader";
import { format } from "date-fns";
import { IReservation } from "../useReservations";
import useCancelBookingMutation from "./useCancelBookingMutation";
import Swal from "sweetalert2";
import { IFetchError } from "../../../../types";
import { Toast } from "../../../../utils";
import { useQueryClient } from "react-query";

interface IProps {
  reservation: IReservation;
}

export default function CancelBooking(props: IProps) {
  const queryClient = useQueryClient();
  const [modalShow, setModalShow] = useState(false);

  const cancel_details = useCancellationDetails({
    reservation_id: props.reservation.id,
  });

  const cancel_booking = useCancelBookingMutation();

  const handleCancelBooking = async () =>
    cancel_booking.mutate(
      {
        reservation_id: props.reservation.id,
      },
      {
        onSuccess: async (data) => {
          setModalShow(!modalShow);
          queryClient.invalidateQueries([{ scope: "reservations" }]);

          if (data.data.refund.total_amount !== 0) {
            await Toast.fire({
              position: "top-end",
              icon: "success",
              title: "Payment refund to your b2b wallet",
              showCloseButton: true,
              timer: 2000,
            });
          }

          Toast.fire({
            position: "top-end",
            icon: "success",
            title: data.message,
            showCloseButton: true,
            timer: 3000,
          });
        },
        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: (error as IFetchError).message,
            text: (error as IFetchError).reason,
          });
        },
      }
    );

  return (
    <>
      <Button
        variant="danger"
        className="mx-1 mx-sm-2 mx-md-3 border-0"
        onClick={() => {
          setModalShow(true);
          cancel_details.refetch();
        }}
      >
        Cancel
      </Button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Booking Cancellation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {!cancel_details.isLoading ? (
              cancel_details.isSuccess ? (
                <>
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    responsive
                    id="reservations"
                  >
                    <thead>
                      <tr>
                        <th className="text-center" colSpan={4}>
                          Reservation Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Reservation Id</th>
                        <td>{props.reservation.id}</td>

                        <th>Property Name</th>
                        <td>{props.reservation.property_name}</td>
                      </tr>

                      <tr>
                        <th>Status</th>
                        <td>{props.reservation.reservation_status}</td>
                        <th>Guest</th>
                        <td>{props.reservation.guest_name}</td>
                      </tr>

                      <tr>
                        <th>Check In</th>
                        <td>
                          {format(
                            new Date(props.reservation.check_in),
                            "dd MMM yyyy"
                          )}
                        </td>
                        <th>Check Out</th>
                        <td>
                          {format(
                            new Date(props.reservation.check_out),
                            "dd MMM yyyy"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {cancel_details.data.data.refund_room_details.length !== 0 &&
                  props.reservation.payment_status === "PAID" ? (
                    <>
                      <h4 className="my-3">Refund Details</h4>
                      {cancel_details.data.data.refund_room_details.map(
                        (room) => (
                          <Row className="py-2">
                            <Col lg={9}>
                              <h6 className="fw-bold">{room.room_name}</h6>
                              <p className="text-muted">
                                {room.cancellation_period !== "Non-refundable"
                                  ? `You may cancel free of charge until ${room.cancellation_period} before arrival.
You will be charged the cost of the ${room.cancellation_penalty} if you cancel in the ${room.cancellation_period} before arrival.`
                                  : `If you change or cancel your booking you will not get a refund or credit to use for a future stay.`}
                              </p>
                            </Col>
                            <Col
                              lg={3}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <h5>
                                {(
                                  room.agent_gross_refund * room.quantity
                                ).toFixed(2)}{" "}
                                ৳
                              </h5>
                            </Col>
                          </Row>
                        )
                      )}
                      <Row className="fw-bolder border-top py-2 text-center">
                        <Col lg={9}>
                          <h5>Total</h5>
                        </Col>
                        <Col lg={3}>
                          <h5>
                            {cancel_details.data?.data.agent_total_gross_refund.toFixed(
                              2
                            )}{" "}
                            ৳
                          </h5>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row className="py-4">
                    <Col lg={4}>
                      <Button variant="danger" onClick={handleCancelBooking}>
                        Cancel Booking
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <h5>Data not found!</h5>
              )
            ) : (
              <Row>
                <Col>
                  <ContentLoader
                    width="100%"
                    backgroundColor="#E1E9EE"
                    foregroundColor="#F2F8FC"
                  >
                    <rect x="0" y="30" rx="6" ry="6" width="50%" height="16" />
                    <rect x="0" y="58" rx="6" ry="6" width="40%" height="14" />
                    <rect
                      x="80%"
                      y="46"
                      rx="6"
                      ry="6"
                      width="10%"
                      height="18"
                    />
                  </ContentLoader>
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
