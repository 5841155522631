import { Navigate, useLocation } from "react-router-dom";
import useUserPermissions from "../hooks/useUserPermissions";

interface Props {
  children: JSX.Element;
  module: string;
}

export default function RequirePermission({ module, children }: Props) {
  const location = useLocation();
  const permissions = useUserPermissions();

  if (module !== "home_default") {
    if (!permissions.isLoading) {
      if (!permissions.data?.data.includes(module)) {
        // User doesn't have permission to access this resource
        return (
          <Navigate to="/access-denied" state={{ from: location }} replace />
        );
      }
    }
  }

  return children;
}
