import React, { useState, lazy, Suspense, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Image,
  Modal,
  Carousel,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HotelLocation from "./Location";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PropertyProfileData } from "./usePropertyProfile";

const FsLightbox = lazy(() => import("fslightbox-react"));

interface Props {
  profile: PropertyProfileData;
}

export default function HotelProfileDetails(props: Props) {
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const openLightboxOnSlide = (number: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const isMobile = windowWidth <= 990;

  const { property, property_facilities, property_photos } = props.profile;

  return (
    <>
      {isMobile ? (
        <Carousel>
          {property_photos.map((photo, key) => (
            <Carousel.Item>
              <Image
                className="w-100 cursor-pointer rc_image_cover"
                src={
                  process.env.REACT_APP_CDN_URL +
                  btoa(
                    JSON.stringify({
                      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                      key: photo.name,
                    })
                  )
                }
                rounded
                height={240}
                onClick={() => openLightboxOnSlide(++key)}
                alt={`${property.name} Photo`}
                loading="lazy"
                srcSet={`${
                  process.env.REACT_APP_CDN_URL +
                  btoa(
                    JSON.stringify({
                      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                      key: photo.name,
                      edits: { resize: { width: 480 } },
                    })
                  )
                } 480w,
                ${
                  process.env.REACT_APP_CDN_URL +
                  btoa(
                    JSON.stringify({
                      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                      key: photo.name,
                      edits: { resize: { width: 720 } },
                    })
                  )
                } 720w,
                ${
                  process.env.REACT_APP_CDN_URL +
                  btoa(
                    JSON.stringify({
                      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                      key: photo.name,
                      edits: { resize: { width: 960 } },
                    })
                  )
                } 960w,
                ${
                  process.env.REACT_APP_CDN_URL +
                  btoa(
                    JSON.stringify({
                      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                      key: photo.name,
                      edits: { resize: { width: 1200 } },
                    })
                  )
                } 1200w`}
                sizes="(max-width: 575px) 90vw, (max-width: 990px) 70vw"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : property_photos[0] ? (
        <Row className="px-2">
          <Col className="px-1 pt-1 pb-0" lg={8} xs={6}>
            <Image
              className="cursor-pointer rc_image_cover"
              src={
                process.env.REACT_APP_CDN_URL +
                btoa(
                  JSON.stringify({
                    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                    key: property_photos[0].name,
                  })
                )
              }
              rounded
              width="100%"
              height={408}
              onClick={() => openLightboxOnSlide(1)}
              alt={`${property.name} Photo`}
              srcSet={`${
                process.env.REACT_APP_CDN_URL +
                btoa(
                  JSON.stringify({
                    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                    key: property_photos[0].name,
                    edits: { resize: { width: 1440 } },
                  })
                )
              } 1440w,
              ${
                process.env.REACT_APP_CDN_URL +
                btoa(
                  JSON.stringify({
                    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                    key: property_photos[0].name,
                    edits: { resize: { width: 2160 } },
                  })
                )
              } 2160w,
              ${
                process.env.REACT_APP_CDN_URL +
                btoa(
                  JSON.stringify({
                    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                    key: property_photos[0].name,
                    edits: { resize: { width: 2880 } },
                  })
                )
              } 2880w`}
              sizes="45vw"
            />
          </Col>
          {property_photos[1] ? (
            <Col lg={4} xs={6}>
              <Row>
                <Col className="p-1" lg={12} xs={12}>
                  <Image
                    className="cursor-pointer rc_image_cover"
                    src={
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: property_photos[1].name,
                        })
                      )
                    }
                    rounded
                    width="100%"
                    height={200}
                    onClick={() => openLightboxOnSlide(2)}
                    alt={`${property.name} Photo`}
                    srcSet={`${
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: property_photos[1].name,
                          edits: { resize: { width: 720 } },
                        })
                      )
                    } 720w,
                    ${
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: property_photos[1].name,
                          edits: { resize: { width: 1440 } },
                        })
                      )
                    } 1440w,
                    ${
                      process.env.REACT_APP_CDN_URL +
                      btoa(
                        JSON.stringify({
                          bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                          key: property_photos[1].name,
                          edits: { resize: { width: 2160 } },
                        })
                      )
                    } 2160w`}
                    sizes="30vw"
                  />
                </Col>
                {property_photos[2] ? (
                  <Col className="px-1 pt-1 pb-0" lg={12} xs={12}>
                    <Image
                      className="cursor-pointer rc_image_cover"
                      src={
                        process.env.REACT_APP_CDN_URL +
                        btoa(
                          JSON.stringify({
                            bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                            key: property_photos[2].name,
                          })
                        )
                      }
                      rounded
                      width="100%"
                      height={200}
                      onClick={() => openLightboxOnSlide(3)}
                      alt={`${property.name} Photo`}
                      srcSet={`${
                        process.env.REACT_APP_CDN_URL +
                        btoa(
                          JSON.stringify({
                            bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                            key: property_photos[2].name,
                            edits: { resize: { width: 720 } },
                          })
                        )
                      } 720w,
                      ${
                        process.env.REACT_APP_CDN_URL +
                        btoa(
                          JSON.stringify({
                            bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                            key: property_photos[2].name,
                            edits: { resize: { width: 1440 } },
                          })
                        )
                      } 1440w,
                      ${
                        process.env.REACT_APP_CDN_URL +
                        btoa(
                          JSON.stringify({
                            bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                            key: property_photos[2].name,
                            edits: { resize: { width: 2160 } },
                          })
                        )
                      } 2160w`}
                      sizes="30vw"
                    />
                  </Col>
                ) : null}
              </Row>
            </Col>
          ) : null}
        </Row>
      ) : null}

      <Suspense fallback={<div></div>}>
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={property_photos.map(
            (photo: { name: string | number }) =>
              process.env.REACT_APP_CDN_URL +
              btoa(
                JSON.stringify({
                  bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                  key: photo.name,
                })
              )
          )}
          slide={lightboxController.slide}
          type="image"
        />
      </Suspense>

      <Card className="p-4 mt-0">
        <Row>
          <Col lg={8}>
            <h2 className="fw-bold m-0">
              {property.name}{" "}
              <span className="ml-2">
                {[1, 2, 3, 4, 5].map((rate, key) => (
                  <FontAwesomeIcon
                    key={key}
                    className={`${
                      property.rating >= rate ? "star-checked" : null
                    } font_14`}
                    icon="star"
                  />
                ))}
              </span>
            </h2>
            <p className="pt-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mx-2" />
              {property.address}, {property.thana_name},{" "}
              {property.district_name}, {property.division_name}-
              {property.post_code}
            </p>

            <h5 className="fw-bold my-4">Popular Facilities</h5>
            <Row className="my-2">
              <Col lg={4} xs={6} className="my-2">
                <FontAwesomeIcon icon="coffee" /> Breakfast{" "}
                {property.property_breakfast === "No"
                  ? "Not Included"
                  : property.property_breakfast}
              </Col>

              {property.property_parking.includes("Yes") ? (
                <Col lg={4} xs={6} className="my-2">
                  <FontAwesomeIcon icon="parking" /> Parking
                </Col>
              ) : null}

              {property_facilities
                .sort(
                  ({ priority: previousID }, { priority: currentID }) =>
                    previousID - currentID
                )
                .map((facility: { icon: IconProp; name: string }, key) => {
                  return key < 4 ? (
                    <Col lg={4} xs={6} className="my-2" key={key}>
                      <FontAwesomeIcon icon={facility.icon} /> {facility.name}
                    </Col>
                  ) : null;
                })}
            </Row>
            <Row className="my-4 justify-content-end">
              <Col className="d-flex justify-content-end" lg={3}>
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={() => setShow(true)}
                >
                  See all <FontAwesomeIcon icon={faAngleRight} />
                </Button>
              </Col>
            </Row>
            <Modal show={show} onHide={() => setShow(!show)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Property Amenities</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {property.property_parking.includes("Yes") ? (
                  <>
                    <h6 className="fw-bold my-2">Parking and Transportation</h6>
                    <ul>
                      <li>
                        {property.property_parking}{" "}
                        {property.property_parking_space}{" "}
                        {property.property_parking_location}{" "}
                      </li>
                      {property.property_parking_reserve ||
                      property.property_parking_price ? (
                        <li>
                          {property.property_parking_reserve}
                          {property.property_parking_price ? (
                            <> Price ৳ {property.property_parking_price}</>
                          ) : null}
                        </li>
                      ) : null}
                    </ul>
                  </>
                ) : null}
                <h6 className="fw-bold my-2">Popular Facilities</h6>
                <Row className="my-2 ml-4">
                  {property_facilities
                    .sort(
                      ({ priority: previousID }, { priority: currentID }) =>
                        previousID - currentID
                    )
                    .map((facility: { icon: IconProp; name: string }, key) => (
                      <Col lg={6} xs={6} className="my-2" key={key}>
                        <FontAwesomeIcon icon={facility.icon} /> {facility.name}
                      </Col>
                    ))}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <HotelLocation
            latitude={property.latitude}
            longitude={property.longitude}
            address={property.address}
            post_code={property.post_code}
            division_name={property.division_name}
            district_name={property.district_name}
            thana_name={property.thana_name}
          />
        </Row>
      </Card>
    </>
  );
}
