import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Container, Form, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPrint, faEye } from "@fortawesome/free-solid-svg-icons";
import InputButton from "../../../../components/form-elements/Button";
import Input from "../../../../components/form-elements/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import Select from "../../../../components/form-elements/Select";
import DateRangePickerCustom from "../../../../components/form-elements/DateRangePicker";
import useTransactions, { ITransaction } from "./hooks/useTransactions";
import { Toast } from "../../../../utils";
import { IFetchError } from "../../../../types";
import { TableLoader } from "../../../../components/Loaders";
import Pagination from "../../../../components/pagination";
import { format } from "date-fns";

interface transactionFilterValues {
  payment_id: string;
  reservation_id: string;
  transaction_type: string;
  transaction_code: string;
}

export default function Transactions() {
  const { control, handleSubmit } = useForm<transactionFilterValues>();
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);

  const [offset, setOffset] = useState(0);
  const [pageSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [formData, setFormData] = useState({
    from_date: startDate,
    to_date: endDate,
    transaction_code: "",
    transaction_type: "",
    reservation_id: "",
    payment_id: "",
    offset,
    pageSize,
  });

  const transactions: any = useTransactions({
    ...formData,
    activePage,
  });

  const handlePageClick = (pageNumber: number) => {
    setActivePage(pageNumber);
    setOffset(pageSize * (pageNumber - 1));
    setFormData({ ...formData, offset: pageSize * (pageNumber - 1) });
    transactions.refetch();
  };

  const handleFilter: SubmitHandler<transactionFilterValues> = (data) => {
    setOffset(0);
    setFormData({
      ...formData,
      ...data,
      offset: 0,
      from_date: startDate,
      to_date: endDate,
    });
    transactions.refetch();
  };

  if (transactions.isError) {
    Toast.fire({
      icon: "error",
      title: (transactions.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  const generateStatusBadge = (status: string) => {
    let status_badge = "badge";
    status === "Deposit"
      ? (status_badge += " text-success")
      : (status_badge += " text-danger");

    return status_badge;
  };

  return (
    <Container>
      <Card className="p-3 mt-2 mt-md-3 mt-lg-4">
        <Form className="form-horizontal" onSubmit={handleSubmit(handleFilter)}>
          <Row className="mt-0 align-items-end">
            <DateRangePickerCustom
              lg={6}
              labels={["Date From", "Date To"]}
              startDate={startDate!}
              endDate={endDate!}
              onStartDateChange={(date) => setStartDate(date!)}
              onEndDateChange={(date) => setEndDate(date!)}
            />
            <Input
              lg={3}
              md={6}
              label="Payment ID"
              name="payment_id"
              type="text"
              className="mb-3"
              control={control}
              defaultValue=""
              placeholder="Enter payment id"
            />
            <Input
              lg={3}
              md={6}
              label="Reservation ID"
              name="reservation_id"
              type="text"
              className="mb-3"
              control={control}
              defaultValue=""
              placeholder="Enter reservation id"
            />

            <Select
              lg={3}
              md={6}
              label="Transaction Type"
              name="transaction_type"
              className="mb-3"
              control={control}
              selectOption="Select type"
              defaultValue=""
              options={[
                { id: "Deposit", name: "Deposit" },
                { id: "Withdraw", name: "Withdraw" },
              ]}
            />
            <Input
              lg={3}
              md={6}
              label="Code"
              name="transaction_code"
              type="text"
              className="mb-3"
              control={control}
              defaultValue=""
              placeholder="Enter transaction code"
            />
            <InputButton
              lg={3}
              md={6}
              label="Search"
              type="submit"
              variant="primary"
              className="w-100 mb-3"
            />
          </Row>
        </Form>
      </Card>

      <Card className="mt-4 mt-2 mt-md-3 mt-lg-4 p-2 font_14">
        <div className="d-flex justify-content-between px-2 py-3 align-items-center">
          <h5 className="fw-bold mb-0">Transactions</h5>
          <div className="d-flex align-items-center">
            <button className="border-0 bg-white btn_link_effect">
              <FontAwesomeIcon icon={faFileExcel} className="fs-4" />
            </button>
            <button className="mx-1 mx-sm-2 mx-md-3 border-0 bg-white btn_link_effect">
              <FontAwesomeIcon icon={faPrint} className="fs-5" />
            </button>
          </div>
        </div>
        <Table
          responsive="lg"
          className="caption-top"
          bordered
          striped={!transactions.isLoading}
          hover={!transactions.isLoading}
        >
          <thead className="border-bottom">
            <tr>
              <th>Pay On</th>
              <th>Source</th>
              <th>Code</th>
              <th>Reservation ID</th>
              <th>Payment ID</th>
              <th>Payment Type</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.isLoading ? (
              <TableLoader columnLength={8} />
            ) : transactions.isSuccess ? (
              <>
                {transactions.data.data.transactions.map(
                  (transaction: ITransaction) => {
                    return (
                      <tr className="my-1" key={transaction.id}>
                        <td className="fw-bold">
                          {format(
                            new Date(transaction.created_at),
                            "dd MMM yyyy hh:mm:ss aa"
                          )}
                        </td>
                        <td>{transaction.source}</td>
                        <td>{transaction.code}</td>
                        <td>
                          <Link
                            to={`/manage/booking/details/${transaction.property_reservation_id}`}
                            className="text-primary fw-bold"
                          >
                            {transaction.property_reservation_id}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/payment/details/${transaction.payment_id}`}
                            className="text-primary fw-bold"
                          >
                            {transaction.payment_id}
                          </Link>
                        </td>
                        <td>
                          <Badge bg={generateStatusBadge(transaction.type)}>
                            {transaction.type}
                          </Badge>
                        </td>
                        <td>{transaction.amount}</td>
                        <td>
                          <Link
                            to={`/transaction/details/${transaction.id}`}
                            className="btn btn-warning"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                        </td>
                      </tr>
                    );
                  }
                )}
              </>
            ) : (
              <h4>Data not found!</h4>
            )}
          </tbody>
        </Table>

        {transactions.isSuccess && (
          <Pagination
            count={Number(transactions.data.data.count[0].count)}
            handlePageClick={handlePageClick}
            pageSize={pageSize}
            activePage={activePage}
            name="transaction"
          />
        )}
      </Card>
    </Container>
  );
}
