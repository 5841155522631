import { Card, Container, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { TableLoader } from "../../../../components/Loaders";
import { IFetchError } from "../../../../types";
import { Toast } from "../../../../utils";
import Fetch from "../../../../utils/fetch";
import TransactionDetailsTable from "./detailsTable";

export interface ITransactionDetails {
  source: string;
  code: string;
  status: boolean;
  type: string;
  amount: string;
  currency: string;
  new_balance: string;
  property_reservation_id: string | null;
  payment_id: number | null;
  created_name: string;
  created_at: string;
}

export default function TransactionDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const transactionId = params.transaction_id;

  const transaction_details = useQuery(
    ["transaction_details", transactionId],
    async () =>
      await Fetch<ITransactionDetails>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/transaction/details/${transactionId}`,
        navigate,
      })
  );

  if (transaction_details.isError) {
    Toast.fire({
      icon: "error",
      title: (transaction_details.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  return (
    <Container>
      <Card className="rounded-2 p-3 mt-3">
        <h3 className="mb-4">Transaction Details</h3>
        {transaction_details.isLoading ? (
          <Table>
            <TableLoader rowLength={3} columnLength={2} />
          </Table>
        ) : transaction_details.isSuccess ? (
          <TransactionDetailsTable data={transaction_details.data.data} />
        ) : (
          <h5>Data not found!</h5>
        )}
      </Card>
    </Container>
  );
}
