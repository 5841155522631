import React, { lazy, Suspense, useEffect, useState } from "react";
import { Card, Row, Col, Button, Image, Spinner } from "react-bootstrap";
import styles from "../../../../assets/scss/cart.module.scss";
import { CartDefinition, RemoveCartItem } from "../../../../types/cart";
import useHoldReservation from "./useHoldReservation";

const SwipeableBottomSheet: any = lazy(
  () => import("react-swipeable-bottom-sheet")
);
interface Props {
  search_id: number;
  cartTotal: number;
  cart: CartDefinition[];
  deleteCartItem: RemoveCartItem;
  property: {
    property_id: string;
    name: string;
    district_name: string;
    thana_name: string;
    image: string;
  };
}
export default function Cart(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const hold_reservation = useHoldReservation({ property: props.property });

  const handleBookNow = () => {
    hold_reservation.mutate({
      property_id: props.property.property_id,
      search_id: props.search_id,
      rooms_details: props.cart.map(
        ({
          photo,
          available,
          name,
          b2b_total_nights_gross_rate,
          ...keepAttrs
        }) => keepAttrs
      ),
    });
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const isMobile = windowWidth <= 990;
  return isMobile ? (
    <Suspense
      fallback={
        <div className="text-center">
          <Spinner className="text-primary" animation="border" role="status" />
        </div>
      }
    >
      <SwipeableBottomSheet
        overflowHeight={60}
        open={isOpen}
        onChange={setIsOpen}
        style={{ zIndex: 100 }}
      >
        <Row>
          <Col>
            <Row onClick={() => setIsOpen(!isOpen)}>
              <Col className="text-center p-2 border-bottom bg-primary text-white">
                <h2 className="fw-bold">৳ {props.cartTotal.toFixed(2)}</h2>
              </Col>
            </Row>
            <Row className={styles.sheetBody}>
              <Col>
                <CartBody
                  cart={props.cart}
                  deleteCartItem={props.deleteCartItem}
                />
              </Col>
            </Row>
            <Row className="pt-3">
              <Col>
                <Button
                  variant="primary"
                  className="w-100 fw-bold"
                  onClick={handleBookNow}
                  disabled={props.cart.length === 0}
                >
                  Book Now
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </SwipeableBottomSheet>
    </Suspense>
  ) : (
    <Row className="stickify mb-3">
      <Card as={Col} lg={12} sm={12}>
        <Row onClick={() => setIsOpen(!isOpen)}>
          <Col className="text-center p-2 border-bottom">
            <h2 className="fw-bold">৳ {props.cartTotal.toFixed(2)}</h2>
          </Col>
        </Row>
        <Row className={styles.cartBody}>
          <Col>
            <CartBody cart={props.cart} deleteCartItem={props.deleteCartItem} />
            {props.cart.length !== 0 ? (
              <Row className="py-3">
                <Col>
                  <Button
                    variant="primary"
                    className="w-100 fw-bold"
                    onClick={handleBookNow}
                  >
                    Book Now
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Card>
    </Row>
  );
}

function CartBody(props: {
  cart: CartDefinition[];
  deleteCartItem: RemoveCartItem;
}) {
  return (
    <>
      {props.cart.map((item: CartDefinition, key: React.Key) => (
        <Row className="font_12 py-1 border-bottom" key={key}>
          <Col
            lg={1}
            sm={1}
            xs={1}
            className="px-1 d-flex justify-content-center align-items-center"
          >
            <span>{item.quantity}</span>
          </Col>
          <Col
            lg={2}
            sm={2}
            xs={2}
            className="px-1 d-flex justify-content-center align-items-center"
          >
            <Image
              className={`rounded-sm ${styles.cartItemImg}`}
              src={
                process.env.REACT_APP_CDN_URL +
                btoa(
                  JSON.stringify({
                    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                    key: item.photo,
                    edits: { resize: { width: 200 } },
                  })
                )
              }
              alt="room-img"
            />
          </Col>
          <Col
            lg={5}
            sm={5}
            xs={5}
            className="px-1 d-flex justify-content-center align-items-center"
          >
            <span>{item.name}</span>
          </Col>
          <Col
            lg={4}
            sm={4}
            xs={4}
            className="px-1 d-flex justify-content-around align-items-center"
          >
            <span>৳ {item.b2b_total_nights_gross_rate.toFixed(2)}</span>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() =>
                props.deleteCartItem({
                  id: item.id,
                  name: item.name,
                  b2b_total_nights_gross_rate: item.b2b_total_nights_gross_rate,
                })
              }
            ></button>
          </Col>
        </Row>
      ))}
    </>
  );
}
