import { Card, Table } from "react-bootstrap";

interface IProps {
  refund: {
    total_gross_refund: number;
    rooms: {
      room_name: string;
      quantity: number;
      gross_refund: number;
    }[];
  };
}

export default function ReservationRefundDetails(props: IProps) {
  return (
    <Card className="my-3 py-3 px-3 rounded-2">
      <Table
        striped
        bordered
        hover
        size="sm"
        responsive
        id="reservations_refund"
      >
        <thead>
          <tr>
            <th className="text-center" colSpan={5}>
              Refund Details
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <th>Room Category</th>
            <th># Rooms</th>
            <th>Total (BDT)</th>
          </tr>
          {props.refund.rooms.map((room, index) => {
            return (
              <tr key={index}>
                <td>{room.room_name}</td>
                <td className="text-center">{room.quantity}</td>
                <td className="text-end">{room.gross_refund.toFixed(2)}</td>
              </tr>
            );
          })}
          <tr>
            <th className="text-center" colSpan={2}>
              Total Refund
            </th>
            <td className="text-end">
              {props.refund.total_gross_refund.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
