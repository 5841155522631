import { Col, FormControlProps, Form } from "react-bootstrap";
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
  UnpackNestedValue,
  useController,
} from "react-hook-form";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends FormControlProps {
  name: TName;
  label: string;
  data: {
    id: number;
    name: string;
    service_charge: number;
  };
  header?: string;
  control: Control<TFieldValues>;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  defaultValue?: UnpackNestedValue<FieldPathValue<TFieldValues, TName>>;
}

export default function SelectPaymentOption<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  header,
  defaultValue,
  label,
  data,
  ...rest
}: Props<TFieldValues, TName>) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue,
  });

  return (
    <Form.Group as={Col} lg={12} className="mb-2">
      <Form.Label
        className={`bg-white ${
          fieldState.invalid ? "border border-1 border-danger" : ""
        }`}
      >
        <Form.Control
          type="radio"
          {...rest}
          {...field}
          value={JSON.stringify(data)}
          className="card-input-element"
        />
        <div className="card-input p-3">
          <div className="panel-heading text-center">{label}</div>
        </div>
      </Form.Label>
    </Form.Group>
  );
}
