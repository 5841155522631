import { NavigateFunction } from "react-router-dom";
import Swal from "sweetalert2";
import { IFetchError } from "../../../../../../types";
import Fetch from "../../../../../../utils/fetch";

interface Params {
  amount: number;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
}

export default async function CityBankInitialize(params: Params) {
  try {
    params.setIsLoading(true);
    const citybank_payment = await Fetch<{ redirectUrl: string }>({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/payment/citybank/initialize`,
      body: {
        amount: params.amount,
      },
      navigate: params.navigate,
    });
    params.setIsLoading(false);
    window.open(citybank_payment.data.redirectUrl, "_self");
  } catch (error) {
    Swal.fire({
      title: (error as IFetchError).message,
      text: "Please Try Again",
      icon: "error",
      confirmButtonColor: "#253678",
    });
  }
}
