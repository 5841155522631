import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../../../utils/fetch";

export interface ITransaction {
  id: number;
  source: string;
  code: string;
  type: string;
  amount: string;
  currency: string;
  property_reservation_id: string | null;
  payment_id: number | null;
  created_at: Date;
}

interface IFetchData {
  count: {
    count: string;
  }[];
  transactions: ITransaction[];
}

interface IParams {
  from_date: Date | null;
  to_date: Date | null;
  transaction_code: string;
  transaction_type: string;
  reservation_id: string;
  payment_id: string;
  offset: number;
  pageSize: number;
  activePage: number;
}

export default function useTransactions(params: IParams) {
  const navigate = useNavigate();

  return useQuery(
    ["transactions", params],
    async () =>
      await Fetch<IFetchData>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/transactions`,
        body: {
          from_date: params.from_date,
          to_date: params.to_date,
          transaction_code: params.transaction_code.trim(),
          transaction_type: params.transaction_type,
          reservation_id: params.reservation_id.trim(),
          payment_id: params.payment_id.trim(),
          offset: params.offset,
          pageSize: params.pageSize,
        },
        navigate,
      })
  );
}
