import { Card, Table } from "react-bootstrap";

interface IProps {
  total_vat: number;
  total_rate: number;
  total_paid: number;
  total_service_charge: number;
  sub_total_rate: number;
  number_of_nights: number;
  poperty_reservation_details: {
    quantity: number;
    rate: number;
    room_name: string;
  }[];
}

export default function ReservationRoomDetails(props: IProps) {
  return (
    <Card className="pt-4 pb-2 px-3 rounded-2">
      <Table striped bordered hover size="sm" responsive id="reservations">
        <thead>
          <tr>
            <th className="text-center" colSpan={5}>
              Room Details
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <th>Room Category</th>
            <th># Rooms</th>
            <th># Nights</th>
            <th>Avg. Rate/Night</th>
            <th>Total (BDT)</th>
          </tr>
          {props.poperty_reservation_details?.map((room, index) => {
            return (
              <tr key={room.room_name}>
                <td>{room.room_name}</td>
                <td className="text-center">{room.quantity}</td>
                <td className="text-center">{props.number_of_nights}</td>
                <td className="text-end">
                  {(Number(room.rate) / props.number_of_nights).toFixed(2)}
                </td>
                <td className="text-end">
                  {(Number(room.rate) * room.quantity).toFixed(2)}
                </td>
              </tr>
            );
          })}
          <tr>
            <th colSpan={4}>Net Room Price</th>
            <td className="text-end">{props.sub_total_rate.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={4}>Service Charge</td>
            <td className="text-end">
              {props.total_service_charge.toFixed(2)}
            </td>
          </tr>

          <tr>
            <td colSpan={4}>VAT </td>
            <td className="text-end">{props.total_vat.toFixed(2)}</td>
          </tr>
          <tr>
            <th colSpan={4}>Total Room Price</th>

            <td className="text-end">{props.total_rate.toFixed(2)}</td>
          </tr>
          <tr>
            <th colSpan={4}>Total Paid</th>
            <td className="text-end">{props.total_paid.toFixed(2)}</td>
          </tr>
          <tr>
            <th colSpan={4}>Due</th>
            <td className="text-end">
              {Number(props.total_rate.toFixed(2)) -
                Number(props.total_paid.toFixed(2))}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
