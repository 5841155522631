import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
export default function HelpCenter() {
  return (
    <Container className="mt-3 mt-lg-4">
      <Row>
        <Col sm={6} className="mb-3">
          <Card className="p-3 rounded-1 shadow-sm text-center">
            <h5 className="fw-bold pb-2 border-bottom">Support</h5>
            <div className="mt-4 font_14">
              <p className="mb-2">
                <FontAwesomeIcon icon={faUser} /> Customer care
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faPhone} /> +8801955880000
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faEnvelope} /> booking@rnrrooms.com
              </p>
            </div>
          </Card>
        </Col>
        <Col sm={6} className="mb-3">
          <Card className="p-3 rounded-1 shadow-sm text-center">
            <h5 className="fw-bold pb-2 border-bottom">Billing</h5>
            <div className="mt-4 font_14">
              <p className="mb-2">
                <FontAwesomeIcon icon={faUser} /> Billing Support
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faPhone} /> +8801955880000
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faEnvelope} /> billing@rnrrooms.com
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
