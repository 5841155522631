import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import InputButton from "../../../components/form-elements/Button";
import Input from "../../../components/form-elements/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import Select from "../../../components/form-elements/Select";

import Pagination from "../../../components/pagination";
import useUsers from "./hooks/useUsers";
import AddUser from "./addUser";
import { Toast } from "../../../utils";
import { IFetchError } from "../../../types";
import AssignPermissions from "./assignPermissions";
import B2bUserUpdate from "./updateUser";
import ResetPassword from "./resetPassword";
import useUserPermissions from "../../../hooks/useUserPermissions";
import { TableLoader } from "../../../components/Loaders";

interface IFormValues {
  name: string;
  status: string;
}

interface IUser {
  id: number;
  type: string;
  name: string;
  email: string;
  status: boolean;
  mobile_no: string;
  photo: string | null;
}

export default function GuestList() {
  const { control, handleSubmit } = useForm<IFormValues>();

  const [updateUserModalShow, setUpdateUserModalShow] = useState(false);
  const [assignPermissionsModalShow, setAssignPermissionsModalShow] =
    useState(false);
  const [updateUser, setUpdateUser] = useState<any | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  const [offset, setOffset] = useState(0);
  const [pageSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    status: "",
    offset,
    pageSize,
  });

  const b2bUsers: any = useUsers({
    name: formData.name,
    status: formData.status,
    offset: formData.offset,
    pageSize: formData.pageSize,
    activePage: activePage,
  });

  const permissions = useUserPermissions();

  const handlePageClick = (pageNumber: number) => {
    setActivePage(pageNumber);
    setOffset(pageSize * (pageNumber - 1));
    setFormData({ ...formData, offset: pageSize * (pageNumber - 1) });
    b2bUsers.refetch();
  };

  const handleFilter: SubmitHandler<IFormValues> = (data) => {
    setOffset(0);
    setFormData({ ...formData, ...data, offset: 0 });
    b2bUsers.refetch();
  };

  if (b2bUsers.isError) {
    Toast.fire({
      icon: "error",
      title: (b2bUsers.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  const generateStatusBadge = (status: boolean) => {
    let status_badge = "badge";
    if (status === true) {
      status_badge += " bg-success";
    }
    if (status === false) {
      status_badge += " bg-danger";
    }
    return status_badge;
  };

  return (
    <Container>
      <Card className="p-3 mt-2 mt-md-3 mt-lg-4">
        <Form className="form-horizontal" onSubmit={handleSubmit(handleFilter)}>
          <Row className="mt-0 align-items-end">
            <Input
              md={4}
              label="Name"
              name="name"
              type="text"
              className="mb-3"
              placeholder="Enter name"
              aria-autocomplete="none"
              control={control}
              defaultValue=""
            />
            <Select
              md={4}
              label="User Status"
              name="status"
              className="mb-3"
              control={control}
              selectOption="Select One"
              defaultValue=""
              options={[
                { id: true, name: "Active" },
                { id: false, name: "Disable" },
              ]}
            />
            <InputButton
              md={4}
              label="Search"
              type="submit"
              variant="primary"
              className="w-100 mb-3"
            />
          </Row>
        </Form>
      </Card>

      <Card className="mt-4 mt-2 mt-md-3 mt-lg-4 p-2 font_14">
        <div className="d-flex justify-content-between px-2 py-3 align-items-center">
          <h5 className="fw-bold mb-0">Admin users</h5>

          {permissions.data?.data.includes("b2b_create_user") ? (
            <div className="d-flex align-items-center">
              <AddUser />
            </div>
          ) : null}
        </div>
        <Table
          responsive="lg"
          className="caption-top"
          bordered
          striped={!b2bUsers.isLoading}
          hover={!b2bUsers.isLoading}
        >
          <thead className="border-bottom">
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {b2bUsers.isLoading ? (
              <TableLoader columnLength={5} />
            ) : b2bUsers.isSuccess ? (
              <>
                {b2bUsers.data.data.users
                  .sort((a: IUser, b: IUser) => a.id - b.id)
                  .map((user: IUser) => {
                    return (
                      <tr className="my-1" key={user.id}>
                        <td className="fw-bold">{user.name}</td>
                        <td>{user.mobile_no}</td>
                        <td>{user.email}</td>
                        <td>
                          <Badge bg={generateStatusBadge(user.status)}>
                            {user.status === true ? "Active" : "Disable"}
                          </Badge>
                        </td>
                        <td className="d-flex align-items-center justify-content-center">
                          {permissions.data?.data.includes(
                            "b2b_manage_user_permission"
                          ) ? (
                            <Button
                              className="me-2 shadow"
                              variant="warning"
                              onClick={() => {
                                setUserId(user.id);
                                setAssignPermissionsModalShow(
                                  !assignPermissionsModalShow
                                );
                              }}
                            >
                              Permissions
                            </Button>
                          ) : null}

                          {permissions.data?.data.includes(
                            "b2b_update_user"
                          ) ? (
                            <Button
                              variant="warning"
                              className="shadow"
                              onClick={() => {
                                setUpdateUser(user);
                                setUpdateUserModalShow(!updateUserModalShow);
                              }}
                            >
                              <FontAwesomeIcon icon={faUserPen} />
                            </Button>
                          ) : null}

                          {permissions.data?.data.includes(
                            "b2b_reset_user_password"
                          ) ? (
                            <ResetPassword
                              user={{ id: user.id, email: user.email }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <h4>Data not found!</h4>
            )}
          </tbody>
        </Table>

        {b2bUsers.isSuccess && (
          <Pagination
            count={b2bUsers.data.data.count[0].count}
            handlePageClick={handlePageClick}
            pageSize={pageSize}
            activePage={activePage}
            name="user"
          />
        )}

        {userId && (
          <AssignPermissions
            user_id={userId}
            show={assignPermissionsModalShow}
            onHide={setAssignPermissionsModalShow}
          />
        )}
        {updateUser && (
          <B2bUserUpdate
            user={updateUser}
            show={updateUserModalShow}
            onHide={setUpdateUserModalShow}
          />
        )}
      </Card>
    </Container>
  );
}
