import { UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { IFetchError, ReservationGuestForm } from "../../../../types";
import { Toast } from "../../../../utils";
import Fetch from "../../../../utils/fetch";

interface ReservationReqBody {
  reservation_id: number;
  guest_name: string;
  guest_email: string;
  guest_mobile_no: string;
  guest_address: string;
  guest_special_request: string;
}

interface ReservationResponse {
  property_reservation: { id: number; status: string; created_at: Date };
  wallet_balance: number;
  transaction_code: string;
}

interface Params {
  setError: UseFormSetError<ReservationGuestForm>;
}

export default function useReservationMutation({ setError }: Params) {
  const navigate = useNavigate();

  return useMutation(
    (body: ReservationReqBody) => {
      return Fetch<ReservationResponse>({
        method: "POST",
        url:
          process.env.REACT_APP_BACKEND_URL +
          "/api/b2b/booking/property/reservation/confirm",
        body,
        navigate,
      });
    },
    {
      onSuccess: async (data) => {
        navigate(
          `/manage/booking/details/${data.data.property_reservation.id}`,
          {
            replace: true,
          }
        );

        await Toast.fire({
          position: "top-end",
          icon: "success",
          title: "Your payment has been successful!",
          showCloseButton: true,
          timer: 2000,
        });

        Toast.fire({
          position: "top-end",
          icon: "success",
          title: data.message,
          showCloseButton: true,
          timer: 3000,
        });
      },
      onError: (error) => {
        if ((error as IFetchError).status === "ValidationError") {
          setError(
            (error as IFetchError).details.body[0].context.key,
            {
              type: "server",
              message: (error as IFetchError).reason,
            },
            { shouldFocus: true }
          );
        } else {
          Swal.fire({
            icon: "error",
            title: (error as IFetchError).message,
            text: (error as IFetchError).reason,
          });
        }
      },
    }
  );
}
