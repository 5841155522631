import React, { useState } from "react";
import {
  Nav,
  Dropdown,
  Navbar,
  Image,
  NavDropdown,
  Modal,
} from "react-bootstrap";
import logo from "../../../assets/img/logo.svg";
import userHeadset from "../../../assets/img/user-headset.svg";
import addMoneyIcon from "../../../assets/img/add_money_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faFingerprint,
  faPowerOff,
  faUser,
  faChevronDown,
  faScrewdriverWrench,
  faCoins,
  faBell,
  faMagnifyingGlass,
  faBarsProgress,
  faWallet,
  faBank,
  faArrowRightArrowLeft,
  faUserPlus,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import "./../../../assets/scss/navbar.scss";
import Fetch from "../../../utils/fetch";
import useUserPermissions from "../../../hooks/useUserPermissions";
import ToggleBalance from "../../toogleBalance";

export default function ProtectedNavbar() {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);

  const handleLogout = async () => {
    await Fetch({
      method: "PATCH",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/user/logout`,
      navigate,
    });

    document.cookie = "token=; Max-Age=0";
    document.cookie = "u_id=; Max-Age=0";
    document.cookie = "email=; Max-Age=0";
    document.cookie = "u_img=; Max-Age=0";
    navigate("/");
  };

  const permissions = useUserPermissions();

  return (
    <>
      <Navbar
        className="shadow-sm py-1 container-fluid"
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
      >
        <Navbar.Brand>
          <Link to="/dashboard">
            <Image src={logo} className="brand_logo" alt="my trigguru" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="border-0 toggle_button"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown
              title={
                <>
                  <FontAwesomeIcon
                    icon={faScrewdriverWrench}
                    className="shadow_background rounded-3 mx-1"
                  />
                  Operations
                  <FontAwesomeIcon icon={faChevronDown} className="mx-1" />
                </>
              }
              renderMenuOnMount={true}
              // id="nav-central-dropdown"
              className="dropdown-hover d-lg-flex "
            >
              <NavDropdown.Item as={Link} to="/dashboard">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                Search
              </NavDropdown.Item>

              {permissions.data?.data.includes("b2b_view_property_booking") ? (
                <NavDropdown.Item as={Link} to="/manage/booking">
                  <FontAwesomeIcon icon={faBarsProgress} /> Manage Booking
                </NavDropdown.Item>
              ) : null}
            </NavDropdown>

            <NavDropdown
              title={
                <>
                  <FontAwesomeIcon
                    icon={faCoins}
                    className="shadow_background rounded-3 mx-1"
                  />
                  Wallet
                  <FontAwesomeIcon icon={faChevronDown} className="mx-1" />
                </>
              }
              renderMenuOnMount={true}
              // id="nav-central-dropdown"
              className="dropdown-hover d-lg-flex "
            >
              {permissions.data?.data.includes("b2b_make_new_payment") ? (
                <NavDropdown.Item as={Link} to="/wallet/money/deposit">
                  <img
                    src={addMoneyIcon}
                    alt="add_money_navbar_icon"
                    className="add_money_navbar_icon rounded-3 me-2"
                  />
                  Add Money
                </NavDropdown.Item>
              ) : null}

              {permissions.data?.data.includes("b2b_view_payments") ? (
                <NavDropdown.Item as={Link} to="/payments">
                  <FontAwesomeIcon icon={faWallet} />
                  Payments
                </NavDropdown.Item>
              ) : null}
              {permissions.data?.data.includes("b2b_view_transactions") ? (
                <NavDropdown.Item as={Link} to="/transactions">
                  <FontAwesomeIcon icon={faArrowRightArrowLeft} /> Transactions
                </NavDropdown.Item>
              ) : null}
              <NavDropdown.Item as={Link} to="/banklist">
                <FontAwesomeIcon icon={faBank} /> Bank List
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={
                <>
                  <FontAwesomeIcon
                    icon={faCog}
                    className="shadow_background rounded-3 mx-1"
                  />
                  Settings
                  <FontAwesomeIcon icon={faChevronDown} className="mx-1" />
                </>
              }
              renderMenuOnMount={true}
              id="nav-settings-dropdown"
              className="dropdown-hover d-lg-flex"
            >
              {permissions.data?.data.includes("b2b_list_user") ? (
                <NavDropdown.Item as={Link} to="/users">
                  <FontAwesomeIcon icon={faUserPlus} /> Users
                </NavDropdown.Item>
              ) : null}

              {/* <NavDropdown.Item as={Link} to="/guestlist">
                <FontAwesomeIcon icon={faAddressCard} /> Guest List
              </NavDropdown.Item> */}

              {permissions.data?.data.includes("view_b2b_profile") ? (
                <NavDropdown.Item as={Link} to="/company">
                  <FontAwesomeIcon icon={faCircleInfo} /> Company
                </NavDropdown.Item>
              ) : null}
            </NavDropdown>
            <NavDropdown
              title={
                <>
                  <img
                    src={userHeadset}
                    alt="user_headset_icon"
                    className="user_headset_icon rounded-3 mx-1"
                  />
                  Support
                  <FontAwesomeIcon icon={faChevronDown} className="mx-1" />
                </>
              }
              renderMenuOnMount={true}
              id="nav-settings-dropdown"
              className="dropdown-hover d-lg-flex"
            >
              <NavDropdown.Item as={Link} to="/help_center">
                <img
                  src={userHeadset}
                  alt="user_headset_icon"
                  className="user_headset_icon rounded-3 mx-1"
                  style={{ backgroundColor: "#d1d9e0" }}
                />{" "}
                Help center
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/tickets">
                <FontAwesomeIcon icon={faTicket} />
                Support Tickets
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
          <Nav className="d-flex align-items-lg-center">
            <ToggleBalance />
            <Dropdown align="end">
              <Dropdown.Toggle
                as="div"
                className="cursor-pointer user_dropdown d-flex align-items-center my-md-2 mx-lg-2"
                bsPrefix="."
              >
                <FontAwesomeIcon
                  icon={faBell}
                  className="mx-1 shadow_background rounded-3 "
                />
                <span className="d-lg-none">Notification</span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="d-lg-none mx-1"
                />
              </Dropdown.Toggle>
              {/* <Dropdown.Menu className="notification">
                <div>
                  <h4>Notification</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>New</h5>
                    <Nav.Link as={Link} to="/notification">
                      See All
                    </Nav.Link>
                  </div>
                  <ListGroup className="font_14">
                    {notificationData.map((notification) => {
                      return (
                        <ListGroup.Item
                          key={notification.id}
                          action
                          variant="info"
                          className="mb-2 border-1"
                          onClick={() => {
                            setModalShow(true);
                            setModalData(notification);
                          }}
                        >
                          <p>{notification.title}</p>
                          <span>{notification.time}</span>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
              </Dropdown.Menu> */}
            </Dropdown>

            <Dropdown align="end" className="dropdown-hover">
              <Dropdown.Toggle
                as="div"
                className="cursor-pointer user_dropdown d-flex align-items-center my-md-2"
                bsPrefix="."
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className="mx-1 shadow_background rounded-3"
                />
                <span className="d-lg-none">Profile</span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="d-lg-none mx-1"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu renderOnMount={true}>
                <Dropdown.Item as={Link} to="/profile">
                  <FontAwesomeIcon icon={faUser} /> Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/change-password">
                  <FontAwesomeIcon icon={faFingerprint} /> Change Password
                </Dropdown.Item>
                <Dropdown.Item tag="a" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faPowerOff} /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

function MyVerticallyCenteredModal(props: any) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Notification details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.data?.title}</p>
        <p>{props.data?.title}</p>
        <span>{props.data?.time}</span>
      </Modal.Body>
    </Modal>
  );
}
