import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Alert } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../../components/form-elements/Input";
import InputButton from "../../../components/form-elements/Button";
import Fetch from "../../../utils/fetch";
import { IFetchError } from "../../../types";

type LoginFormValues = {
  email: string;
  password: string;
};

interface SigninResponse {
  token: string;
  user: {
    b2b_agent_id: number;
    email: string;
    id: number;
    mobile_no: string;
    name: string;
    password: string;
    photo: string | null;
  };
}

function Login(props: any) {
  const navigate = useNavigate();

  const { control, handleSubmit, setError } = useForm<LoginFormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const handleLogin: SubmitHandler<LoginFormValues> = async (data) => {
    try {
      setIsLoading(true);
      const signin = await Fetch<SigninResponse>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/user/login`,
        body: data,
        navigate,
      });

      setIsLoading(false);

      document.cookie = `token=${signin.data.token};`;
      document.cookie = `u_id=${signin.data.user.id};`;
      document.cookie = `email=${signin.data.user.email};`;
      document.cookie = `u_img=${signin.data.user.photo};`;

      navigate("/dashboard");
    } catch (error) {
      setIsLoading(false);

      setErrorMessage((error as IFetchError).message);
      if ((error as IFetchError).status === "ValidationError") {
        setError(
          (error as IFetchError).details.body[0].context.key,
          {
            type: "server",
            message: (error as IFetchError).reason,
          },
          { shouldFocus: true }
        );
      }
      for (const key of Object.keys(data) as Array<keyof typeof data>) {
        if ((error as IFetchError).reason?.toLowerCase().includes(key)) {
          setError(
            key,
            {
              type: "server",
              message: (error as IFetchError).reason,
            },
            { shouldFocus: true }
          );
        }
      }
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center vh-100 ">
        <Col sm={10} md={8} lg={7} xl={5}>
          <Card className="p-4">
            <div className="position-relative">
              {/* <div className="text-center pt-3 px-1">
                <Image src={logo} width="320 " alt="My TrigGuru" />
              </div> */}
              <h3 className="fw-bolder">Sign In</h3>
              <Form
                className="form-horizontal"
                onSubmit={handleSubmit(handleLogin)}
              >
                {errorMessage !== null ? (
                  <Alert variant="danger">{errorMessage}</Alert>
                ) : null}
                <Row className="mt-4">
                  <Input
                    lg={12}
                    label="Email"
                    type="email"
                    placeholder="Enter your email"
                    aria-autocomplete="none"
                    name="email"
                    control={control}
                    rules={{
                      required: "Please provide your email",
                    }}
                  />
                </Row>
                <Row className="mt-4">
                  <Input
                    lg={12}
                    label="Password"
                    type="password"
                    placeholder="password"
                    name="password"
                    control={control}
                    rules={{
                      required: "Please provide your password",
                    }}
                  />
                </Row>
                <Row>
                  <div className="text-end">
                    <Link
                      className="w-100 font_14 text-decoration-none"
                      to="/forgot-password"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </Row>
                <Row className="mt-4">
                  <InputButton
                    lg={12}
                    disabled={isLoading}
                    label={isLoading ? "Logging..." : "SIGN IN"}
                    variant="primary"
                    type="submit"
                    className="w-100"
                  />
                </Row>
              </Form>
              <div className="text-center mt-4">
                <p className="font_14 m-0">
                  Copyright © {new Date().getFullYear()} RNR Rooms. All rights
                  reserved.
                </p>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
