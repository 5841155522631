import React from "react";
import { Col, Form, FormControlProps, Row } from "react-bootstrap";
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import {
  Control,
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
  UnpackNestedValue,
} from "react-hook-form";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends FormControlProps {
  xs?: string | number;
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
  xl?: string | number;
  label: string;
  label_class?: string;
  className?: string;
  group_class?: string;
  name: TName;
  control: Control<TFieldValues>;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  defaultValue?: UnpackNestedValue<FieldPathValue<TFieldValues, TName>>;
}

function DatePickerInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  md,
  xl,
  xs,
  lg,
  sm,
  label,
  className,
  label_class,
  group_class,
  name,
  control,
  rules,
  defaultValue,
  ...rest
}: Props<TFieldValues, TName>) {
  return (
    <Form.Group
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      as={Col}
      className={group_class}
    >
      <Form.Label className={label_class}>{label}</Form.Label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ...field }, fieldState }) => (
          <DatePicker
            onDateChange={onChange}
            date={value}
            {...field}
            locale={enGB}
            format="dd MMM yyyy"
          >
            {({ inputProps, focused }) => (
              <>
                <Form.Control
                  className={
                    `${className} input` + (focused ? " -focused" : "")
                  }
                  {...inputProps}
                  {...rest}
                  placeholder="DD-MMM-YYYY"
                  isInvalid={fieldState.invalid}
                />
                <Form.Control.Feedback type="invalid">
                  {fieldState.error?.message}
                </Form.Control.Feedback>
              </>
            )}
          </DatePicker>
        )}
      />
    </Form.Group>
  );
}

export default DatePickerInput;
