import { useState } from "react";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Form, Row } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import InputButton from "../../../components/form-elements/Button";
import FileInput from "../../../components/form-elements/FileInput";
import Input from "../../../components/form-elements/Input";
import Select from "../../../components/form-elements/Select";
import { IFetchError } from "../../../types";
import { Toast } from "../../../utils";
import Fetch from "../../../utils/fetch";

interface IFormValues {
  name: string;
  email: string;
  mobile_no: string;
  password: string;
  status: string;
  user_photo: string;
}

export default function AddUser() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [modalShow, setModalShow] = useState(false);
  const { control, handleSubmit, reset } = useForm<IFormValues>();

  const { mutate, isLoading }: any = useMutation(
    async (params) =>
      await Fetch({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/user/create`,
        body: params,
        multipart: true,
        navigate,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("b2bUsers");
        Toast.fire({
          icon: "success",
          title: data.message,
          timer: 5000,
          timerProgressBar: true,
          showCloseButton: true,
        });

        reset();
        setModalShow(false);
      },
      onError: (error: IFetchError) => {
        Toast.fire({
          icon: "error",
          title: error?.message,
          text: error.reason,
          position: "bottom-left",
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        });
      },
    }
  );

  const createHandler: SubmitHandler<IFormValues> = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.delete("user_photo");
    if (data.user_photo) formData.append("user_photo", data.user_photo[0]);

    mutate(formData);
  };
  return (
    <>
      <button
        className="mx-1 mx-sm-2 mx-md-3 border-0 bg-white btn_link_effect"
        onClick={() => setModalShow(true)}
      >
        <FontAwesomeIcon icon={faUserPlus} className="fs-5" />
      </button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create user
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="form-horizontal"
            onSubmit={handleSubmit(createHandler)}
          >
            <Row className="mt-0 align-items-baseline">
              <Input
                sm={6}
                label="Name"
                name="name"
                type="text"
                group_class="mb-3"
                control={control}
                placeholder="Enter name"
                rules={{
                  required: "Please provide a name",
                }}
              />
              <Input
                sm={6}
                label="Mobile number"
                name="mobile_no"
                type="tel"
                group_class="mb-3"
                control={control}
                placeholder="Enter phone number"
                rules={{
                  required: "Please provide a phone number",
                }}
              />
              <Input
                sm={6}
                label="Email"
                name="email"
                type="email"
                group_class="mb-3"
                control={control}
                placeholder="Enter email"
                rules={{
                  required: "required email address",
                }}
              />
              <Input
                sm={6}
                label="Password"
                name="password"
                type="text"
                group_class="mb-3"
                control={control}
                placeholder="Enter password"
                rules={{
                  required: "Please provide a password",
                  minLength: {
                    value: 8,
                    message:
                      '"Password" length must be at least 8 characters long',
                  },
                }}
              />

              <Select
                sm={6}
                label="Status"
                name="status"
                group_class="mb-3"
                control={control}
                selectOption="Select status"
                options={[
                  { id: true, name: "Active" },
                  { id: false, name: "Disable" },
                ]}
                rules={{
                  required: "Select one",
                }}
              />
              <FileInput
                name="user_photo"
                control={control}
                group_class="mb-3"
                label="User Photo (Optional)"
                accept=".png, .jpg, .jpeg, .svg"
              />
              <InputButton
                sm={12}
                label={isLoading ? "Creating..." : "Create"}
                type="submit"
                variant="primary"
                className="w-100 mb-3"
              />
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
