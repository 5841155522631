import { QueryClient } from "react-query";
import { NavigateFunction } from "react-router-dom";
import Swal from "sweetalert2";
import { IFetchError } from "../../../../../../types";
import { Toast } from "../../../../../../utils";
import Fetch from "../../../../../../utils/fetch";

interface ICreateBkash {
  paymentID: string;
  createTime: string;
  orgLogo: string;
  orgName: string;
  transactionStatus: string;
  amount: string;
  currency: string;
  intent: string;
  merchantInvoiceNumber: string;
}

interface ICompleteBkash {
  b2b_agent_id: number;
  transaction_amount: number;
  wallet_payment_id: number;
  wallet_payment_status: string;
  wallet_new_balance: number;
}

interface Params {
  amount: number;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
  queryClient: QueryClient;
}

declare var bKash: any;

export default function bKashInit(params: Params) {
  bKash.init({
    paymentMode: "checkout",
    paymentRequest: {
      amount: String(params.amount),
      intent: "sale",
    },

    createRequest: async function () {
      try {
        params.setIsLoading(true);

        const create_payment = await Fetch<ICreateBkash>({
          method: "POST",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/payment/bkash/create`,
          body: { amount: params.amount },
          navigate: params.navigate,
        });
        params.setIsLoading(false);

        if (create_payment.data.paymentID !== null) {
          window.paymentID = create_payment.data.paymentID;
          bKash.create().onSuccess(create_payment.data);
        } else {
          throw create_payment;
        }
      } catch (error) {
        bKash.create().onError();
        Swal.fire({
          title: (error as IFetchError).message,
          text: (error as IFetchError).reason,
          icon: "error",
          confirmButtonColor: "#253678",
        });
      }
    },

    executeRequestOnAuthorization: async function () {
      // execute bKash Payment
      try {
        const bkash_payment = await Fetch<ICompleteBkash>({
          method: "POST",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/payment/bkash/complete`,
          body: { payment_ref_id: window.paymentID },
          navigate: params.navigate,
        });

        Toast.fire({
          icon: "success",
          title: bkash_payment.message,
          position: "top-right",
          showCloseButton: true,
        });
        params.queryClient.invalidateQueries("wallet-balance");
        params.navigate(
          `/payment/details/${bkash_payment.data.wallet_payment_id}`
        );
      } catch (error) {
        bKash.execute().onError();
        Swal.fire({
          title: (error as IFetchError).message,
          text: (error as IFetchError).reason,
          icon: "error",
          confirmButtonColor: "#253678",
        });
      }
    },
  });
}
