import React from "react";
import BadRequestImg from "../assets/img/bad_request.svg";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
interface Props {
  ErrorMessage: string;
  ButtonName: string;
  ButtonTrigger: () => void;
}
export default function RequestFailed(props: Props) {
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center my-4">
          <Col lg="6" className="text-center">
            <Image src={BadRequestImg} />
            <p className="cm-lead my-4">{props.ErrorMessage}</p>
            <Button variant="primary" onClick={props.ButtonTrigger}>
              {props.ButtonName}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
