import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { IFetchError } from "../../../../../types";
import { Toast } from "../../../../../utils";
import Fetch from "../../../../../utils/fetch";

type AccountsType = {
  id: string;
  name: string;
  branch_name: string;
  account_name: string;
  account_number: string;
}[];

export function useDepositAccounts() {
  const navigate = useNavigate();
  return useQuery(
    ["deposit", "accounts"],
    async () =>
      await Fetch<AccountsType>({
        method: "GET",
        url:
          process.env.REACT_APP_BACKEND_URL +
          "/api/dropdowns/bank/deposit/accounts",
        navigate,
      }),
    {
      onError: (error) =>
        Toast.fire({
          icon: "error",
          title: (error as IFetchError).message,
          position: "bottom-left",
          showCloseButton: true,
        }),
    }
  );
}
