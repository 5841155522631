import {
  DateRangePicker,
  START_DATE,
  END_DATE,
  DateChangeCallBack,
} from "react-nice-dates";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { enGB } from "date-fns/locale";

interface IProps {
  sm?: string | number;
  labels: string[];
  labelClass?: string;
  lg?: string | number;
  md?: string | number;
  className?: string;
  required?: boolean;
  icon?: boolean;
  startDate?: Date;
  endDate?: Date;
  onStartDateChange?: DateChangeCallBack;
  onEndDateChange?: DateChangeCallBack;
  minimumDate?: Date;
  maximumDate?: Date;
  minimumLength?: number;
  maximumLength?: number;
}

export default function DateRangePickerCustom({
  labelClass,
  lg,
  sm,
  md,
  className,
  required,
  labels,
  icon,
  ...rest
}: IProps) {
  return (
    <Form.Group
      sm={sm}
      lg={lg}
      md={md}
      as={Col}
      className="mb-3 position-relative"
    >
      <DateRangePicker locale={enGB} format="dd MMM yyyy" {...rest}>
        {({ startDateInputProps, endDateInputProps, focus }) => (
          <>
            <Row>
              <Form.Group as={Col} className="mb-0 position-relative">
                <Form.Label className={labelClass ? labelClass : ""}>
                  {labels[0]}
                </Form.Label>
                <Form.Control
                  className={`input ${className} ${
                    focus === START_DATE ? " -focused" : ""
                  }`}
                  {...startDateInputProps}
                  placeholder={labels[0]}
                  required={required}
                />
                {icon ? (
                  <span className="cm-form-icon ">
                    <FontAwesomeIcon icon={faCalendar} />
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group as={Col} className="mb-0 position-relative">
                <Form.Label className={labelClass ? labelClass : ""}>
                  {labels[1]}
                </Form.Label>
                <Form.Control
                  className={`input ${className} ${
                    focus === END_DATE ? " -focused" : ""
                  }`}
                  {...endDateInputProps}
                  placeholder={labels[1]}
                  required={required}
                />
                {icon ? (
                  <span className="cm-form-icon ">
                    <FontAwesomeIcon icon={faCalendar} />
                  </span>
                ) : null}
              </Form.Group>
            </Row>
          </>
        )}
      </DateRangePicker>
    </Form.Group>
  );
}
