import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../../utils/fetch";

interface PropetiesData {
  properties: {
    id: number;
    name: string;
    photo: string;
    district_name: string;
    thana_name: string;
    property_breakfast: string;
    property_parking: string;
    pricing: {
      per_night: {
        guest: { gross_rate: string };
        b2b_agent: {
          gross_rate: string;
        };
        currency: string;
      };
    };
    rating: number;
    facilities: {
      icon: IconProp;
      name: string;
      priority: number;
    }[];
  }[];
  check_in: string;
  check_out: string;
  has_more: boolean;
  nextOffset: number;
}
interface Params {
  location_type: string;
  location_id: number;
  start_date: string;
  end_date: string;
}

export default function useProperties(params: Params) {
  const navigate = useNavigate();
  return useInfiniteQuery(
    [
      {
        scope: "properties",
        ...params,
      },
    ],
    async ({
      pageParam = 0,
      queryKey: [{ location_type, location_id, start_date, end_date }],
    }) =>
      await Fetch<PropetiesData>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/booking/search/properties`,
        body: {
          location_type,
          location_id,
          start_date,
          end_date,
          offset: pageParam,
          limit: 10,
        },
        navigate,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        lastPage.data.has_more ? lastPage.data.nextOffset : undefined,
    }
  );
}
