import { Spinner } from "react-bootstrap";
import ContentLoader from "react-content-loader";

interface ITLProps {
  rowLength?: number;
  columnLength?: number;
}

export function TableLoader({ rowLength, columnLength }: ITLProps) {
  return (
    <>
      {Array.from({ length: rowLength ? rowLength : 3 }).map(() => {
        return (
          <tr>
            {Array.from({ length: columnLength ? columnLength : 5 }).map(() => (
              <td>
                <ContentLoader viewBox="0 0 380 70">
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
              </td>
            ))}
          </tr>
        );
      })}
    </>
  );
}

export function Loader() {
  return (
    // <div className="p-3 bg-light rounded-circle shadow-lg">
    <Spinner className="loader_cm" animation="border" role="status"></Spinner>
    // </div>
  );
}
