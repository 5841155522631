import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { CartDefinition } from "../../../../../types/cart";
import Fetch from "../../../../../utils/fetch";

export interface ICancellationPolicy {
  policy: string;
  penalty: string;
  details: string;
}

export interface PropertyRoom {
  id: number;
  name: string;
  room_size_sqft: string | number;
  available: number;
  occupancy: number;
  room_photos: { name: string }[];
  room_beds: { name: string }[];
  room_amenities: { name: string; icon: IconProp }[];
  cancellation_policy: ICancellationPolicy;
  pricing: {
    per_night: {
      guest: { net_rate: string };
      b2b_agent: { net_rate: string };
      vat: string;
      service_charge: string;
    };
    total_nights: {
      guest: { net_rate: string };
      b2b_agent: {
        net_rate: string;
      };
      vat: string;
      service_charge: string;
    };
    currency: string;
  };
}

interface PropertyRoomsData {
  search_id: number;
  property_rooms: PropertyRoom[];
  check_in: string;
  check_out: string;
  number_of_nights: number;
}

interface Params {
  property_id?: string;
  start_date: string;
  end_date: string;
  cart: CartDefinition[];
  setCart: React.Dispatch<React.SetStateAction<CartDefinition[]>>;
  setCartTotal: React.Dispatch<React.SetStateAction<number>>;
}

export function usePropertyRooms(params: Params) {
  const navigate = useNavigate();
  return useQuery(
    [
      {
        scope: "property",
        entity: "rooms",
        property_id: params.property_id,
        start_date: params.start_date,
        end_date: params.end_date,
      },
    ],
    async ({ queryKey: [{ property_id, start_date, end_date }] }) => {
      return await Fetch<PropertyRoomsData>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/booking/search/property_rooms`,
        body: {
          property_id,
          start_date,
          end_date,
        },
        navigate,
      });
    },
    {
      keepPreviousData: true,
      onSettled: () => {
        params.setCart([]);
        params.setCartTotal(0.0);
      },
      // onSuccess: (data) => {
      //   const current_roomdetails = data.data.property_rooms.filter(
      //     (currentRoom) =>
      //       params.cart.find((preRoom) => {
      //         if (currentRoom.id === preRoom.id) {
      //           if (
      //             currentRoom.client_total_nights_net_rate !==
      //               preRoom.client_total_nights_net_rate ||
      //             currentRoom.available !== preRoom.available
      //           ) {
      //             params.setCartTotal(0.0);
      //             params.setCart([]);

      //             Toast.fire({
      //               icon: "warning",
      //               title: "Room Price or Inventory update",
      //               position: "top-right",
      //               showCloseButton: true,
      //               timer: 5000,
      //               timerProgressBar: true,
      //             });
      //             return true;
      //           }
      //         }
      //         return false;
      //       })
      //   );

      //Need to Exprenent on single room change
      // console.log("====================================");
      // console.log(current_roomdetails);
      // console.log("====================================");
      //   },
    }
  );
}
