import React, { RefObject, useEffect } from "react";
import { LoadScript, RemoveScript, Toast } from "../../../../../../utils";

interface Props {
  bKashButtonRef: RefObject<HTMLButtonElement>;
}

export default function BkashPayment(props: Props) {
  useEffect(() => {
    (async () => {
      // load jquery script //

      await LoadScript(
        "jquery_script",
        "https://code.jquery.com/jquery-3.3.1.min.js"
      ).catch((error) =>
        Toast.fire({ icon: "error", title: "Unable to load scripts!" })
      );

      // load bKash script //

      const bKash_script =
        process.env.REACT_APP_VERSION === "beta"
          ? "https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js"
          : "https://scripts.pay.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout.js";
      await LoadScript("bKash_script", bKash_script).catch((error) =>
        Toast.fire({ icon: "error", title: "Unable to load scripts!" })
      );
    })();

    return () => {
      RemoveScript("jquery_script");
      RemoveScript("bKash_script");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      id="bKash_button"
      style={{ display: "none" }}
      ref={props.bKashButtonRef}
    >
      Pay with bKash
    </button>
  );
}
