import React, { useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faUser } from "@fortawesome/free-solid-svg-icons";

interface Props {
  lg: any;
  totalRooms: any;
  totalGuests: any;
  rooms_guests: any[];
  setRoomsGuests: (arg0: (prev: any) => any[]) => void;
}

export default function RoomsGuests(props: Props) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Form.Group lg={props.lg} as={Col} className="position-relative mb-3">
        <Form.Label className="cm-form-label">Rooms Guests</Form.Label>
        <Form.Control
          className="w-100 btn-cm-form"
          autoComplete="off"
          type="text"
          onClick={() => setModalShow(true)}
          value={`${props.totalRooms} Room${props.totalRooms > 1 ? "s" : ""}, ${
            props.totalGuests
          } Guest${props.totalGuests > 1 ? "s" : ""}`}
          required
          readOnly
        />
        <span className="cm-form-icon">
          <FontAwesomeIcon icon={faUser} />
        </span>
      </Form.Group>

      <Modal show={modalShow} onHide={() => setModalShow(!modalShow)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Rooms & Guests
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {props.rooms_guests.map((room_guest: any, key: number) => (
              <RoomsGuestCells
                key={key}
                room_guest={room_guest}
                id={++key}
                setRoomsGuests={props.setRoomsGuests}
              />
            ))}
            {/* <Row className="d-flex justify-content-end m-3">
              <Form.Group lg={6} as={Col}>
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={() =>
                    props.setRoomsGuests((prev: any) => [
                      ...prev,
                      { adult: 1, child: 0 },
                    ])
                  }
                >
                  Add Another Room
                </Button>
              </Form.Group>
            </Row> */}
            <Row>
              <Form.Group lg={12} as={Col}>
                <Button
                  className="w-100"
                  variant="primary"
                  onClick={() => setModalShow(false)}
                >
                  Done
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

function RoomsGuestCells(props: {
  room_guest: { adult: number; child: number };
  id:
    | string
    | number
    | boolean
    | {}
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal
    | null
    | undefined;
  setRoomsGuests: (arg0: {
    (prev: any): any[];
    (prev: any): any[];
    (prev: any): any[];
    (prev: any): any[];
  }) => void;
}) {
  const [adult, setAdult] = useState(props.room_guest.adult);
  const [child, setChild] = useState(props.room_guest.child);

  return (
    <div className="p-2 border-bottom">
      <h6 className="fw-bold">Room {props.id}</h6>
      <Row className="text-center">
        <Col lg={6} className="pt-2">
          Adults
        </Col>
        <Form.Group lg={6} as={Col}>
          <Button
            variant="light"
            size="sm"
            className="rounded-circle m-2 border"
            onClick={() => {
              setAdult(adult + 1);
              props.room_guest.adult += 1;
              props.setRoomsGuests((prev: any) => [...prev]);
            }}
          >
            <FontAwesomeIcon icon={faPlus} color="#000000" />
          </Button>
          <span>{adult}</span>
          <Button
            variant="light"
            size="sm"
            className="rounded-circle m-2 border"
            onClick={() => {
              if (adult > 1) {
                setAdult(adult - 1);
                props.room_guest.adult -= 1;
                props.setRoomsGuests((prev: any) => [...prev]);
              }
            }}
          >
            <FontAwesomeIcon icon={faMinus} color="#000000" />
          </Button>
        </Form.Group>
      </Row>
      <Row className="text-center">
        <Col lg={6} className="pt-2">
          Child
        </Col>
        <Form.Group lg={6} as={Col}>
          <Button
            variant="light"
            size="sm"
            className="rounded-circle m-2 border"
            onClick={() => {
              setChild(child + 1);
              props.room_guest.child += 1;
              props.setRoomsGuests((prev: any) => [...prev]);
            }}
          >
            <FontAwesomeIcon icon={faPlus} color="#000000" />
          </Button>
          <span>{child}</span>
          <Button
            variant="light"
            size="sm"
            className="rounded-circle m-2 border"
            onClick={() => {
              if (child > 0) {
                setChild(child - 1);
                props.room_guest.child -= 1;
                props.setRoomsGuests((prev: any) => [...prev]);
              }
            }}
          >
            <FontAwesomeIcon icon={faMinus} color="#000000" />
          </Button>
        </Form.Group>
      </Row>
    </div>
  );
}
