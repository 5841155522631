import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import DateRangePickerCustom from "../../../components/form-elements/DateRangePicker";
import RoomsGuests from "../../../components/RoomsGuests";
import SearchDestination from "../../../components/SearchDestination";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [startDate, setStartDate] = useState<null | Date>();
  const [endDate, setEndDate] = useState<null | Date>();
  const [searchDestination, setSearchDestination] = useState<{
    destination_type: string;
    id: number;
    name: string;
  }>();

  const [roomsGuests, setRoomsGuests] = useState([{ adult: 1, child: 0 }]);
  const [totalRooms, setTotalRooms] = useState(1);
  const [totalGuests, setTotalGuests] = useState(1);
  const navigate = useNavigate();

  // calculate total rooms and guests

  useEffect(() => {
    let total_guests = 0;
    roomsGuests.forEach((room_guest) => {
      total_guests += room_guest.adult;
      total_guests += room_guest.child;
    });
    setTotalRooms(roomsGuests.length);
    setTotalGuests(total_guests);
  }, [roomsGuests]);

  const handlePropertySearch = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (searchDestination?.destination_type === "property") {
      navigate(
        `/search/property/${searchDestination.id}?s_date=${format(
          startDate!,
          "yyyy-MM-dd"
        )}&e_date=${format(
          endDate!,
          "yyyy-MM-dd"
        )}&total_rooms=${totalRooms}&total_guests=${totalGuests}&rooms_guests=${btoa(
          JSON.stringify(roomsGuests)
        )}`
      );
    } else {
      navigate("/search/properties", {
        state: { startDate, endDate, searchDestination, roomsGuests },
      });
    }
  };

  return (
    <div className="home_banner">
      <Container className="h-75 d-flex justify-content-center align-items-center">
        <Row>
          <Col>
            <Card className="px-4 pt-5 pb-4">
              <Form onSubmit={handlePropertySearch}>
                <Row>
                  <SearchDestination
                    lg={4}
                    searchDestination={searchDestination}
                    setSearchDestination={setSearchDestination}
                  />
                  <DateRangePickerCustom
                    labels={["Check In", "Check Out"]}
                    labelClass="cm-form-label"
                    className="cm-form-input"
                    icon={true}
                    lg={5}
                    minimumLength={1}
                    minimumDate={new Date()}
                    startDate={startDate!}
                    endDate={endDate!}
                    onStartDateChange={(date) => setStartDate(date!)}
                    onEndDateChange={(date) => setEndDate(date!)}
                    required={true}
                  />
                  <RoomsGuests
                    lg={3}
                    totalGuests={totalGuests}
                    totalRooms={totalRooms}
                    rooms_guests={roomsGuests}
                    setRoomsGuests={setRoomsGuests}
                  />
                </Row>
                <Row className="justify-content-center">
                  <Form.Group lg={3} as={Col} className="m-0 top_20">
                    <Button
                      className="w-100 font-weight-bold p-2"
                      variant="primary"
                      type="submit"
                    >
                      Search
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
