import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import PaymentFailedIcon from "../../../../assets/img/PaymentFailed.svg";
import { useNavigate, useParams } from "react-router-dom";

function PaymentFailed() {
  const navigate = useNavigate();

  const params = useParams();

  const { message, error } = JSON.parse(atob(params.response!));

  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center my-5">
          <Col lg="6" className="text-center py-4">
            <Image width={250} src={PaymentFailedIcon} />
            <h3 className="my-4 font-weight-light">
              Payment <span className="font-weight-bold">Failed</span>
            </h3>
            <p>{message}</p>
            <p>{error}</p>
            <Button
              variant="primary"
              onClick={() => navigate(`/wallet/money/deposit`)}
            >
              Try Again
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PaymentFailed;
