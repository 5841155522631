import React, { useEffect } from "react";
import { Container, Row, Spinner, Image } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Fetch from "../../../../../../utils/fetch";
import Logo from "../../../../../../assets/img/rnr_rooms_logo_white.svg";
import { useMutation, useQueryClient } from "react-query";
import { IFetchError } from "../../../../../../types";
import { Toast } from "../../../../../../utils";

interface PaymentReqBody {
  payment_ref_id: string;
  reason_message: string;
}

interface PaymentResponse {
  b2b_agent_id: number;
  transaction_amount: number;
  wallet_payment_id: number;
  wallet_payment_status: string;
  wallet_new_balance: number;
}

export default function ProcessingNagadPayment() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const payment_ref_id = searchParams.get("payment_ref_id")!;

  const complete_payment = useMutation(
    (body: PaymentReqBody) => {
      return Fetch<PaymentResponse>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/payment/nagad/complete`,
        body: {
          payment_reference_id: body.payment_ref_id,
          reason_message: body.reason_message,
        },
        navigate,
      });
    },
    {
      onSuccess: (response) => {
        Toast.fire({
          icon: "success",
          title: response.message,
          position: "top-right",
          showCloseButton: true,
        });
        queryClient.invalidateQueries("wallet-balance");
        navigate(`/payment/details/${response.data.wallet_payment_id}`, {
          replace: true,
        });
      },
      onError: (error) => {
        navigate(
          `/wallet/payment/failed/${btoa(
            JSON.stringify({
              message: (error as IFetchError).message,
              error: searchParams.get("message"),
            })
          )}`,
          {
            replace: true,
          }
        );
      },
    }
  );

  useEffect(() => {
    complete_payment.mutate({
      payment_ref_id,
      reason_message: searchParams.get("message")!,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      className="vh-100 vw-100 bg-primary d-flex justify-content-center align-items-center"
      fluid
    >
      <Row className="w-50 h-50 justify-content-center align-items-center">
        <Spinner
          style={{
            width: "4rem",
            height: "4rem",
            borderWidth: "0.30em",
          }}
          variant="light"
          animation="border"
          role="status"
        ></Spinner>

        <h2 className="text-white w-100 text-center">
          We are processing your payment
        </h2>

        <h3 className="text-white w-100 text-center">
          Please don't close this page
        </h3>
        <Image width="420" height="120" src={Logo}></Image>
      </Row>
    </Container>
  );
}
