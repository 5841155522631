import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import getSessionCookie from "../utils/getSessionCookie";

interface Props {
  children: JSX.Element;
}

export default function RequireAuth({ children }: Props) {
  let location = useLocation();

  const auth_token = getSessionCookie("token");

  // Check-to see if user is authenticate

  if (!auth_token) {
    // User is not authenticated so redirect him to login page
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
