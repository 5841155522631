import React, { lazy, Suspense } from "react";
import { Col } from "react-bootstrap";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentLoader from "react-content-loader";

const GoogleMap: any = lazy(() => import("google-map-react"));

export default function HotelLocation(props: {
  latitude: number;
  longitude: number;
  division_name: string;
  district_name: string;
  thana_name: string;
  address: string;
  post_code: string;
}) {
  return (
    <Col lg={4} style={{ height: "300px" }}>
      <Suspense
        fallback={
          <ContentLoader
            width="100%"
            height={250}
            backgroundColor="#E1E9EE"
            foregroundColor="#F2F8FC"
          >
            <rect rx="10" ry="10" width="100%" height="250" />
          </ContentLoader>
        }
      >
        <GoogleMap
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
          }}
          defaultCenter={{
            lat: Number(props.latitude),
            lng: Number(props.longitude),
          }}
          defaultZoom={10}
        >
          <MapMarker lat={props.latitude} lng={props.longitude} />
        </GoogleMap>
      </Suspense>
    </Col>
  );
}

const MapMarker = (props: any) => {
  return <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="#0080BD" />;
};
