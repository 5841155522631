import { Link } from "react-router-dom";
import { Badge, Card, Container, Form, Row, Table } from "react-bootstrap";
import InputButton from "../../../components/form-elements/Button";
import Input from "../../../components/form-elements/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import Select from "../../../components/form-elements/Select";
import { add, format, isBefore, parseISO } from "date-fns";
import { useState } from "react";
import DateRangePickerCustom from "../../../components/form-elements/DateRangePicker";
import useReservations, { IReservation } from "./useReservations";
import { Toast } from "../../../utils";
import { IFetchError } from "../../../types";
import Pagination from "../../../components/pagination";
import { TableLoader } from "../../../components/Loaders";
import useUserPermissions from "../../../hooks/useUserPermissions";
import CancelBooking from "./cancel/CancelBooking";

type IFilterBooking = {
  reservation_id: string;
  date_type: string;
  reservation_status: string;
  payment_status: string;
  guest_name: string;
  guest_email: string;
  guest_mobile_no: string;
};

export default function ManageBooking() {
  const { control, handleSubmit, getValues } = useForm<IFilterBooking>();
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);

  const [offset, setOffset] = useState(0);
  const [pageSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [formData, setFormData] = useState({
    from_date: startDate,
    to_date: endDate,
    reservation_id: "",
    date_type: "",
    reservation_status: "",
    payment_status: "",
    guest_name: "",
    guest_email: "",
    guest_mobile_no: "",
    offset,
    pageSize,
  });

  const permissions = useUserPermissions();

  const reservations = useReservations(formData);

  const handlePageClick = (pageNumber: number) => {
    setActivePage(pageNumber);
    setOffset(pageSize * (pageNumber - 1));
    setFormData({ ...formData, offset: pageSize * (pageNumber - 1) });
    reservations.refetch();
  };

  const handleFilter: SubmitHandler<IFilterBooking> = (data) => {
    setOffset(0);
    setFormData({
      ...formData,
      ...data,
      offset: 0,
      from_date: startDate,
      to_date: endDate,
    });
    reservations.refetch();
  };

  if (reservations.isError) {
    Toast.fire({
      icon: "error",
      title: (reservations.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  return (
    <Container>
      <Card className="p-3 mt-2 mt-md-3 mt-lg-4">
        <Form className="form-horizontal" onSubmit={handleSubmit(handleFilter)}>
          <Row className="mt-0 align-items-end">
            <Select
              lg={2}
              md={4}
              sm={6}
              label="Date Of"
              name="date_type"
              className="mb-3"
              control={control}
              selectOption="Select One"
              defaultValue=""
              options={[
                { id: "check_in", name: "Check In" },
                { id: "check_out", name: "Check Out" },
                { id: "reservation", name: "Reservation" },
              ]}
            />

            <DateRangePickerCustom
              lg={6}
              md={4}
              sm={6}
              labels={["Date From", "Date To"]}
              minimumLength={
                getValues("date_type") === "reservation" ? undefined : 1
              }
              startDate={startDate!}
              endDate={endDate!}
              onStartDateChange={(date) => setStartDate(date!)}
              onEndDateChange={(date) => setEndDate(date!)}
            />

            <Select
              lg={2}
              md={4}
              sm={6}
              label="Reservation Status"
              name="reservation_status"
              className="mb-3"
              control={control}
              selectOption="Select One"
              defaultValue=""
              options={[
                { id: "confirmed", name: "Confirmed" },
                { id: "canceled", name: "Canceled" },
                { id: "hold", name: "Hold" },
                { id: "system_canceled", name: "System Canceled" },
              ]}
            />

            <Input
              lg={2}
              md={4}
              sm={6}
              label="Reservation ID"
              name="reservation_id"
              defaultValue=""
              type="text"
              className="mb-3"
              aria-autocomplete="none"
              control={control}
              placeholder="Reservation id"
            />

            <Select
              lg={2}
              md={4}
              sm={6}
              label="Payment Status"
              name="payment_status"
              defaultValue=""
              className="mb-3"
              control={control}
              selectOption="Select One"
              options={[
                { id: "PAID", name: "PAID" },
                { id: "PARTIAL PAID", name: "PARTIAL PAID" },
                { id: "UNPAID", name: "UNPAID" },
              ]}
            />

            <Input
              lg={2}
              md={4}
              sm={6}
              label="Guest Name"
              name="guest_name"
              defaultValue=""
              type="text"
              className="mb-3"
              aria-autocomplete="none"
              control={control}
              placeholder="Guest Name"
            />

            <Input
              lg={2}
              md={4}
              sm={6}
              label="Guest Phone"
              name="guest_mobile_no"
              defaultValue=""
              type="text"
              className="mb-3"
              aria-autocomplete="none"
              control={control}
              placeholder="Guest Phone"
            />

            <Input
              lg={2}
              md={4}
              sm={6}
              label="Guest Email"
              name="guest_email"
              defaultValue=""
              type="text"
              className="mb-3"
              aria-autocomplete="none"
              control={control}
              placeholder="Guest Email"
            />
            <InputButton
              lg={2}
              md={4}
              sm={6}
              label="Submit"
              type="submit"
              variant="primary"
              className="w-100 mb-3"
            />
          </Row>
        </Form>
      </Card>
      <Card className="mt-4 mt-2 mt-md-3 mt-lg-4 p-2 font_14">
        <div className="d-flex justify-content-between px-2 py-3 align-items-center">
          <h5 className="fw-bold mb-0">Manage Booking</h5>
          {/* <div className="d-flex align-items-center">
              <button className="border-0 bg-white btn_link_effect">
                <FontAwesomeIcon icon={faFileExcel} className="fs-4" />
              </button>
              <button className="mx-1 mx-sm-2 border-0 bg-white">
                <img
                  src={addMoneyIcon}
                  alt="add_money_icon"
                  className="h-25 add_money_icon"
                />
              </button>
            </div> */}
        </div>
        <Table
          responsive="lg"
          className="caption-top"
          bordered
          striped={!reservations.isLoading}
          hover={!reservations.isLoading}
        >
          <thead className="border-bottom">
            <tr>
              <th>Reservation ID</th>
              <th>Hotel Name</th>
              <th>Guest Name</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Nights</th>
              <th>Rooms</th>
              <th>Booked On</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {reservations.isLoading ? (
              <TableLoader columnLength={10} />
            ) : reservations.isSuccess ? (
              <>
                {reservations.data.data.reservations.map(
                  (reservation: IReservation) => {
                    const [hours, minutes, seconds] =
                      reservation.checkin_time.split(":");

                    const checkin_datetime = add(
                      parseISO(reservation.check_in),
                      {
                        hours: Number(hours),
                        minutes: Number(minutes),
                        seconds: Number(seconds),
                      }
                    );

                    const is_cancelable = isBefore(
                      new Date(),
                      checkin_datetime
                    );

                    return (
                      <tr className="my-1" key={reservation.id}>
                        <td>
                          <Link
                            to={`/manage/booking/details/${reservation.id}`}
                          >
                            <span className="text-primary fw-bold">
                              {reservation.id}
                            </span>
                          </Link>
                        </td>
                        <td>{reservation.property_name}</td>
                        <td>{reservation.guest_name}</td>
                        <td>
                          {format(
                            new Date(reservation.check_in),
                            "dd MMMM yyyy"
                          )}
                        </td>
                        <td>
                          {format(
                            new Date(reservation.check_out),
                            "dd MMMM yyyy"
                          )}
                        </td>
                        <td>{reservation.number_of_nights}</td>
                        <td>{reservation.rooms}</td>
                        <td>
                          {" "}
                          {format(
                            new Date(reservation.created_at),
                            "dd MMMM yyyy"
                          )}
                        </td>
                        <td>
                          <Badge
                            bg={generateStatusBadge(
                              reservation.reservation_status
                            )}
                          >
                            {reservation.reservation_status}
                          </Badge>
                        </td>
                        <td>
                          {Number(reservation.grand_total_rate).toFixed(2)}
                          <br />
                          <span
                            className={getPaymentStatusColor(
                              reservation.payment_status
                            )}
                          >
                            {reservation.payment_status}

                            {reservation.total_paid_amount
                              ? ` - ${reservation.total_paid_amount}`
                              : null}
                          </span>
                        </td>
                        <td>
                          {is_cancelable &&
                          reservation.reservation_status === "confirmed" &&
                          permissions.data?.data.includes(
                            "b2b_cancel_property_booking"
                          ) &&
                          !reservation.is_invoice_closed ? (
                            <CancelBooking reservation={reservation} />
                          ) : null}
                        </td>
                      </tr>
                    );
                  }
                )}
              </>
            ) : (
              <h4>Data not found!</h4>
            )}
          </tbody>
        </Table>

        {reservations.isSuccess && (
          <Pagination
            count={Number(reservations.data.data.count[0].count)}
            handlePageClick={handlePageClick}
            pageSize={pageSize}
            activePage={activePage}
            name="reservation"
          />
        )}
      </Card>
    </Container>
  );
}
const getPaymentStatusColor = (status: string) => {
  let color = "text-dark";
  switch (status) {
    case "PAID":
      color = "text-success";
      break;
    case "PARTIAL PAID":
      color = "text-warning";
      break;
    case "UNPAID":
      color = "text-danger";
      break;
  }
  return color;
};
const generateStatusBadge = (status: string) => {
  let status_badge = "badge";
  status === "confirmed"
    ? (status_badge += " bg-success")
    : (status_badge += " bg-danger");

  return status_badge;
};
