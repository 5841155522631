import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../../utils/fetch";

interface IFetchData {
  agent_total_gross_refund: number;
  refund_room_details: {
    room_id: number;
    room_name: string;
    quantity: number;
    cancellation_period: string;
    cancellation_penalty: string;
    agent_gross_refund: number;
  }[];
}

interface IParams {
  reservation_id: number;
}

export default function useCancellationDetails(params: IParams) {
  const navigate = useNavigate();

  return useQuery(
    [{ scope: "cancellation-details", reservation_id: params.reservation_id }],
    async ({ queryKey: [{ reservation_id }] }) =>
      await Fetch<IFetchData>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/booking/property/reservation/cancel/${reservation_id}`,
        navigate,
      }),
    { keepPreviousData: true, enabled: false }
  );
}
