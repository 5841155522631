import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Fetch from "../../../../utils/fetch";
import Input from "../../../../components/form-elements/Input";
import InputButton from "../../../../components/form-elements/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query";
import { IFetchError } from "../../../../types";
import { Toast } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

interface IFormValues {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<IFormValues>();

  const { mutate, isLoading, isSuccess }: any = useMutation(
    async (params) =>
      await Fetch({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/user/password/forgot`,
        body: params,
        navigate,
      }),
    {
      onError: (error: IFetchError) => {
        Toast.fire({
          icon: "error",
          title: error?.message,
          text: error.reason,
          position: "bottom-left",
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        });
      },
    }
  );

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    mutate(data);
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center vh-100">
        {isSuccess ? (
          <Col sm={10} md={8} lg={7} xl={5}>
            <Card className="p-4">
              <div className="position-relative">
                <div className="text-center pb-3 px-1">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    fontSize={50}
                    color="#a5dc86"
                  />
                </div>
                {/* <Card.Body>  */}
                <h5 className="fw-bold mt-3 mb-4 text-center">
                  Password reset email sent successfully
                </h5>
                <h6 className="fw-bold mb-2 font_14 text-center">
                  In the next 5 minutes, you must follow these steps
                </h6>
                <ol className="font_14 mt-3">
                  {/* <li>Open the email sent to {values.email}</li> */}
                  <li>
                    Click the link in the email to be taken to the page where
                    you will create a new password
                  </li>
                  <li>Save the new password.</li>
                </ol>
                <Row className="text-center mt-3 mb-4">
                  <Col>
                    <Link className="fw-bold" to="/">
                      Return to Sign In
                    </Link>
                  </Col>
                </Row>
                {/* </Card.Body> */}
                <div className="text-center">
                  <p className="m-0 font_14">
                    Copyright © {new Date().getFullYear()} RNR Rooms. All rights
                    reserved.
                  </p>
                </div>
              </div>
            </Card>
          </Col>
        ) : (
          // </Col>
          <Col sm={10} md={8} lg={7} xl={5}>
            <Card className="p-4">
              <div className="position-relative">
                <h4 className="fw-bolder text-center">Forgot password</h4>
                <p className="text-center mt-4 font_14">
                  We will email you instructions for resetting your password.
                </p>
                <Form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row className="mt-4">
                    <Input
                      lg={12}
                      label="Email"
                      type="email"
                      placeholder="name@address.com"
                      aria-autocomplete="none"
                      name="email"
                      control={control}
                      rules={{
                        required: "Please provide your email",
                      }}
                    />
                  </Row>
                  <Row className="mt-4">
                    <InputButton
                      lg={12}
                      label={isLoading ? "Sending" : "SEND"}
                      variant="primary"
                      type="submit"
                      className="w-100"
                    />
                  </Row>
                  <Row>
                    <div className="text-center mt-3">
                      <Link
                        className="w-100 font_14 text-decoration-none"
                        to="/"
                      >
                        Back to Sign In
                      </Link>
                    </div>
                  </Row>
                </Form>
                <div className="text-center mt-3">
                  <p className="font_14 m-0">
                    Copyright © {new Date().getFullYear()} RNR Rooms. All rights
                    reserved.
                  </p>
                </div>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ForgotPassword;
