import React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import AppRoute from "./router/route";
import { AppRoutes } from "./router/index";
import ErrorBoundary from "./components/ErrorBoundary";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { library } from "@fortawesome/fontawesome-svg-core";
import Icons from "./components/Icons";
import { Toast } from "./utils";

library.add(...Icons);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      // only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        Toast.fire({
          icon: "error",
          title: "Failed to fetch data!",
          position: "bottom-left",
          showCloseButton: true,
          timer: 5000,
        });
      }
    },
  }),
});

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            {AppRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<AppRoute {...route} />}
                key={idx}
              />
            ))}
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
