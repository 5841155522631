import {
  Row,
  Col,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const LOREM =
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem vitae, voluptatum aspernatur sed nisi voluptatem magni deleniti ratione eaque expedita, cupiditate unde neque quas voluptas iure illum. At, id earum!";

interface Iprops {
  guest_name: string;
  guest_email: string;
  guest_address: string | null;
  guest_mobile_no: string;
  guest_special_request: string | null;
}

export default function GuestDetails(props: Iprops) {
  return (
    <Row className="my-3">
      <Col>
        <Card className="pt-4 pb-2 px-3 rounded-2">
          <Table striped bordered hover size="sm" responsive id="reservations">
            <thead>
              <tr>
                <th className="text-center" colSpan={4}>
                  Guest Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Name</th>
                <td>{props.guest_name}</td>
                <th>Email</th>
                <td>{props.guest_email}</td>
              </tr>

              <tr>
                <th>Phone</th>
                <td>{props.guest_mobile_no}</td>
                {props.guest_address ? (
                  <>
                    <th>Address</th>
                    <td>{props.guest_address}</td>
                  </>
                ) : null}
              </tr>
              {props.guest_special_request ? (
                <tr>
                  <th colSpan={2}>Special Request</th>
                  <td colSpan={2}>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">{LOREM}</Tooltip>}
                    >
                      <span className="d-inline-block">
                        {LOREM.length > 30 ? `${LOREM.slice(0, 27)}...` : LOREM}
                      </span>
                    </OverlayTrigger>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
}
