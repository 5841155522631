import { format } from "date-fns";
import { Card, Table } from "react-bootstrap";

interface IProps {
  reservation_id: number;
  property_name: string;
  status: string;
  check_in: string;
  check_out: string;
  created_at: Date;
  number_of_nights: number;
  booking_source: string;
  cancelation_at: Date;
  b2b_created_user: string | null;
  extranet_cancelation_user: string;
}

export default function ReservationDetails(props: IProps) {
  return (
    <Card className="pt-4 pb-2 px-3 rounded-2 my-3">
      <Table striped bordered hover size="sm" responsive id="reservations">
        <thead>
          <tr>
            <th className="text-center" colSpan={4}>
              Reservation Details
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Reservation Id</th>
            <td>{props.reservation_id}</td>

            <th>Property Name</th>
            <td>{props.property_name}</td>
          </tr>

          <tr>
            <th>Status</th>
            <td>{props.status}</td>

            <th>Check In</th>
            <td>{format(new Date(props.check_in), "dd MMM yyyy")}</td>
          </tr>

          <tr>
            <th>Total Nights</th>
            <td>{props.number_of_nights} stay</td>

            <th>Check Out</th>
            <td>{format(new Date(props.check_out), "dd MMM yyyy")}</td>
          </tr>

          <tr>
            <th>Booking Source</th>
            <td>{props.booking_source}</td>
          </tr>
          <tr>
            {props.b2b_created_user ? (
              <>
                <th>Created By</th>
                <td>{props.b2b_created_user}</td>
              </>
            ) : null}
            {props.created_at ? (
              <>
                <th>Creation Time</th>
                <td>
                  {format(
                    new Date(props.created_at),
                    "dd MMM yyyy hh:mm:ss aa"
                  )}
                </td>
              </>
            ) : null}
          </tr>
          <tr>
            {props.extranet_cancelation_user ? (
              <>
                <th>Canceled By</th>
                <td>{props.extranet_cancelation_user}</td>
              </>
            ) : null}

            {props.cancelation_at ? (
              <>
                <th>Cancelation Time</th>
                <td>
                  {format(
                    new Date(props.cancelation_at),
                    "dd MMM yyyy hh:mm:ss aa"
                  )}
                </td>
              </>
            ) : null}
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
