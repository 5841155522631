import React from "react";
import BadRequestImg from "../../../assets/img/bad_request.svg";
import { Col, Container, Image, Row } from "react-bootstrap";

function BadRequest() {
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center my-4">
          <Col lg="6" className="text-center">
            <Image src={BadRequestImg} />
            <p className="cm-lead my-4">
              Oops! Looks like you followed a bad link. Let’s get you back on
              track.
            </p>
            <a className="btn btn-primary" href="/">
              Go back home
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BadRequest;
