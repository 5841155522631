import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  rooms_details: {
    id: number;
    name: string;
    quantity: number;
    pricing: {
      nightly: {
        date: string;
        net_rate: string;
        vat: string;
        service_charge: string;
      }[];
      total_nights: { net_rate: string; vat: string; service_charge: string };
    };
    cancellation_policy: {
      cancellation_period: string;
      cancellation_penalty: string;
    };
  }[];
  sub_total_rate: number;
  total_service_charge: number;
  total_vat: number;
  grand_total_rate: number;
}

export default function PriceDetails(props: Props) {
  return (
    <Row>
      <Col>
        <Card className="p-4 my-3">
          <h5 className="fw-bold pb-2">Price details</h5>
          {props.rooms_details.map((item, key: React.Key) => (
            <Row className="pb-3 px-2" key={key}>
              <Col lg={1} xs={1} sm={1} className="px-2">
                {item.quantity}
              </Col>
              <Col lg={1} xs={1} sm={1} className="px-2">
                x
              </Col>
              <Col lg={5} xs={5} sm={5} className="px-2">
                {item.name}
              </Col>
              <Col lg={5} xs={5} sm={5} className="text-end px-2">
                ৳ {item.pricing.total_nights.net_rate}
              </Col>
            </Row>
          ))}
          <Row className="py-2 fw-bold border-top">
            <Col>Sub Total</Col>
            <Col className="text-end">৳ {props.sub_total_rate}</Col>
          </Row>

          {props.total_service_charge !== 0 ? (
            <Row className="py-2 font_14">
              <Col lg={6} xs={6}>
                Service Charge
              </Col>
              <Col lg={6} xs={6} className="text-end">
                <FontAwesomeIcon icon={faPlusCircle} /> ৳{" "}
                {props.total_service_charge}
              </Col>
            </Row>
          ) : null}

          {props.total_vat !== 0 ? (
            <Row className="py-2 font_14">
              <Col lg={6} xs={6}>
                VAT
              </Col>
              <Col lg={6} xs={6} className="text-end">
                <FontAwesomeIcon icon={faPlusCircle} /> ৳ {props.total_vat}
              </Col>
            </Row>
          ) : null}

          <Row className="py-3 fw-bolder border-top">
            <Col>Grand Total</Col>
            <Col className="text-end">৳ {props.grand_total_rate}</Col>
          </Row>

          <p className="font_12 text-muted">
            Taxes and Fees due at the property are based on current exchange
            rates, and are payable in local currency.
          </p>
          <p className="font_12 text-muted m-0">
            Rates are quoted in <b>BDT</b>
          </p>
        </Card>
      </Col>
    </Row>
  );
}
