import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ICancellationPolicy } from "./usePropertyRooms";

interface Props {
  cancellation_policy: ICancellationPolicy;
}

export default function CancellationPolicy(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        variant="link"
        className={`text-decoration-none btn-outline-light p-0 ${
          props.cancellation_policy.policy === "Non-refundable"
            ? "text-warning"
            : "text-success"
        }`}
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faInfoCircle} />{" "}
        {props.cancellation_policy.policy}
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancellation policies</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p
            className={
              props.cancellation_policy.policy === "Non-refundable"
                ? "text-warning"
                : "text-success"
            }
          >
            {props.cancellation_policy.policy}
          </p>
          {props.cancellation_policy.policy !== "Non-refundable" ? (
            <p>{props.cancellation_policy.details}</p>
          ) : null}
          <p>{props.cancellation_policy.penalty}</p>
          {props.cancellation_policy.policy !== "Non-refundable" ? (
            <p>Times are based on the property's local time.</p>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
