import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import PageNotFoundImg from "../../../assets/img/not_found404.svg";

function PageNotFound() {
  return (
    <Container>
      <Row className="d-flex justify-content-center align-items-center my-4">
        <Col lg="5" className="text-center">
          <Image src={PageNotFoundImg} />
          <h3 className="mt-4 mb-2 fw-light">
            Page <span className="fw-bold ">Not Found</span>
          </h3>
          <p className="cm-lead mt-2 mb-4">
            It happens! Let’s get you back on track.
          </p>
          <a className="btn btn-primary" href="/">
            Go back home
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default PageNotFound;
