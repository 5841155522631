import React from "react";
import { Col, Form, FormControlProps } from "react-bootstrap";
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
  UnpackNestedValue,
  useController,
} from "react-hook-form";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends FormControlProps {
  xs?: string | number;
  lg?: string | number;
  md?: string | number;
  sm?: string | number;
  label?: string;
  options: any[];
  className?: string;
  label_class?: string;
  group_class?: string;
  invalid?: string;
  loading?: boolean;
  selectOption?: string;
  children?: React.ReactElement;
  name: TName;
  control: Control<TFieldValues>;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  defaultValue?: UnpackNestedValue<FieldPathValue<TFieldValues, TName>>;
}

function Select<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  lg,
  md,
  xs,
  sm,
  label,
  loading,
  selectOption,
  label_class,
  group_class,
  className,
  options,
  name,
  control,
  children,
  rules,
  defaultValue,
  ...rest
}: Props<TFieldValues, TName>) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue,
  });

  return (
    <Form.Group
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      as={Col}
      className={group_class}
    >
      {label ? <Form.Label className={label_class}>{label}</Form.Label> : null}
      <Form.Control
        as="select"
        className={`w-100 ${className ? className : "dropdown-toggle"}`}
        {...rest}
        {...field}
        isInvalid={invalid}
      >
        {selectOption ? <option value="">{selectOption}</option> : null}

        {!loading
          ? options.map(
              (
                option: {
                  id: string | number;
                  name: string | React.ReactChild;
                },
                key: React.Key
              ) => (
                <option key={key} value={option.id}>
                  {option.name}
                </option>
              )
            )
          : null}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>

      {children ? children : null}
    </Form.Group>
  );
}

export default Select;
