import React from "react";
import { useNavigate } from "react-router";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import ServerErrorImg from "../../../assets/img/server_error.svg";

function ServerError() {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center my-4">
          <Col lg="6" className="text-center">
            <Image src={ServerErrorImg} />
            <h3 className="my-2 fw-light">
              Internal <span className="fw-bold ">Server Error</span>
            </h3>
            <p className="cm-lead my-t mb-4">
              Oops! Something has gone seriously wrong
            </p>
            <Button variant="primary" onClick={() => navigate(-1)}>
              Try Again
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ServerError;
