import { NavigateFunction } from "react-router-dom";
import { Toast } from ".";
import { IFetchError } from "../types";
import getSessionCookie from "./getSessionCookie";

interface Props {
  method: string;
  url: string;
  abortController?: AbortController;
  body?: any;
  multipart?: boolean;
  navigate: NavigateFunction;
}

interface IResult<T> {
  data: T;
  message: string;
  status: string;
}

const Fetch = <TResponseData>({
  method,
  url,
  body,
  multipart,
  abortController,
  navigate,
}: Props): Promise<IResult<TResponseData>> => {
  const auth_token = getSessionCookie("token");

  // fetch options
  const options: RequestInit = {
    method: method,
    headers: { Authorization: `Bearer ${auth_token}` },
  };

  // if abortController available
  if (abortController) {
    options.signal = abortController.signal;
  }

  // if multipart form data available
  if (multipart) {
    options.body = body;
  } else {
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json",
    };
    options.body = JSON.stringify(body);
  }

  let response: Response;

  return fetch(url, options)
    .then((res) => {
      if (process.env.NODE_ENV === "development") {
        console.log(res);
      }

      response = res;

      return res.json();
    })
    .then((data) => {
      if (!response.ok) {
        // eslint-disable-next-line no-throw-literal
        throw { ...data, statusCode: response.status }; // promise reject instead of throw
      }

      if (process.env.NODE_ENV === "development") {
        console.log(data);
      }

      return data;
    })
    .catch((error: IFetchError) => {
      if (process.env.NODE_ENV === "development") {
        console.log(error);
      }

      if (!window.navigator.onLine) {
        Toast.fire({
          icon: "error",
          title: "You're currently offline.",
          position: "bottom-left",
          showCloseButton: true,
        });
        throw error;
      } else {
        //  is abortController available

        if (abortController) {
          // if fetch is not aborted by abortController then handle error
          if (!abortController.signal.aborted) {
            throw ErrorHandler({ error, navigate });
          }
        } else {
          throw ErrorHandler({ error, navigate });
        }
      }
    });
};

const ErrorHandler = ({
  error,
  navigate,
}: {
  error: IFetchError;
  navigate: NavigateFunction;
}) => {
  switch (error.statusCode) {
    case 400:
      throw error;
    case 403:
      navigate("/access-denied");
      break;
    case 401:
      navigate("/");
      break;
    case 404:
      navigate("/404");
      break;
    case 500:
      Toast.fire({
        icon: "error",
        title: "Internal Server Error!",
        position: "bottom-left",
        showCloseButton: true,
      });
      throw error;
    default:
      Toast.fire({
        icon: "error",
        title: "Something has gone seriously wrong.",
        position: "bottom-left",
        showCloseButton: true,
      });
      throw error;
  }
};

export default Fetch;
