import React, { Dispatch, SetStateAction } from "react";
import { Col, Form, Button, Modal, Row } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IFetchError } from "../../../types";
import { Toast } from "../../../utils";
import Fetch from "../../../utils/fetch";

interface IProps {
  user_id: number;
  show: boolean;
  onHide: Dispatch<SetStateAction<boolean>>;
}

interface IPermissions {
  module: string;
  user_permissions: {
    id: number;
    status: boolean;
    name: string;
    display_name: string;
  }[];
}

export default function AssignPermissions(props: IProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const user_permissions = useQuery(
    ["user_permissions", props.user_id],
    async () =>
      await Fetch<IPermissions[]>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/user/module_permissions/${props.user_id}`,
        navigate,
      })
  );

  if (user_permissions.isError) {
    Toast.fire({
      icon: "error",
      title: (user_permissions.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  const { mutate, isLoading }: any = useMutation(
    async (permissions) =>
      await Fetch({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/user/assign_permissions`,
        body: {
          permissions,
        },
        navigate,
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user_permissions", props.user_id]);
        Toast.fire({
          icon: "success",
          title: data.message,
          timer: 5000,
          timerProgressBar: true,
          showCloseButton: true,
        });

        props.onHide(false);
      },
      onError: (error: IFetchError) => {
        Toast.fire({
          icon: "error",
          title: error?.message,
          text: error.reason,
          position: "bottom-left",
          showCloseButton: true,
          timer: 10000,
          timerProgressBar: true,
        });
      },
    }
  );

  const handleAssignPermissions = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const permissions: { id: any; status: any }[] = [];

    document
      .querySelectorAll(".user_permission_checkbox input[type=checkbox]")
      .forEach((checkbox) => {
        permissions.push({
          id: (checkbox as HTMLInputElement).value,
          status: (checkbox as HTMLInputElement).checked,
        });
      });

    mutate(permissions);
  };

  const selectAllPermissions = (id: any) => {
    document
      .querySelectorAll(`#${id} input[type=checkbox]`)
      .forEach((checkbox) => {
        (checkbox as HTMLInputElement).checked = !(checkbox as HTMLInputElement)
          .checked;
      });
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onHide(!props.show)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Assign User Permissions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="m-3"
            id="assign_user_permission"
            onSubmit={handleAssignPermissions}
          >
            {!user_permissions.isLoading ? (
              <Row>
                {user_permissions.data?.data.map(
                  (
                    permission: {
                      module: string;
                      user_permissions: {
                        id: number;
                        status: boolean;
                        name: string;
                        display_name: string;
                      }[];
                    },
                    key: React.Key
                  ) => (
                    <Form.Group lg="4" as={Col} key={key} className="mb-3">
                      <h6 className="element-header">
                        <Form.Check
                          id={permission.module.trim().split(" ").join("--")}
                          type="switch"
                          label={permission.module}
                          onClick={() =>
                            selectAllPermissions(
                              permission.module
                                .trim()
                                .split(" ")
                                .join("_")
                                .toLowerCase()
                            )
                          }
                        />
                      </h6>
                      <div
                        id={permission.module
                          .trim()
                          .split(" ")
                          .join("_")
                          .toLowerCase()}
                      >
                        {permission.user_permissions.map(
                          (
                            permission: {
                              id: number;
                              status: boolean;
                              name: string;
                              display_name: string;
                            },
                            key: React.Key
                          ) => (
                            <Form.Group
                              as={Col}
                              className="mb-3 ms-2"
                              key={key}
                            >
                              <Form.Check
                                key={permission.id}
                                className="user_permission_checkbox"
                                id={`user_permission_${permission.id}`}
                                type="checkbox"
                                label={permission.display_name}
                                value={permission.id}
                                defaultChecked={permission.status}
                              />
                            </Form.Group>
                          )
                        )}
                      </div>
                    </Form.Group>
                  )
                )}
              </Row>
            ) : (
              <ContentLoader
                width="100%"
                height={200}
                backgroundColor="#E1E9EE"
                foregroundColor="#F2F8FC"
                className="p-3"
              >
                {/* Col 1 */}
                <rect x="0" y="0" rx="6" ry="6" width="30%" height="16" />

                <rect x="0" y="30" rx="6" ry="6" width="3%" height="14" />
                <rect x="5%" y="30" rx="6" ry="6" width="22%" height="14" />

                <rect x="0" y="50" rx="6" ry="6" width="3%" height="14" />
                <rect x="5%" y="50" rx="6" ry="6" width="22%" height="14" />

                <rect x="0" y="70" rx="6" ry="6" width="3%" height="14" />
                <rect x="5%" y="70" rx="6" ry="6" width="22%" height="14" />

                {/* Col 2 */}

                <rect x="33%" y="0" rx="6" ry="6" width="30%" height="16" />

                <rect x="33%" y="30" rx="6" ry="6" width="3%" height="14" />
                <rect x="38%" y="30" rx="6" ry="6" width="22%" height="14" />

                <rect x="33%" y="50" rx="6" ry="6" width="3%" height="14" />
                <rect x="38%" y="50" rx="6" ry="6" width="22%" height="14" />

                <rect x="33%" y="70" rx="6" ry="6" width="3%" height="14" />
                <rect x="38%" y="70" rx="6" ry="6" width="22%" height="14" />

                {/* Col 3 */}

                <rect x="66%" y="0" rx="6" ry="6" width="30%" height="16" />

                <rect x="66%" y="30" rx="6" ry="6" width="3%" height="14" />
                <rect x="71%" y="30" rx="6" ry="6" width="22%" height="14" />

                <rect x="66%" y="50" rx="6" ry="6" width="3%" height="14" />
                <rect x="71%" y="50" rx="6" ry="6" width="22%" height="14" />

                <rect x="66%" y="70" rx="6" ry="6" width="3%" height="14" />
                <rect x="71%" y="70" rx="6" ry="6" width="22%" height="14" />
              </ContentLoader>
            )}

            <Row>
              <Form.Group className="py-2" lg={3} as={Col}>
                <Button className="w-100" type="submit">
                  {isLoading ? "Submitting.." : "Submit"}
                </Button>
              </Form.Group>
              <Form.Group className="py-2" lg={3} as={Col}>
                <Button
                  variant="danger"
                  className="w-100"
                  onClick={() => props.onHide(!props.show)}
                >
                  Close
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
