import { Modal, Form, Row, Table } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import InputButton from "../../../../../components/form-elements/Button";
import Input from "../../../../../components/form-elements/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { format } from "date-fns";
import CheckBox from "../../../../../components/form-elements/CheckBox";
import CompanyDetails from "../company_details";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Fetch from "../../../../../utils/fetch";
import { Ib2b_agent } from "../../types";
import PrintRoomDetails from "./roomDetails";
import CancellationPolicy from "./CancellationPolicy";
import { IFetchError } from "../../../../../types";
import { Toast } from "../../../../../utils";
import { TableLoader } from "../../../../../components/Loaders";
import ReservationRefundDetails from "../refund_details";

interface IProps {
  show: boolean;
  onHide: () => void;
}

interface IReservationDetails {
  property_reservation: {
    property_name: string;
    booking_source: string;

    guest_name: string;
    guest_email: string;
    guest_mobile_no: string;
    guest_address: string | null;
    guest_special_request: string | null;

    check_in: string;
    check_out: string;
    number_of_nights: number;
    status: string;
    sub_total_rate: number;
    total_vat: number;
    total_service_charge: number;
    total_rate: number;

    created_at: Date;
    cancelation_at: Date;

    b2b_created_user: string;
    extranet_cancelation_user: string;
    cancelation_note: string;
  };

  property_reservation_details: {
    room_name: string;
    quantity: number;
    rate: number;
    cancellation_penalty: string;
    cancellation_period: string;
    occupancy: number;
  }[];

  property_reservation_payment: {
    total_paid: number;
  };
  b2b_agent: Ib2b_agent;
  property_reservation_refund: {
    total_gross_refund: number;
    rooms: {
      room_name: string;
      quantity: number;
      gross_refund: number;
    }[];
  };
}

export default function PrintModal(props: IProps) {
  const printRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const reservationId = Number(params.reservation_id);
  const { control, handleSubmit, watch } = useForm();

  const { data, isLoading, isSuccess, isError, error } = useQuery(
    ["reservation_details_print", reservationId],
    async () =>
      await Fetch<IReservationDetails>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/booking/property/print/reservation/${reservationId}`,
        navigate,
      })
  );

  if (isError) {
    Toast.fire({
      icon: "error",
      title: (error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  const printHandler: SubmitHandler<any> = () => {
    print();
  };

  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: "p-5",
  });

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Print reservation details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <>
            {[1, 2].map(() => (
              <Table className="mb-5">
                <TableLoader columnLength={4} />
              </Table>
            ))}
          </>
        ) : isSuccess ? (
          <>
            <Form
              className="form-horizontal"
              onSubmit={handleSubmit(printHandler)}
            >
              <Row>
                <CheckBox
                  // md={3}
                  className="mb-3"
                  control={control}
                  name="show_company_details"
                  type="checkbox"
                  label="Attach Company Information"
                />

                <CheckBox
                  // md={3}
                  className="mb-3"
                  control={control}
                  name="custom_room_price_show"
                  type="checkbox"
                  label="Apply Custom Price"
                />
              </Row>
              {watch("custom_room_price_show") && (
                <Row className="bg-info bg-opacity-25 py-2 mb-2 align-items-baseline">
                  <h5>Custom Pricing</h5>
                  {data.data.property_reservation_details.map((room, index) => {
                    return (
                      <Input
                        key={index}
                        md={3}
                        label={`${room.room_name} Price`}
                        name={`avg_rates.${index}`}
                        type="number"
                        className="mb-3"
                        placeholder="Room Price"
                        aria-autocomplete="none"
                        control={control}
                        defaultValue={
                          Number(room.rate) /
                          data.data.property_reservation.number_of_nights
                        }
                      />
                    );
                  })}

                  <Input
                    step="0.1"
                    md={3}
                    label="Service Charge"
                    name="service_charge"
                    type="number"
                    className="mb-3"
                    placeholder="Service charge"
                    aria-autocomplete="none"
                    control={control}
                    defaultValue={
                      data.data.property_reservation.total_service_charge
                    }
                  />
                  <Input
                    md={3}
                    label="Vat"
                    name="vat"
                    type="number"
                    className="mb-3"
                    placeholder="vat"
                    aria-autocomplete="none"
                    control={control}
                    defaultValue={data.data.property_reservation.total_vat}
                  />
                </Row>
              )}

              <Row>
                <InputButton
                  icon={<FontAwesomeIcon icon={faPrint} fontSize={22} />}
                  label="Print"
                  type="submit"
                  variant="primary"
                  label_class="mx-2"
                  className="w-100 mb-3 rounded-1 py-2 d-flex align-items-center justify-content-center"
                />
              </Row>
            </Form>

            <div ref={printRef} className="position-relative">
              <div className="position-absolute opacity-25 w-100 h-100 d-flex align-items-center justify-content-center fa-10x">
                <span style={{ transform: "rotate(-45deg)" }}>Paid</span>
              </div>
              <div>
                {watch("show_company_details") ? (
                  <CompanyDetails
                    property_name={data.data.property_reservation.property_name}
                    b2b_agent={data.data.b2b_agent}
                    created_at={data.data.property_reservation.created_at}
                    status={data.data.property_reservation.status}
                  />
                ) : null}

                <Table
                  bordered
                  size="sm"
                  responsive
                  className="border-dark mb-4"
                >
                  <thead>
                    <tr>
                      <th className="text-start p-2 font_20" colSpan={4}>
                        Reservation Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Reservation Id</th>
                      <td>{reservationId}</td>

                      <th>Check In</th>
                      <td>
                        {format(
                          new Date(data.data.property_reservation.check_in),
                          "dd MMM yyyy"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th>Total Nights</th>
                      <td>{data.data.property_reservation.number_of_nights}</td>

                      <th>Check Out</th>
                      <td>
                        {format(
                          new Date(data.data.property_reservation.check_out),
                          "dd MMM yyyy"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th>No of Rooms</th>
                      <td>
                        {data.data.property_reservation_details.reduce(
                          (a, b) => {
                            return a + b.quantity;
                          },
                          0
                        )}
                      </td>

                      {data.data.property_reservation.created_at ? (
                        <>
                          <th>Creation Time</th>
                          <td>
                            {format(
                              new Date(
                                data.data.property_reservation.created_at
                              ),
                              "dd MMM yyyy hh:mm:ss aa"
                            )}
                          </td>
                        </>
                      ) : null}
                    </tr>
                    <tr>
                      <th>No of Guest</th>
                      <td>
                        {data.data.property_reservation_details.reduce(
                          (a, b) => {
                            return a + b.occupancy;
                          },
                          0
                        )}
                      </td>
                      <th>Booking Source</th>
                      <td>{data.data.property_reservation.booking_source}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{data.data.property_reservation.status}</td>
                    </tr>
                  </tbody>
                </Table>

                <Table
                  bordered
                  size="sm"
                  responsive
                  className="border-dark mb-4"
                >
                  <thead>
                    <tr>
                      <th className="text-start p-2 font_20" colSpan={4}>
                        Guest Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{data.data.property_reservation.guest_name}</td>
                      <th>Email</th>
                      <td>{data.data.property_reservation.guest_email}</td>
                    </tr>

                    <tr>
                      <th>Phone</th>
                      <td>{data.data.property_reservation.guest_mobile_no}</td>
                      {data.data.property_reservation.guest_address ? (
                        <>
                          <th>Address</th>
                          <td>
                            {data.data.property_reservation.guest_address}
                          </td>
                        </>
                      ) : null}
                    </tr>
                    {data.data.property_reservation.guest_special_request ? (
                      <tr>
                        <th>Special Request</th>
                        <td colSpan={3}>
                          {data.data.property_reservation.guest_special_request}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>

                <PrintRoomDetails
                  newRoomRate={watch("avg_rates")}
                  total_vat={
                    watch("vat")
                      ? watch("vat")
                      : data.data.property_reservation.total_vat
                  }
                  total_service_charge={
                    watch("service_charge")
                      ? watch("service_charge")
                      : data.data.property_reservation.total_service_charge
                  }
                  sub_total_rate={data.data.property_reservation.sub_total_rate}
                  total_paid={data.data.property_reservation_payment.total_paid}
                  total_rate={data.data.property_reservation.total_rate}
                  poperty_reservation_details={
                    data.data.property_reservation_details
                  }
                  number_of_nights={
                    data.data.property_reservation.number_of_nights
                  }
                />

                {data.data.property_reservation_refund.rooms.length !== 0 ? (
                  <Table
                    bordered
                    size="sm"
                    responsive
                    className="border-dark mb-4"
                  >
                    <thead>
                      <tr>
                        <th className="text-start p-2 font_20" colSpan={3}>
                          Refund Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <th>Room Category</th>
                        <th># Rooms</th>
                        <th>Total (BDT)</th>
                      </tr>
                      {data.data.property_reservation_refund.rooms.map(
                        (room, index) => {
                          return (
                            <tr key={index}>
                              <td>{room.room_name}</td>
                              <td className="text-center">{room.quantity}</td>
                              <td className="text-end">
                                {room.gross_refund.toFixed(2)}
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <tr>
                        <th className="text-center" colSpan={2}>
                          Total Refund
                        </th>
                        <td className="text-end">
                          {data.data.property_reservation_refund.total_gross_refund.toFixed(
                            2
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : null}

                <Table
                  bordered
                  size="sm"
                  responsive
                  className="border-dark mb-4"
                >
                  <thead>
                    <tr>
                      <th className="text-start p-2">Cancenation Policy</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <CancellationPolicy
                        cancellation_penalty={
                          data.data.property_reservation_details[0]
                            .cancellation_penalty
                        }
                        cancellation_period={
                          data.data.property_reservation_details[0]
                            .cancellation_period
                        }
                        check_in={data.data.property_reservation.check_in}
                      />
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="border-top border-dark text-center py-2 print_footer position-fixed bottom-0 ">
                This is system generated copy Print Date & Time:{" "}
                {format(new Date(), "dd MMM yyyy hh:mm:ss aa")}
              </div>
            </div>
          </>
        ) : (
          <h4>Data not found!</h4>
        )}
      </Modal.Body>
    </Modal>
  );
}
