import { Card, Container, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { TableLoader } from "../../../../components/Loaders";
import { IFetchError } from "../../../../types";
import { Toast } from "../../../../utils";
import Fetch from "../../../../utils/fetch";
import DetailsTable from "./detailsTable";

export interface IPaymentDetails {
  gateway_type: string;
  order_id: string;
  amount: string;
  currency: string;
  status: string;
  payment_ref_id: string | null;
  transaction_id: string | null;
  client_mobile_no: string | null;
  deposited_from_bank: string;
  bank_branch: string | null;
  bank_session_id: string;
  bank_order_id: string;
  bank_pan: string | null;
  gateway_service_charge: number;
  processing_fee: number;
  gateway_trx_status: string;
  gateway_trx_reason: string | null;
  order_datetime: string;
  payment_datetime: Date | null;
  deposited_to_account: string;
  refund_request: string;
  note: string | null;
  cilent_ip: string;
  document_of_proof: string | null;
  created_name: string;
  updated_name: string;
  created_at: string;
  updated_at: Date | null;
  deposited_to_account_name: string;
  deposited_to_account_number: string;
}

export default function PaymentDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const paymentId = params.payment_id;

  const payment_details = useQuery(
    ["payment_details", paymentId],
    async () =>
      await Fetch<IPaymentDetails>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/wallet/payment/details/${paymentId}`,
        navigate,
      })
  );

  if (payment_details.isError) {
    Toast.fire({
      icon: "error",
      title: (payment_details.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  return (
    <Container>
      <Card className="rounded-2 p-3 mt-3">
        <h3 className="mb-4">Payment Details</h3>
        {payment_details.isLoading ? (
          <Table>
            <TableLoader rowLength={3} columnLength={2} />
          </Table>
        ) : payment_details.isSuccess ? (
          <DetailsTable data={payment_details.data.data} />
        ) : (
          <h5>Data not found!</h5>
        )}
      </Card>
    </Container>
  );
}
