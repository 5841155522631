import { format } from "date-fns";
import { Badge, Table } from "react-bootstrap";
import { IPaymentDetails } from "./paymentDetails";

interface IProps {
  data: IPaymentDetails;
}

export default function DetailsTable({ data }: IProps) {
  const generateStatusBadge = (status: string) => {
    let status_badge = "badge";

    status === "succeeded"
      ? (status_badge += " bg-success")
      : status === "initiated"
      ? (status_badge += " bg-warning")
      : (status_badge += " bg-danger");

    return status_badge;
  };
  return (
    <Table striped bordered hover>
      <tbody>
        <tr>
          <th>Gateway Type</th>
          <td>{data.gateway_type}</td>
        </tr>
        <tr>
          <th>Order Id</th>
          <td>{data.order_id}</td>
        </tr>
        {data.transaction_id ? (
          <tr>
            <th>Transaction Id</th>
            <td>{data.transaction_id}</td>
          </tr>
        ) : null}
        {data.payment_ref_id ? (
          <tr>
            <th>Reference Number</th>
            <td>{data.payment_ref_id}</td>
          </tr>
        ) : null}
        <tr>
          <th>Payment Status</th>
          <td>
            <Badge bg={generateStatusBadge(data.status)}>
              {data.status === "initiated" &&
              data.gateway_type.includes("Manual")
                ? "Pending For Verify"
                : data.status}
            </Badge>
          </td>
        </tr>
        <tr>
          <th>Payment Amount</th>
          <td>
            {data.amount} {data.currency}
          </td>
        </tr>
        {data.gateway_service_charge ? (
          <tr>
            <th>Gateway service charge</th>
            <td>{data.gateway_service_charge} %</td>
          </tr>
        ) : null}
        {data.processing_fee ? (
          <tr>
            <th>Processing Fee</th>
            <td>{data.processing_fee}</td>
          </tr>
        ) : null}
        {data.processing_fee ? (
          <tr>
            <th>Top UP Amount</th>
            <td>
              {Number(data.amount) - Number(data.processing_fee)}{" "}
              {data.currency}
            </td>
          </tr>
        ) : null}
        <tr>
          <th>Gateway trx status</th>
          <td>{data.gateway_trx_status}</td>
        </tr>

        {data.gateway_trx_reason ? (
          <tr>
            <th>Gateway trx reason</th>
            <td>{data.gateway_trx_reason}</td>
          </tr>
        ) : null}
        {data.order_datetime ? (
          <tr>
            <th>Order DateTime</th>
            <td>
              {format(new Date(data.order_datetime), "dd MMM yyyy hh:mm:ss aa")}
            </td>
          </tr>
        ) : null}
        {data.payment_datetime ? (
          <tr>
            <th>Payment DateTime</th>
            <td>
              {format(
                new Date(data.payment_datetime),
                "dd MMM yyyy hh:mm:ss aa"
              )}
            </td>
          </tr>
        ) : null}
        {data.client_mobile_no ? (
          <tr>
            <th>Client Mobile No</th>
            <td>{data.client_mobile_no}</td>
          </tr>
        ) : null}
        {data.bank_pan ? (
          <tr>
            <th>Card Number</th>
            <td>{data.bank_pan}</td>
          </tr>
        ) : null}
        <tr>
          <th>Client IP</th>
          <td>{data.cilent_ip}</td>
        </tr>
        <tr>
          <th>Refund Request</th>
          <td>{data.refund_request}</td>
        </tr>
        {data.note ? (
          <tr>
            <th>Note</th>
            <td>{data.note}</td>
          </tr>
        ) : null}
        <tr>
          <th>Deposited From Account</th>
          <td>{data.deposited_from_bank}</td>
        </tr>
        <tr>
          <th>Deposited From Branch</th>
          <td>{data.bank_branch}</td>
        </tr>
        <tr>
          <th>Deposited To Account Name</th>
          <td>{data.deposited_to_account_name}</td>
        </tr>
        <tr>
          <th>Deposited To Account Number</th>
          <td>{data.deposited_to_account_number}</td>
        </tr>
        {data.created_at ? (
          <tr>
            <th>Created DateTime</th>
            <td>
              {format(new Date(data.created_at), "dd MMM yyyy hh:mm:ss aa")}
            </td>
          </tr>
        ) : null}
        {data.created_name ? (
          <tr>
            <th>Created Name</th>
            <td>{data.created_name}</td>
          </tr>
        ) : null}
        {data.updated_at ? (
          <tr>
            <th>Updated DateTime</th>
            <td>
              {format(new Date(data.updated_at), "dd MMM yyyy hh:mm:ss aa")}
            </td>
          </tr>
        ) : null}
        {data.updated_name ? (
          <tr>
            <th>Updated Name</th>
            <td>{data.updated_name}</td>
          </tr>
        ) : null}
        {data.document_of_proof ? (
          <tr>
            <th>Document of proof</th>
            <td>
              <a href={data.document_of_proof} target="_blank" rel="noreferrer">
                view document
              </a>
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
}
