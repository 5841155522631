import React from "react";
import NavBar from "../components/layout/protected/NavBar";
import RequireAuth from "./RequireAuth";
import RequirePermission from "./RequirePermission";

type Props =
  | {
      title: string;
      path: string;
      Layout: (props: { children: JSX.Element }) => JSX.Element;
      Component: (props: any) => JSX.Element;
      isAuthProtected: boolean;
      module: string;
    }
  | {
      title: string;
      path: string;
      Layout: (props: { children: JSX.Element }) => JSX.Element;
      Component: (props: any) => JSX.Element;
      isAuthProtected: boolean;
      module?: undefined;
    };

function AppRoute({
  Component,
  Layout,
  isAuthProtected,
  module,
  title,
}: Props) {
  document.title = `${title} | RNR Rooms B2B`;

  if (isAuthProtected) {
    return (
      <RequireAuth>
        <RequirePermission module={module!}>
          <Layout>
            <>
              <NavBar />
              <Component />
            </>
          </Layout>
        </RequirePermission>
      </RequireAuth>
    );
  } else {
    return (
      <Layout>
        <>
          <Component />
        </>
      </Layout>
    );
  }
}
export default AppRoute;
