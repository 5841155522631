import { differenceInDays, format, subDays } from "date-fns";
import { useEffect, useState } from "react";

interface IProps {
  cancellation_period: string;
  cancellation_penalty: string;
  check_in: string;
}

export default function CancellationPolicy(props: IProps) {
  const [cancellationPolicy, setCancellationPolicy] = useState<{
    policy: string;
    cancelDate?: string;
    cancellation_penalty: string;
  }>({
    policy: "Non-refundable",
    cancellation_penalty: props.cancellation_penalty,
  });
  useEffect(() => {
    const cancellationPolicies = [
      { subDays: 1, period: "24 Hours" },
      { subDays: 2, period: "48 Hours" },
      { subDays: 3, period: "72 Hours" },
      { subDays: 7, period: "7 Days" },
    ];

    cancellationPolicies.forEach((policy) => {
      if (
        props.cancellation_period === policy.period &&
        differenceInDays(new Date(props.check_in), new Date()) >= policy.subDays
      ) {
        const d = format(
          subDays(new Date(props.check_in), policy.subDays),
          "MMM dd"
        );
        setCancellationPolicy((prev) => {
          return {
            ...prev,
            policy: `Fully refundable before ${d}`,
            cancelDate: d,
          };
        });
      }
    });
  }, [props.cancellation_period, props.check_in]);

  return (
    <td className="p-2">
      {cancellationPolicy.policy !== "Non-refundable" ? (
        <>
          <p>
            <span className="text-success bg-success bg-opacity-10 p-1 rounded-2">
              {cancellationPolicy.policy}
            </span>{" "}
            <br />
            <span>
              Cancel your reservation before {cancellationPolicy.cancelDate} and
              you'll get a full refund.
            </span>
            <br />
            <span>
              After that, you'll be charged for the{" "}
              {cancellationPolicy.cancellation_penalty}
            </span>
          </p>
          <p className="mb-0">Times are based on the property’s local time.</p>
        </>
      ) : (
        <p>
          <span className="text-danger bg-danger bg-opacity-10 p-1 rounded-2">
            Non-refundable
          </span>{" "}
          <br />
          <br />
          <span>
            If you change or cancel your booking you will not get a refund or
            credit to use for a future stay.{" "}
          </span>
        </p>
      )}
    </td>
  );
}
