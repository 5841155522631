import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import AccessDeniedImg from "../../../assets/img/access_denied.svg";

function AccessDenied() {
  return (
    <Container>
      <Row className="d-flex justify-content-center align-items-center my-4">
        <Col lg="6" className="text-center">
          <Image src={AccessDeniedImg} />
          <p className="cm-lead my-4">
            Oops! You don't have permission to access these resource
          </p>
          <a className="btn btn-primary" href="/account/sign-in">
            Sign In
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default AccessDenied;
