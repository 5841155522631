import { format } from "date-fns";
import { Table } from "react-bootstrap";
import { Ib2b_agent } from "../types";

interface IProps {
  status: string;
  created_at: Date;
  b2b_agent: Ib2b_agent;
  property_name: string;
}

export default function CompanyDetails({
  b2b_agent,
  created_at,
  property_name,
}: IProps) {
  return (
    <Table size="sm" responsive className="border-dark mb-4">
      <tr>
        <td>
          <img
            src={
              process.env.REACT_APP_CDN_URL +
              btoa(
                JSON.stringify({
                  bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                  key: b2b_agent.logo,
                  edits: { resize: { width: 600 } },
                })
              )
            }
            alt="logo"
            style={{ maxHeight: "120px", maxWidth: "120px" }}
          />
        </td>
        <td>
          <p className="fw-bold mb-0">{b2b_agent.brand_name}</p>
          <address className="mb-0">
            {b2b_agent.address} {b2b_agent.thana}, {b2b_agent.district},{" "}
            {b2b_agent.division}-{b2b_agent.post_code}, {b2b_agent.country}
          </address>
          <span>{b2b_agent.mobile_no}</span>
        </td>
        <td>
          <span className="fw-bold bg-primary bg-opacity-25 rounded-2 ">
            {property_name}
          </span>

          <br />
          <span className="font_14">
            Booking on:{" "}
            <span className="fw-bold">
              {format(new Date(created_at), "dd MMM yyyy hh:mm:ss aa")}
            </span>
          </span>
        </td>
      </tr>
    </Table>
  );
}
