import React from "react";
import { Card, Container, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPrint } from "@fortawesome/free-solid-svg-icons";
// import InputButton from "../../../components/form-elements/Button";
// import Input from "../../../components/form-elements/Input";
// import { SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../utils/fetch";
import { TableLoader } from "../../../components/Loaders";

// interface IFormValues {
//   bank_name: string;
//   branch_name: string;
// }

interface IBank {
  id: number;
  name: string;
  branch_name: string | null;
  account_name: string;
  account_number: string;
  routing_number: string;
}

export default function BankList() {
  const navigate = useNavigate();
  // const { control, handleSubmit } = useForm<IFormValues>();

  const banklist = useQuery(
    ["banklist"],
    async () =>
      await Fetch<IBank[]>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/dropdowns/bank/deposit/accounts`,
        navigate,
      })
  );

  // const handleFilter: SubmitHandler<IFormValues> = (data) => {
  //   alert(JSON.stringify(data));
  //   console.log(data);
  // };

  return (
    <Container>
      {/* <Card className="p-3 mt-2 mt-md-3 mt-lg-4">
        <Form className="form-horizontal" onSubmit={handleSubmit(handleFilter)}>
          <Row className="mt-0 align-items-end">
            <Input
              md={4}
              label="Bank name"
              name="bank_name"
              type="text"
              className="mb-3"
              placeholder="Enter bank name"
              aria-autocomplete="none"
              control={control}
            />
            <Input
              md={4}
              label="Branch name"
              name="branch_name"
              type="text"
              className="mb-3"
              placeholder="Enter branch name"
              aria-autocomplete="none"
              control={control}
            />
            <InputButton
              md={4}
              label="Search"
              type="submit"
              variant="primary"
              className="w-100 mb-3"
            />
          </Row>
        </Form>
      </Card> */}
      <Card className="mt-4 mt-2 mt-md-3 mt-lg-4 p-2 py-lg-4 font_14 rounded-2">
        <div className="d-flex justify-content-between px-2 py-3 align-items-center">
          <h5 className="fw-bold mb-0">Bank List</h5>
          <div className="d-flex align-items-center">
            <button className="border-0 bg-white btn_link_effect">
              <FontAwesomeIcon icon={faFileExcel} className="fs-4" />
            </button>
            <button className="mx-1 mx-sm-2 mx-md-3 border-0 bg-white btn_link_effect">
              <FontAwesomeIcon icon={faPrint} className="fs-5" />
            </button>
          </div>
        </div>
        <Table
          responsive="lg"
          className="caption-top"
          bordered
          striped={!banklist.isLoading}
          hover={!banklist.isLoading}
        >
          <thead className="border-bottom">
            <tr>
              {/* <th>Logo</th> */}
              <th>Bank Name</th>
              <th>Branch Name</th>
              <th>Account NO.</th>
              <th>Account Name</th>
              <th>Routing NO.</th>
            </tr>
          </thead>
          {/* {banklist.isSuccess} */}
          <tbody>
            {banklist.isLoading ? (
              <TableLoader rowLength={3} columnLength={6} />
            ) : banklist.isSuccess ? (
              <>
                {banklist.data.data.map((bank) => {
                  return (
                    <tr className="my-1" key={bank.id}>
                      {/* <td className="fw-bold">missing</td> */}
                      <td>{bank.name}</td>
                      <td>{bank.branch_name}</td>
                      <td>{bank.account_number}</td>
                      <td>{bank.account_name}</td>
                      <td>{bank.routing_number}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <h4>Data not found!</h4>
            )}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
}
