import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export default function Footer() {
  return (
    <footer className="navbar-fixed-bottom">
      <Container className="mt-4">
        <Row className="border-top py-4 justify-content-between">
          <Col>
            <p className="text-center">
              © {new Date().getFullYear()} <span> RNR Rooms </span> All Rights
              Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
