import React from "react";
import Footer from "./Footer";

function ProtectedLayout(props: any) {
  return (
    <>
      {props.children}
      <Footer />
    </>
  );
}

export default ProtectedLayout;
