import React, { useEffect, useRef, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "../assets/scss/search.scss";
import Fetch from "../utils/fetch";
import { BulletList } from "react-content-loader";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Toast } from "../utils";

interface Props {
  lg?: number;
  sm?: number;
  searchDestination:
    | { destination_type: string; id: number; name: string }
    | undefined;
  setSearchDestination: (arg0: {
    destination_type: string;
    id: number;
    name: string;
  }) => void;
}

type SearchData = {
  destination_type: string;
  id: number;
  name: string;
  thana_name: string;
  district_name: string;
  division_name: string;
  country_name: string;
}[];

function SearchDestination(props: Props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const suggestionsRef = useRef<HTMLDivElement>(null);
  const searchDesRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState(
    props.searchDestination ? props.searchDestination.name : ""
  );

  const searchResult = useQuery(
    ["destinations", search],
    async () =>
      await Fetch<SearchData>({
        method: "POST",
        url:
          process.env.REACT_APP_BACKEND_URL +
          "/api/property/booking/search/destination",
        body: { search },
        navigate,
      }),
    { retry: false, enabled: search !== "" }
  );

  if (searchResult.isError) {
    Toast.fire({
      icon: "error",
      title: "Bad Request!",
      position: "bottom-left",
      showCloseButton: true,
      timer: 3000,
    });
  }

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [suggestionsRef]);

  return (
    <>
      <Form.Group
        lg={props.lg}
        sm={props.sm}
        as={Col}
        className="position-relative mb-3"
      >
        <Form.Label className="cm-form-label cursor-pointer">
          Going to
        </Form.Label>
        <Form.Control
          className="w-100 btn-cm-form"
          placeholder="Destination"
          autoComplete="off"
          type="text"
          onClick={() => {
            setIsOpen(true);
            setTimeout(() => {
              searchDesRef.current?.focus();
            }, 1);
          }}
          value={props.searchDestination ? props.searchDestination.name : ""}
          required
        />
        <span className="cm-form-icon cursor-pointer">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </span>
      </Form.Group>

      <div
        className={`s_suggestions_overlay ${isOpen ? "is-open" : ""}`}
        ref={suggestionsRef}
      >
        <Row className="px-3 pt-3 border-bottom s_suggestion_header">
          <Col>
            <Form.Control
              className="border-0 shadow-none"
              type="text"
              placeholder="Where are you going?"
              autoComplete="off"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              ref={searchDesRef}
              value={search}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <ul className="list-unstyled">
              {!searchResult.isLoading ? (
                searchResult.data ? (
                  searchResult.data.data.map((suggestion, key: React.Key) => (
                    <li
                      className="px-3 py-2 suggestion_item row align-items-center"
                      key={key}
                      onClick={() => {
                        props.setSearchDestination(suggestion);
                        setIsOpen(false);
                      }}
                    >
                      <Col xs="2" md="2" lg="2" className="text-center">
                        {suggestion.destination_type === "property" ? (
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="map-marker-alt"
                            className="svg-inline--fa fa-map-marker-alt fa-w-12 fa-1x"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            fill="#253678"
                          >
                            <path d="M64 481.8V.2h270v107.6h114v374h64v30H0v-30h64zm297-52.4h30v-42.7h-30v42.7zm0-72.7h30V314h-30v42.7zm0-72.7h30v-42.7h-30V284zm0-72.7h30v-42.7h-30v42.7zm-117 234h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82.1h30v-52h-30v52zm-60 379.6h30V393.3h-30v103.5zm0-133.5h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82.1h30v-52h-30v52zm-60 328.1h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82h30v-52h-30v52zm0-82.1h30v-52h-30v52z" />
                          </svg>
                        ) : (
                          <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" />
                        )}
                      </Col>

                      <Col xs="10" md="10" lg="10">
                        <p className="m-0 fw-bold">{suggestion.name}</p>
                        <p className="m-0">
                          {suggestion.destination_type === "property"
                            ? `${suggestion.thana_name}, ${suggestion.district_name}, `
                            : null}
                          {suggestion.destination_type === "thana"
                            ? `${suggestion.district_name}, `
                            : null}
                          {suggestion.division_name} Division,{" "}
                          {suggestion.country_name}
                        </p>
                      </Col>
                    </li>
                  ))
                ) : null
              ) : search !== "" ? (
                <BulletList className="p-3" />
              ) : null}
            </ul>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SearchDestination;
