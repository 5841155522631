// import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faBed,
//   faEnvelope,
//   faHashtag,
//   faPrint,
//   faUserPen,
//   faVanShuttle,
// } from "@fortawesome/free-solid-svg-icons";
import { Card, Container, Table } from "react-bootstrap";

// import CalenderPen from "../../../../assets/img/calender-pen.svg";
// import InputButton from "../../../../components/form-elements/Button";
// import GuestDetailsUpdateModal from "./modals/guestDetails";
// import SendMailModal from "./modals/sendMail";
// import PickupNoteModal from "./modals/pickupNote";
// import ConfirmationNoteModal from "./modals/confirmationNote";
// import DateModificationModal from "./modals/dateModification";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Fetch from "../../../../utils/fetch";
import GuestDetails from "./guest_details";
import ReservationDetails from "./reservation_details";
import ReservationRoomDetails from "./room_details";
import InputButton from "../../../../components/form-elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import PrintModal from "./modals/print";
import { IFetchError } from "../../../../types";
import { Toast } from "../../../../utils";
import { TableLoader } from "../../../../components/Loaders";
import ReservationRefundDetails from "./refund_details";

interface IReservationDetails {
  property_name: string;
  booking_source: string;

  guest_name: string;
  guest_email: string;
  guest_mobile_no: string;
  guest_address: string | null;
  guest_special_request: string | null;

  check_in: string;
  check_out: string;
  number_of_nights: number;
  status: string;
  sub_total_rate: number;
  total_vat: number;
  total_service_charge: number;
  total_rate: number;

  created_at: Date;
  cancelation_at: Date;

  b2b_created_user: string;
  extranet_cancelation_user: string;
  cancelation_note: string;

  room_details: [
    {
      room_name: string;
      quantity: number;
      rate: number;
    }
  ];
  payment: {
    total_paid: number;
  };
  refund: {
    total_gross_refund: number;
    rooms: {
      room_name: string;
      quantity: number;
      gross_refund: number;
    }[];
  };
}

export default function BookingDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const reservationId = Number(params.reservation_id);
  const [printModalShow, setPrintModalShow] = useState(false);
  // const [guestDetailsModalShow, setGuestDetailsModalShow] = useState(false);
  // const [sendMailModalShow, setSendMailModalShow] = useState(false);
  // const [comfirmationNoteModalShow, setComfirmationNoteModalShow] =
  //   useState(false);
  // const [pickupNoteModalShow, setPickupNoteModalShow] = useState(false);
  // const [dateModificationModalShow, setDateModificationModalShow] =
  //   useState(false);
  // const [reservationDetails, setReservationDetails] =
  //   useState<null | IReservationDetails>(null);

  const reservation_details = useQuery(
    ["reservation_details", reservationId],
    async () =>
      await Fetch<IReservationDetails>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/booking/property/reservation/${reservationId}`,
        navigate,
      })
  );

  if (reservation_details.isError) {
    Toast.fire({
      icon: "error",
      title: (reservation_details.error as IFetchError).message,
      position: "bottom-left",
      showCloseButton: true,
    });
  }

  return (
    <Container className="py-2">
      {/* <Card className="pt-4 pb-2 px-3 rounded-2 mt-2">
            <Row>
              <InputButton
                icon={<FontAwesomeIcon icon={faUserPen} fontSize={22} />}
                onClick={() => setGuestDetailsModalShow(true)}
                lg={2}
                md={6}
                label="Guest"
                type="submit"
                variant="primary"
                title="Guest Details"
                label_class="mx-2"
                className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
              />
              <InputButton
                icon={<FontAwesomeIcon icon={faBed} fontSize={22} />}
                onClick={() => setRoomDetailsModalShow(true)}
                lg={2}
                md={6}
                label="Room"
                type="submit"
                variant="primary"
                title="Room Details"
                label_class="mx-2"
                className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
              />
              <InputButton
                icon={<img src={CalenderPen} alt="calender-pen" height={22} />}
                onClick={() => setDateModificationModalShow(true)}
                lg={2}
                md={6}
                label="Date"
                type="submit"
                variant="primary"
                title="Room Details"
                label_class="mx-2"
                className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
              />
              <InputButton
                icon={<FontAwesomeIcon icon={faVanShuttle} fontSize={22} />}
                onClick={() => setPickupNoteModalShow(true)}
                lg={2}
                md={6}
                label="Pick up"
                type="submit"
                variant="primary"
                title="Room Details"
                label_class="mx-2"
                className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
              />
              <InputButton
                icon={<FontAwesomeIcon icon={faHashtag} fontSize={22} />}
                onClick={() => setComfirmationNoteModalShow(true)}
                lg={2}
                md={6}
                label="Confirm"
                type="submit"
                variant="primary"
                title="Room Details"
                label_class="mx-2"
                className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
              />
              <InputButton
                icon={<FontAwesomeIcon icon={faEnvelope} fontSize={22} />}
                onClick={() => setSendMailModalShow(true)}
                lg={2}
                md={6}
                label="Mail"
                type="submit"
                variant="primary"
                title="Room Details"
                label_class="mx-2"
                className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
              />
              <InputButton
                icon={<FontAwesomeIcon icon={faPrint} fontSize={22} />}
                // onClick={() => setGuestDetailsModalShow(true)}
                lg={2}
                md={6}
                label="Print"
                type="submit"
                variant="primary"
                title="Guest Details"
                label_class="mx-2"
                className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
              />
            </Row>
          </Card> */}
      <InputButton
        icon={<FontAwesomeIcon icon={faPrint} fontSize={22} />}
        onClick={() => setPrintModalShow(true)}
        lg={2}
        md={6}
        label="Print"
        type="button"
        variant="primary"
        label_class="mx-2"
        className="w-100 mb-3 p-0 fs-6 rounded-1 py-2 border-0 d-flex align-items-center justify-content-center"
      />

      {reservation_details.isLoading ? (
        <>
          {[1, 2].map(() => (
            <Card className="mb-3 rounded-2">
              <Table>
                <TableLoader columnLength={4} />
              </Table>
            </Card>
          ))}
        </>
      ) : reservation_details.isSuccess ? (
        <>
          <ReservationDetails
            reservation_id={reservationId}
            status={reservation_details.data.data.status}
            property_name={reservation_details.data.data.property_name}
            check_in={reservation_details.data.data.check_in}
            check_out={reservation_details.data.data.check_out}
            created_at={reservation_details.data.data.created_at}
            number_of_nights={reservation_details.data.data.number_of_nights}
            booking_source={reservation_details.data.data.booking_source}
            cancelation_at={reservation_details.data.data.cancelation_at}
            b2b_created_user={reservation_details.data.data.b2b_created_user}
            extranet_cancelation_user={
              reservation_details.data.data.extranet_cancelation_user
            }
          />

          <GuestDetails
            guest_name={reservation_details.data?.data.guest_name}
            guest_email={reservation_details.data?.data.guest_email}
            guest_address={reservation_details.data?.data.guest_address}
            guest_mobile_no={reservation_details.data?.data.guest_mobile_no}
            guest_special_request={
              reservation_details.data?.data.guest_special_request
            }
          />

          <ReservationRoomDetails
            total_vat={reservation_details.data.data.total_vat}
            total_service_charge={
              reservation_details.data.data.total_service_charge
            }
            sub_total_rate={reservation_details.data.data.sub_total_rate}
            total_paid={reservation_details.data.data.payment.total_paid}
            total_rate={reservation_details.data.data.total_rate}
            poperty_reservation_details={
              reservation_details.data.data.room_details
            }
            number_of_nights={reservation_details.data.data.number_of_nights}
          />
          {reservation_details.data.data.refund.rooms.length !== 0 ? (
            <ReservationRefundDetails
              refund={reservation_details.data.data.refund}
            />
          ) : null}
        </>
      ) : (
        <h4 className="teaxt-center">Data is not Found!</h4>
      )}
      <PrintModal
        show={printModalShow}
        onHide={() => setPrintModalShow(false)}
      />

      {/* <GuestDetailsUpdateModal
        show={guestDetailsModalShow}
        onHide={() => setGuestDetailsModalShow(false)}
      />
      <ConfirmationNoteModal
        show={comfirmationNoteModalShow}
        onHide={() => setComfirmationNoteModalShow(false)}
      />
      <PickupNoteModal
        show={pickupNoteModalShow}
        onHide={() => setPickupNoteModalShow(false)}
      />
      <SendMailModal
        show={sendMailModalShow}
        onHide={() => setSendMailModalShow(false)}
      />

      <DateModificationModal
        show={dateModificationModalShow}
        onHide={() => setDateModificationModalShow(false)}
      />  */}
    </Container>
  );
}
