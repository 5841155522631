import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Fetch from "../../../../utils/fetch";

interface UsersData {
  count: {
    count: string;
  }[];
  users: {
    id: number;
    type: string;
    name: string;
    email: string;
    status: boolean;
    mobile_no: string;
    photo: string | null;
  }[];
}

interface Params {
  name: string;
  status: string;
  offset: number;
  pageSize: number;
  activePage: number;
}

export default function useUsers(params: Params) {
  const navigate = useNavigate();

  return useQuery(
    ["b2bUsers", params],
    async () =>
      await Fetch<UsersData>({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/user/filter_users`,
        body: {
          name: params.name,
          status: params.status,
          offset: params.offset,
          pageSize: params.pageSize,
        },
        navigate,
      }),
  );
}
