import React from "react";
import { Container, Card, Row, Col, Badge } from "react-bootstrap";
import userHeadset from "../../../assets/img/user-headset.svg";
import { useQuery } from "react-query";
import Fetch from "../../../utils/fetch";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import CompanyDocuments from "./company_documents";
import ContentLoader from "react-content-loader";

export type TypeDocuments = {
  trade_license: string;
  tin_certificate: string;
  vat_certificate: string;
  proprietor_nid: string;
  contact_person_nid: string;
  civil_aviation_travel_agency_registration: string;
  toab_certificate_of_membership: string;
  atab_certificate_of_membership: string;
  incorporation_certificate: string;
  agreement: string;
  any_other_documentation: string;
};

interface ICompany {
  profile: {
    agent_id: string;
    brand_name: string;
    business_entity_name: string;
    business_entity_type: string;
    wallet_balance: number;
    credit_limit: number;

    email: string;
    mobile_no: string;
    status: string;
    logo: string;
    registered_since: any;
    commission: string;
    payment_methods: string;
    website: string;

    contact_person_name: string;
    contact_person_email: string;
    contact_person_mobile_no: string;
    contact_person_designation: string;

    address: string;
    country: string;
    division: string;
    district: string;
    thana: string;
    post_code: string;

    account_manager_name: string;
    account_manager_email: string;
    account_manager_mobile: string;
    account_manager_designation: string | null;
  };
  documents: TypeDocuments;
}

export default function Company() {
  const navigate = useNavigate();

  const company_details = useQuery(
    ["company_details"],
    async () =>
      await Fetch<ICompany>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/agent/profile`,
        navigate,
      })
  );

  const generateStatusBadge = (status: string) => {
    let status_badge = "badge";
    if (status === "Processing") {
      status_badge += " bg-primary";
    }
    if (status === "Under Review") {
      status_badge += " bg-warning";
    }
    if (status === "Active") {
      status_badge += " bg-success";
    }
    if (status === "Disable") {
      status_badge += " bg-danger";
    }
    return status_badge;
  };

  return (
    <div className="container-fluid">
      {company_details.isLoading ? (
        <Container>
          {[1, 2, 3].map((_, index: React.Key) => (
            <Card className="my-2 card_hover" key={index}>
              <ContentLoader
                width="100%"
                height={150}
                backgroundColor="#E1E9EE"
                foregroundColor="#F2F8FC"
                className="p-3"
              >
                <rect x="0" y="0" rx="10" ry="10" width="30%" height="45%" />
                <rect x="0" y="55%" rx="10" ry="10" width="30%" height="45%" />
                <rect x="33%" y="14" rx="6" ry="6" width="30%" height="16" />
                <rect x="33%" y="40" rx="6" ry="6" width="25%" height="14" />
                <rect x="33%" y="70" rx="6" ry="6" width="30%" height="16" />
                <rect x="66%" y="14" rx="6" ry="6" width="30%" height="16" />
                <rect x="66%" y="40" rx="6" ry="6" width="25%" height="14" />
                <rect x="66%" y="70" rx="6" ry="6" width="30%" height="16" />
              </ContentLoader>
            </Card>
          ))}
        </Container>
      ) : (
        <>
          <div className="min-height-80 bg-primary bg-gradient rounded-3"></div>
          {company_details.isSuccess ? (
            <Container>
              <Card className="p-3 company_header ">
                <Row className="align-items-center">
                  <Col className="col-md-6 col-sm-12 d-flex align-items-center">
                    <img
                      src={
                        company_details.data.data.profile.logo
                          ? company_details.data.data.profile.logo.includes(
                              ".svg"
                            )
                            ? process.env.REACT_APP_CDN_URL +
                              company_details.data.data.profile.logo
                            : process.env.REACT_APP_CDN_URL +
                              btoa(
                                JSON.stringify({
                                  bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                                  key: company_details.data.data.profile.logo,
                                  edits: {
                                    resize: { width: 480 },
                                  },
                                })
                              )
                          : userHeadset
                      }
                      alt="user_headset_icon"
                      className="mx-1"
                      style={{ maxHeight: "120px", maxWidth: "120px" }}
                    />
                    <div className="mx-2">
                      <h4 className="">
                        {
                          company_details.data?.data.profile
                            .business_entity_name
                        }
                      </h4>
                      <p className="m-0">
                        {company_details.data?.data.profile.address}{" "}
                        {company_details.data?.data.profile.thana},{" "}
                        {company_details.data?.data.profile.district},{" "}
                        {company_details.data?.data.profile.division}-
                        {company_details.data?.data.profile.post_code},{" "}
                        {company_details.data?.data.profile.country}
                      </p>
                    </div>
                  </Col>
                  <ul className="list-group list-group-horizontal-sm col-md-6 col-sm-12 justify-content-md-end p-3 pt-4">
                    <li className="list-group-item">
                      <a href="#info" className="text-decoration-none">
                        Info
                      </a>
                    </li>
                    <li className="list-group-item">
                      <a href="#contact" className="text-decoration-none">
                        Contact
                      </a>
                    </li>
                    <li className="list-group-item">
                      <a href="#documents" className="text-decoration-none">
                        Documents
                      </a>
                    </li>
                  </ul>
                </Row>
              </Card>
              <div
                data-bs-spy="scroll"
                data-bs-target="#navbar-example2"
                data-bs-offset="0"
                className="scrollspy-example font_14"
              >
                <Card className="mt-lg-4 mt-3 p-3 p-md-4" id="info">
                  <h5 className="fw-bold">General Info</h5>
                  <Row>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Brand Name</h6>
                      <p className="">
                        {company_details.data?.data.profile.brand_name}
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">
                        Type of Business Entity
                      </h6>
                      <p className="">
                        {
                          company_details.data?.data.profile
                            .business_entity_type
                        }
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Email</h6>
                      <p className="">
                        {company_details.data?.data.profile.email}
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Contact Number</h6>
                      <p className="">
                        {company_details.data?.data.profile.mobile_no}
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Partner ID</h6>
                      <p className="">
                        {company_details.data?.data.profile.agent_id}
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Registered since</h6>
                      <p className="">
                        {format(
                          new Date(
                            company_details.data?.data.profile.registered_since
                          ),
                          "dd MMMM yyyy"
                        )}
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Payment methods</h6>
                      <p className="">
                        {company_details.data?.data.profile.payment_methods}
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Status</h6>
                      <Badge
                        bg={generateStatusBadge(
                          `${company_details.data?.data.profile.status}`
                        )}
                      >
                        {company_details.data?.data.profile.status}
                      </Badge>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Balance</h6>
                      <p className="">
                        {company_details.data?.data.profile.wallet_balance}
                      </p>
                    </Col>
                    {company_details.data.data.profile.credit_limit && (
                      <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                        <h6 className="font_14 fw-bold">Credit Limit</h6>
                        <p className="">
                          {company_details.data?.data.profile.credit_limit}
                        </p>
                      </Col>
                    )}
                  </Row>
                </Card>

                <Card className="mt-lg-4 mt-3 p-3 p-md-4" id="contact">
                  <h5 className="fw-bold">Contact Person</h5>
                  <Row>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Name</h6>
                      <p className="">
                        {company_details.data?.data.profile.contact_person_name}
                      </p>
                    </Col>

                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Official Designation</h6>
                      <p className="">
                        {
                          company_details.data?.data.profile
                            .contact_person_designation
                        }
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Contact Number</h6>
                      <p className="">
                        {
                          company_details.data?.data.profile
                            .contact_person_mobile_no
                        }
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Email</h6>
                      <p className="">
                        {
                          company_details.data?.data.profile
                            .contact_person_email
                        }
                      </p>
                    </Col>
                  </Row>
                </Card>

                <Card className="mt-lg-4 mt-3 p-3 p-md-4">
                  <h5 className="fw-bold">Account Manager (RNR Rooms)</h5>
                  <Row>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Name</h6>
                      <p className="">
                        {
                          company_details.data?.data.profile
                            .account_manager_name
                        }
                      </p>
                    </Col>
                    {company_details.data?.data.profile
                      .account_manager_designation ? (
                      <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                        <h6 className="font_14 fw-bold">
                          Official Designation
                        </h6>
                        <p className="">
                          {
                            company_details.data?.data.profile
                              .account_manager_designation
                          }
                        </p>
                      </Col>
                    ) : null}
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Contact Number</h6>
                      <p className="">
                        {
                          company_details.data?.data.profile
                            .account_manager_mobile
                        }
                      </p>
                    </Col>
                    <Col className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-3">
                      <h6 className="font_14 fw-bold">Email</h6>
                      <p className="">
                        {
                          company_details.data?.data.profile
                            .account_manager_email
                        }
                      </p>
                    </Col>
                  </Row>
                </Card>

                {company_details.data.data.documents && (
                  <CompanyDocuments
                    documents={company_details.data.data.documents}
                  />
                )}
              </div>
            </Container>
          ) : (
            <h4 className="text-center">Data is not found!</h4>
          )}
        </>
      )}
    </div>
  );
}
