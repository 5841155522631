import { Container, Card, Row, Col, Image } from "react-bootstrap";
import userHeadset from "../../../assets/img/user-headset.svg";
import {
  faEnvelope,
  faCircleInfo,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import Fetch from "../../../utils/fetch";
import ContentLoader from "react-content-loader";

interface IProfile {
  id: number;
  name: string;
  email: string;
  mobile_no: string;
  status: boolean;
  photo: string | null;
  designation: string | null;
}

export default function Profile() {
  const navigate = useNavigate();

  const profile_data = useQuery(
    ["profile_data"],
    async () =>
      await Fetch<IProfile>({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/b2b/settings/account/profile`,
        navigate,
      })
  );

  return (
    <Container>
      <Row className="justify-content-center vh-100 font_14">
        <Col sm={10} md={8} lg={7} xl={5}>
          <Card className="mt-lg-4 mt-3 p-3 p-md-3 rounded-2" id="contact">
            <h3 className="fw-bolder border-bottom p-2">User Profile</h3>
            {profile_data.isLoading ? (
              <Row className="flex-column-reverse flex-md-row ">
                <Col>
                  {[1, 2, 3].map(() => (
                    <>
                      <ContentLoader viewBox="0 0 380 40" className="mb-2">
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>

                      <ContentLoader viewBox="0 0 380 40" className="mb-4">
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="100%"
                        />
                      </ContentLoader>
                    </>
                  ))}
                </Col>
                <Col>
                  <ContentLoader viewBox="0 0 30 30">
                    <rect
                      x="0"
                      y="0"
                      rx="50%"
                      ry="50%"
                      width="100%"
                      height="100%"
                    />
                  </ContentLoader>
                </Col>
              </Row>
            ) : (
              <Row className="p-2 flex-column-reverse flex-md-row ">
                <Col>
                  <Row>
                    <Col className="col-12 mb-2 mb-sm-3">
                      <h6 className=" fw-bold">
                        <FontAwesomeIcon icon={faUser} /> Name
                      </h6>
                      <p className="">{profile_data.data?.data.name}</p>
                    </Col>

                    <Col className="col-12 mb-2 mb-sm-3">
                      <h6 className=" fw-bold">
                        <FontAwesomeIcon icon={faPhone} /> Contact Number
                      </h6>
                      <p className="">{profile_data.data?.data.mobile_no}</p>
                    </Col>

                    <Col className="col-12 mb-2 mb-sm-3">
                      <h6 className=" fw-bold">
                        <FontAwesomeIcon icon={faEnvelope} /> Email
                      </h6>
                      <p className="">{profile_data.data?.data.email}</p>
                    </Col>
                    {profile_data.data?.data.designation ? (
                      <Col className="col-12 4 mt-2 mt-sm-3">
                        <h6 className=" fw-bold">
                          <FontAwesomeIcon icon={faCircleInfo} /> Official
                          Designation
                        </h6>
                        <p className="">
                          {profile_data.data?.data.designation}
                        </p>
                      </Col>
                    ) : null}
                  </Row>
                </Col>

                <Col className="d-flex flex-column align-items-center">
                  <Image
                    className="mb-2 p-1 bg-dark bg-opacity-75"
                    roundedCircle
                    src={
                      profile_data.data?.data.photo
                        ? process.env.REACT_APP_CDN_URL +
                          btoa(
                            JSON.stringify({
                              bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                              key: profile_data.data?.data.photo,
                              edits: { resize: { width: 120, height: 120 } },
                            })
                          )
                        : userHeadset
                    }
                    width="120px"
                    height="120px"
                  />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
