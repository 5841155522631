import { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import InputButton from "../../../../../components/form-elements/Button";
import InputFile from "../../../../../components/form-elements/FileInput";
import Input from "../../../../../components/form-elements/Input";
import Select from "../../../../../components/form-elements/Select";
import { useNavigate } from "react-router-dom";
import { usePaymentTypes } from "./usePaymentTypes";
import { useBankList } from "./useBankList";
import { useDepositAccounts } from "./useDepositAccounts";
import DatePickerInput from "../../../../../components/form-elements/DatePicker";
import { useMutation, useQueryClient } from "react-query";
import Fetch from "../../../../../utils/fetch";
import Swal from "sweetalert2";
import { IFetchError } from "../../../../../types";
import { Toast } from "../../../../../utils";

type ManualPaymentValues = {
  payment_type_id: string;
  payment_type: string;
  amount: string;
  final_amount: string;
  processing_fee: string;
  gateway_service_charge: string;
  deposited_from_bank: string;
  bank_branch: string;
  deposited_to_account: string;
  payment_date: string;
  transaction_id: string;
  document_of_proof: File;
};

export default function ManualPayment() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { control, handleSubmit, watch, setValue, setError } =
    useForm<ManualPaymentValues>();

  const [isBankShow, setIsBankShow] = useState(false);

  const payment_types = usePaymentTypes();

  const banks = useBankList({ enabled: isBankShow });

  const deposit_accounts = useDepositAccounts();

  const payment_mutation = useMutation((body: FormData) =>
    Fetch<{ b2b_wallet_payment_id: number }>({
      method: "POST",
      url:
        process.env.REACT_APP_BACKEND_URL +
        "/api/b2b/wallet/payment/manual/create",
      body,
      navigate,
      multipart: true,
    })
  );

  const onSubmit: SubmitHandler<ManualPaymentValues> = (data) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key !== "final_amount" && key !== "document_of_proof") {
        formData.append(key, value as string);
      }
      if (key === "document_of_proof") {
        formData.append(key, (value as any)[0] as string);
      }
    }

    payment_mutation.mutate(formData, {
      onSuccess: (data) => {
        Toast.fire({
          icon: "success",
          title: data.message,
          position: "top-right",
          showCloseButton: true,
        });
        queryClient.invalidateQueries("wallet-balance");
        navigate(`/payment/details/${data.data.b2b_wallet_payment_id}`, {
          replace: true,
        });
      },
      onError: (error) => {
        if ((error as IFetchError).status === "ValidationError") {
          setError(
            // @ts-ignore: Unreachable code error
            (error as IFetchError).details.body[0].context.key,
            {
              type: "server",
              message: (error as IFetchError).reason,
            },
            { shouldFocus: true }
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: (error as IFetchError).message,
          });
        }
      },
    });
  };

  const watchAmount = watch("amount");
  const watchPaymentType = watch("payment_type_id");

  useEffect(() => {
    if (watchPaymentType && payment_types.data && deposit_accounts.data) {
      const amount = Number(watchAmount) || 0;

      const payment_type = payment_types.data.data.find(
        (payment_type) => payment_type.id === Number(watchPaymentType)
      );

      const gateway_processing_fee =
        amount * (payment_type!.service_charge / 100);
      const final_amount = amount - gateway_processing_fee;

      if (payment_type?.code_name === "Bank") {
        setIsBankShow(true);
      } else {
        setIsBankShow(false);
      }

      //  set deposited to account based on payment type

      if (payment_type?.code_name === "bKash") {
        const bkash_account = deposit_accounts.data.data.find(
          (account) => account.name === "bKash"
        );
        setValue("deposited_to_account", bkash_account!.id);
      } else if (payment_type?.code_name === "Nagad") {
        const nagad_account = deposit_accounts.data.data.find(
          (account) => account.name === "Nagad"
        );
        setValue("deposited_to_account", nagad_account!.id);
      } else {
        setValue("deposited_to_account", "");
      }

      setValue("payment_type", payment_type!.code_name);
      setValue(
        "gateway_service_charge",
        Number(payment_type!.service_charge).toFixed(2)
      );
      setValue("processing_fee", gateway_processing_fee.toFixed(2));
      setValue("final_amount", final_amount.toFixed(2));
      setValue("deposited_from_bank", "");
      setValue("bank_branch", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPaymentType]);

  useEffect(() => {
    if (watchPaymentType && payment_types.data) {
      const amount = Number(watchAmount) || 0;

      const payment_type = payment_types.data.data.find(
        (payment_type) => payment_type.id === Number(watchPaymentType)
      );

      const gateway_processing_fee =
        amount * (payment_type!.service_charge / 100);
      const final_amount = amount - gateway_processing_fee;

      setValue(
        "gateway_service_charge",
        Number(payment_type!.service_charge).toFixed(2)
      );
      setValue("processing_fee", gateway_processing_fee.toFixed(2));
      setValue("final_amount", final_amount.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAmount]);

  return (
    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col lg={6}>
          <Row>
            <Col lg={12}>
              <h6 className="mb-4">Payment Information</h6>
            </Col>
            <Row>
              <Select
                lg={12}
                type="text"
                name="payment_type_id"
                label="Payment Type"
                control={control}
                group_class="mb-3"
                selectOption="Select type"
                loading={payment_types.isLoading}
                options={payment_types.data ? payment_types.data?.data : []}
                rules={{ required: "Please select payment type" }}
              />
            </Row>
            {isBankShow ? (
              <Row>
                <Select
                  lg={6}
                  type="text"
                  name="deposited_from_bank"
                  label="Deposited From Bank"
                  control={control}
                  group_class="mb-3"
                  selectOption="Select From"
                  loading={banks.isLoading}
                  options={banks.data ? banks.data.data : []}
                  rules={{ required: "Please select payment type" }}
                />
                <Input
                  lg={6}
                  type="text"
                  name="bank_branch"
                  label="Branch Name (optional)"
                  control={control}
                  group_class="mb-3"
                  placeholder="Branch Name"
                />
              </Row>
            ) : null}
            <Row>
              <Input
                lg={6}
                name="amount"
                type="text"
                label="Amount"
                control={control}
                group_class="mb-3"
                placeholder="Enter amount"
                step=".01"
                rules={{
                  min: 1,
                  required: "Please provide an amount",
                }}
              />
              <DatePickerInput
                lg={6}
                group_class="mb-3 position-relative"
                name="payment_date"
                label="Date"
                control={control}
                rules={{ required: "Please provide payment date" }}
              />
            </Row>
            <Row>
              <Input
                lg={6}
                disabled
                type="text"
                name="gateway_service_charge"
                label="Service Charge (%)"
                control={control}
                group_class="mb-3"
              />
              <Input
                lg={6}
                disabled
                type="text"
                name="final_amount"
                label="TopUP Amount"
                control={control}
                group_class="mb-3"
                children={
                  <span className="font_14 text-warning">
                    This amount will be added to balance
                  </span>
                }
              />
            </Row>
          </Row>
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={12}>
              <h6 className="mb-4">
                Transfer Information (Account Name: RNR Rooms)
              </h6>
            </Col>
            <Row>
              <Select
                lg={12}
                type="text"
                name="deposited_to_account"
                label="Deposited To Account"
                control={control}
                group_class="mb-3"
                selectOption="Select From Dropdown"
                loading={deposit_accounts.isLoading}
                options={
                  deposit_accounts.data ? deposit_accounts.data?.data : []
                }
                rules={{ required: "Please select Deposited Account" }}
              />
            </Row>
            <Row>
              <InputFile
                lg={12}
                name="document_of_proof"
                label="Document of proof"
                control={control}
                group_class="mb-3"
                multiple={false}
                accept="jpg, jpeg, png, webp, pdf"
                rules={{ required: "Please upload a proof" }}
              />
            </Row>
            <Row>
              <Input
                lg={12}
                type="text"
                name="transaction_id"
                label="Reference Number"
                control={control}
                group_class="mb-3"
                children={
                  <span className="font_14 text-danger">
                    (Transaction No / Reference No etc)
                  </span>
                }
                rules={{ required: "Please provide a reference id" }}
              />
            </Row>
          </Row>
        </Col>
        <Row>
          <InputButton
            lg={4}
            label={
              payment_mutation.isLoading ? (
                <>
                  SUBMITTING{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">...</span>
                </>
              ) : (
                "SUBMIT"
              )
            }
            disabled={payment_mutation.isLoading}
            variant="primary"
            type="submit"
            className="w-100"
          />
        </Row>
      </Row>
    </Form>
  );
}
